.home-about {
	float: left;
	width: 100%;
	background: #fff;
	padding: 10px 0 50px;
	position: relative;
	@include breakpoint($bp-adams){
		// padding: 250px 0 50px;
	}
	@include breakpoint($bp-charlie) {
		padding: 370px 40px 85px;
	}
	@include breakpoint($bp-bobby) {
		padding: 340px 0 125px;
	}
	@include breakpoint($bp-echo){
		padding: 260px 0 125px;
	}
	.col-md-11 {
		float: none;
		display: table;
		margin: 0 auto;
	}
	h6{
		color: $quatenary;
		display: block;
		margin: 0 40px 15px 80px;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 3.3px;
		

		&::before{
			background: $quatenary;
			content: '';
			width: 35px;
			height: 4px;
			position: absolute;
			left: -50px;
			top: 9px;
		}

		@include breakpoint($bp-charlie) {
			display: inline-block;
			left: 20px;
			margin: 0;
			position: absolute;
			top: 50%;
			transform-origin: left top;
			@include transform(rotate(-90deg) translateX(-50%));
		}
	}
	h2 {
		margin-bottom: 45px;
		margin-top: 0;
		max-width: 450px;
		font-size: 35px;

		span{
			&:before, &:after{
				height: 6px;
				@include breakpoint($bp-bravo){
					height: 10px;
				}
			}
		}

		@include breakpoint($bp-bravo){
			font-size: 40px;
		}
		@include breakpoint($bp-charlie){
			font-size: 42px;
		}
		@include breakpoint($bp-bobby){
			font-size: 58px;
		}
	}
	
	.read-more {
		float: left;
		width: 100%;
		margin-right: 0px;
		max-width: 200px;

		@include breakpoint($bp-charlie){
			margin-right: 40px;
		}
		@include breakpoint($bp-bobby){
			margin-right: 120px;
		}
		a {
			float: none;
			display: inline-block;

			&.btn{
				min-width: 100%;
				padding: 10px 15px;
				height: 50px;
				position: relative;

				&::before{
					content: '\f105';
					color: $primary;
					font-family: 'FontAwesome';
					font-size: 20px;
					position: relative;
					left: -15px;
					line-height: 1em;
					@include hover-fade();
				}

				&:hover{

					&:before{
						color: $body;
						left: -10px;
					}
				}
			}
		}
	}
	.description {
		float: left;
		margin-bottom: 20px;
		margin-top: 20px;
		max-width: 100%;

		@include breakpoint($bp-charlie) {
			margin-bottom: 40px;
			margin-top: 0;
			max-width: 360px;
		}
		@include breakpoint($bp-bobby) {
			max-width: 530px;
		}

		@include breakpoint($bp-echo) {
			max-width: 610px;
		}
		p {
			font-size: 16px;

			@include breakpoint($bp-charlie) {
				font-size: 18px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 20px;
			}
		}
	}
}

.home-issues {
	float: left;
	width: 100%;
	// background: #eeeeee;
	// padding: 50px 20px;
	// @include breakpoint($bp-charlie) {
	// 	padding: 85px 40px;
	// }
	// @include breakpoint($bp-bobby) {
	// 	padding: 125px 40px;
	// }
	// .col-md-8 {
	// 	float: none;
	// 	display: table;
	// 	margin: 0 auto;
	// }
	.container {
		padding: 0 16px;
		width: 100%;
		max-width: 100%;
	}
	.row{
		margin: 0;
	}
	// h3 {
	// 	text-align: center;
	// 	margin-bottom: 25px;
	// 	margin-top: 0;
	// }
	// .description {
	// 	margin-top: 0;
	// 	p {
	// 		text-align: center;
	// 		margin-bottom: 0px;
	// 	}
	// }
	// .read-more {
	// 	float: left;
	// 	width: 100%;
	// 	a {
	// 		float: none;
	// 		display: table;
	// 		margin: 0 auto;
	// 	}
	// }

	.issues-grid {
		width: 100%;
		display: table;
		font-size: 0px; /*Important for Firefox*/
		
		@include breakpoint($bp-bobby) {
			text-align: left;
		}
		.single-issue {
			float: none;
			width: 100%;
			max-width: 100%;
			margin: 0px auto 15px;
			background: $secondary;
			border-radius: $borderRadius;
			box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.15);
			height: 450px;
			position: relative;
			@include hover-fade();
			overflow: hidden;

			@include breakpoint($bp-charlie) {
				width: calc(50% - 12px);
				float: none;
				display: inline-block;
				margin: 0 6px 6px;
			}
			@include breakpoint($bp-bobby) {
				width: calc(33.33% - 12px);
				height: calc(500px - 12px);
			}

			//when visible, assume that items will be hovered and change
			//something related to this causes the sticky nav to jump around, which is crazy, not sure what the fix is
			.activeSection & {
				will-change: transform, background-color;
				p, small {
					&.teaser{
						will-change: transform, max-height, opacity;
					}
				}
			}
			&:hover {
				background: $tertiary;
				// transform: translateY(-6px);
				@include transform(scale(1));
				box-shadow: none;
				@include breakpoint($bp-charlie){
					@include transform(scale(1.06));
					@include box-shadow(6px, 9px, 12px, rgba(0,0,0,0.12));
				}
				z-index: 99;
				.grid-image-container {
					background: $tertiary;
					.grid-image {
						opacity: 1;
						@include breakpoint($bp-charlie){
							opacity: 0;
						}
					}
				}
				.wrapper {
					background: transparent;
					display: block;
					background: $body;
					@include breakpoint($bp-charlie){
						max-width:100%;
						background: transparent;
						bottom:auto;
						top: 50%;
						@include transform(translateY(-50%));
					}
					h5, h6, p, small {
						color: #fff;
					}
					h5 {
						max-width:100%;
						margin-bottom: 0;
						@include breakpoint($bp-charlie){
							margin: 0 0 20px;
							padding-bottom: 0;
						}
					}
					p, small {
						&.teaser {
							display: none;
							@include breakpoint($bp-charlie){
								display: block;
								color: #fff;
								opacity: 1;
								position: relative;
								margin-bottom: 30px;
								max-height: 275px;
								@include hover-fade();
								@include transform(translateY(0%));

								@include breakpoint($bp-charlie) {
									max-height: 325px;
								}

								@include breakpoint($bp-bobby) {
									max-height: 350px;
								}
							}
						}
					}
					a.learn-more{
						@include breakpoint($bp-charlie){
							position: relative;
							@include transform(translateY(0%));
							opacity: 1;
						}
					}
					.social.links {
						@include transform(translateY(0%));
						opacity: 1;
					}
				}
			}
			svg{
				bottom: 30px;
				right: 25px;
				position: absolute;
				height: 40px;
				width: 40px;
				z-index: 99;
				fill: #fff;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 99;

				&.learn-more{
					color: #fff;
					font-family: $headline-font;
					position: absolute;
					display: inline-block;
					font-size: 17px;
					margin: 0 35px;
					@include transform(translateY(100%));
					@include transition(0.15s);
					opacity: 0;
					width: inherit;

					@include breakpoint($bp-echo){
						margin: 0 85px;
					}

					span{
						display: inline-block;
						border-bottom: 2px solid;
						line-height: 1em;
					}

					i{
						font-size: 13px;
						margin-right: 5px;
					}
				}
			}
			.grid-image-container {
				height: 100%;
				background: #000;
				.grid-image {
					background-size: cover !important;
					background-position: 50% 50% !important;
					height: 100%;
					width: 100%;
					opacity: 1;
					@include hover-fade();
				}
			}
			.wrapper {
				background: $body;
				bottom: 0;
				position: absolute;
				top: auto;
				transform: none;
				z-index: 98;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				@include breakpoint($bp-charlie){
					min-height: 125px;
					max-width:100%;
				}
				h5, h6 {
					text-align: left;
					margin: 0px;
					@include hover-fade();
				}
				h5 {
					font-size: 22px;
					line-height: 1.27em;
					padding: 35px 90px 35px 35px;
					transition: none !important;

					@include breakpoint($bp-echo){
						max-width:100%;
						padding: 35px 85px 35px 85px;
					}
				}
				h5, p.teaser {
					color: white;
				}
				small {
					display: block;
				}
				p, small {
					text-align: left;
					margin: 0;
					@include transition(0.2s);
					&.teaser {
						position: absolute;
						@include transform(translateY(20%));
						@include transition(0.15s);
						width: 100%;
						left: 0;
						right: 0;
						padding: 0px 35px;
						opacity: 0;
						line-height: 150%;
						max-height: 0;
						overflow: hidden;

						@include breakpoint($bp-echo){
							padding: 0 85px;
						}
					}
				}
				.social.links {
					width: auto;
					position: absolute;
					@include transform(translateY(50%));
					opacity: 0;
					@include hover-fade();
					bottom: 35px;
					z-index: 99;
					ul {
						position: relative;
						width: 100%;
						top: 8px;
						li {
							display: inline-block;
							float: left;
							font-size: 18px;
							.fa-facebook {
								font-size: 17px;
							}
							a {
								position: relative;
								padding: 2px 7px 0px;
								color: white;
								&:hover {
									color: $body;
								}
							}
							&:first-child {
								a {
									padding-left: 0px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.home.take-action{
	background: #fff;
	padding: 15px 0 0px;

	@include breakpoint($bp-charlie){
		padding: 60px 0 70px;
	}

	@include breakpoint($bp-bobby){
		padding: 160px 0 170px;
	}
}

.home.module.take-action{
	.intro-block{
		width: 100%;

		@include breakpoint($bp-echo){
			width: 610px;
		}

		p{
			
			@include breakpoint($bp-echo){
				max-width: 430px;
			}
		}
	}

} 

.our-campaigns{
	background: #f1f1f1;
	border-left: 17px solid #fff;
	position: relative;
	float: left;
	padding: 40px 0 125px;
	width: 100%;
	margin-top: 0;

	@include breakpoint($bp-charlie){
		margin-top: 60px;
	}

	@include breakpoint($bp-bobby){
		margin-top: 80px;
		padding: 140px 0 125px;
	}

	.container, .col-xs-12{
		padding: 0;
		width: 100%;
	}
	.row{
		margin: 0;
	}
	h1, .description{
		max-width: 360px;
		margin-left: 15px;

		@include breakpoint($bp-charlie){
			margin-left: 45px;
		}
	}
	
	h1{
		display: inline-block;
		position: relative;
		z-index: 1;
		margin-bottom: 15px;


		&:before{
			background: $primary;
			content: '';
			transform: skew(-30deg,0);
			width: 102%;
			height: 6px;
			position: absolute;
			bottom: -2px;
			left: -10px;
			padding: 0 1px;
			z-index: -1;
		}
		&:after{
			background: $primary;
			content: '';
			transform: skew(-30deg,0);
			width: 102%;
			height: 6px;
			position: absolute;
			bottom: -2px;
			left: 0;
			padding: 0 1px;
			z-index: -1;
		}

		@include breakpoint($bp-charlie){
			&:after, &:before{
				height: 10px;
			}
		}
	}

	.description{
		font-family: $headline-font;
		font-size: 24px;
		font-weight: bold;
		line-height: 1.25;
		letter-spacing: -0.2px;
		margin-top: 10px;
	}
	.featured-campaign-block{
		background: #fff;
		float: left;
		left: 0;
		height: auto;
		width: 100%;
		margin-bottom: 30px;
		margin-left: -17px;
		margin-top: 30px;
		position: relative;
		padding-bottom: 75px;
		box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.1);
		-moz-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.1);
		@include hover-fade();

		@include breakpoint($bp-charlie){
			padding-bottom: 0;
			width: 95%;
		}

		@include breakpoint($bp-bobby){
			margin-bottom: 0;
			width: 50%;
		}
		
		@include breakpoint($bp-echo){
			width: 43%;
		}

		&:hover{
			transform: translateY(-6px);
		}
		
		.feat-campaign-image{
			background-size: contain; 
			background-position: center center;
			min-height: 250px;

			@include breakpoint($bp-charlie){
				min-height: 435px;
			}

			.category{
				background: $quatenary;
				color: #fff;
				font-family: $headline-font;
				font-weight: bold;
				font-size: 9px;
				letter-spacing: 2px;
				padding: 0 15px;
				display: inline-block;
				position: absolute;
				text-transform: uppercase;
				left: 0;
				margin: 0;
				top: 0;
				@include transform(rotate(-90deg) translateX(-100%));
				transform-origin: top left;
				height: 30px;
				line-height: 30px;

				a{
					color: #fff;
				}
			}
		} 

		.feat-content-wrapper{
			padding: 0 40px;

			@include breakpoint($bp-charlie){
				padding: 0 103px 0 75px;
			}

			h5{
				font-size: 24px;
				letter-spacing: normal;

				@include breakpoint($bp-charlie){
					font-size: 38px;
				}
			}

			p{
				font-size: 18px;
				line-height: 1.64em;

				@include breakpoint($bp-charlie){
					font-size: 22px;
					line-height: 1.64em;
					margin-bottom: 5px
				}

				&.category{
					text-transform: uppercase;
					font-size: 11px;
					letter-spacing: 2px;
					font-family: $headline-font;
					margin-bottom: 20px;

					a{
						color: $quatenary;
						display: inline-block;
						font-size: 11px;

						&:hover{
							color: $primary;
						}

						span {
							display: inline-block;
							position: relative;

							&:after {
								content: '';
								display: block;
								border-bottom: 2px solid;
								position: absolute;
								bottom: 0;
								left: 0;
								right: 0;
								color: inherit;
								transform-origin: left;
								transform: scaleX(1.15);
								transition: all 0.2s ease-in-out;
							}

							&:last-of-type:after {
								transform: none;
							}
						}
					}
				}
			}

			a.link{
				font-family: "Galano Grotesque Alt";
				font-weight: 700;
				color: #3099ff;
				display: block;
				padding-bottom: 45px;
				float: right;

				i{
					font-size: 13px;
					margin-right: 3px;
				}

				span{
					display: inline-block;
					border-bottom: 2px solid;
					line-height: 1em;
				}

				&:hover{
					color: $quatenary;
				}
			}
		}
	}

	.content-and-campaigns, .campaigns-content{
		float: left;
		right: 0;
		position: relative;
		width: 100%;

		@include breakpoint($bp-bobby){
			padding-left: 35px;
			width: calc(50% + 17px);
		}
		
		@include breakpoint($bp-echo){
			width: calc(57% + 17px);
		}
	}

	.campaigns-content{
		float:none; 
		padding-left: 15px;
		
		@include breakpoint($bp-bobby){
			padding-left: 35px;
			float: right;
		}

		h1{
			max-width: 220px;

			@include breakpoint($bp-charlie){
				max-width: none;
			}

			@include breakpoint($bp-bobby){
				max-width: 360px;
			}

			@include breakpoint($bp-echo){
				max-width: none;
			}
		}
	}

	.our-campaign-grid-arrows{
		position: absolute;
		top: -80px;
		right: 30px;

		
		.slick-next, .slick-prev{
			display: inline-block;
			cursor: pointer;
			color: $body;
			
			svg{
				height: 62px;
				width: 62px;
				
				.cls-1{
					fill: $body;
					@include hover-fade();
				}
			}

			&:hover{
				color: $secondary;
				svg .cls-1{
				}
			}
		}
		.slick-prev{
			margin-right: 40px;
			@include transform(rotate(-180deg));
			
		}
		.slick-next{
			position: relative;

			&::before{
				content: '';
				width: 3px;
				height: 23px;
				background-color: $details;
				position: absolute;
				left: -20px;
				top: 20px;
			}
		}
	}

	.campaigns-wrapper{
		position: relative;
	}

	.our-campaigns-grid{
		margin-top: 95px;
		width: 100%;
		font-size: 0px; /*Important for Firefox*/

		.single-campaign{
			// display: inline-block;
			// float: none;
			// width: auto;
			height: 550px;
			padding: 0 20px;
			position: relative;
			@include hover-fade();
			overflow: hidden;
			text-align: left;
			margin-bottom: 20px;
			// opacity: 0;

			&.slick-active,
			&:hover {
				// opacity: 1;
			}

			@include breakpoint(375px){
				height: 530px;
			}

			@include breakpoint($bp-charlie) {
				border-right: 3px solid $body;
				// width: calc(760px / 2);
				// float: none;
				display: inline-block;
				// margin-right: 11.9%; 
				margin-bottom: 35px;
			}
			@include breakpoint($bp-bobby) {
				border: 0;
				// width: 33.33%;
				height: 530px;
				// max-width: 445px;
			}
			@include breakpoint($bp-echo){
				border-right: 3px solid $body;
				height: 600px;
			}
			&:last-child{
				margin-right: 0;
				border-right: 0;
			}
			.campaign-image{
				background-size: cover; 
				background-position: center center;
				height: 0;
				margin-bottom: 40px;
				padding-bottom: 75%;
				position: relative;
			}
			.category{
				text-transform: uppercase;
				font-size: 11px;
				letter-spacing: 2px;
				font-family: $headline-font;
				margin-bottom: 20px;

				&.less-margin{
					margin-bottom: 10px;
				}

				& + .category{
					margin-top: -10px;
				}

				.separator{
					color: $details;
				}

				a{
					color: $quatenary;
					display: inline;
					font-size: 11px;

					&:hover{
						color: $primary;
					}

					span {
						display: inline-block;
						position: relative;

						&:after {
							content: '';
							display: block;
							border-bottom: 2px solid;
							position: absolute;
							bottom: 0;
							left: 0;
							right: 0;
							color: inherit;
							transform-origin: left;
							transform: scaleX(1.15);
							transition: all 0.2s ease-in-out;
						}

						&:last-of-type:after {
							transform: none;
						}
					}
				}
			}

			.content-wrapper{
				padding: 0 20px;
			}

			h5{
				text-transform: none;
				margin-bottom: 10px;
				font-size: 20px;
				line-height: 120%;
				letter-spacing: normal;

				@include breakpoint($bp-charlie){
					font-size: 22px;
				}
			}

			p{
				font-size: 15px;
				margin-bottom: 30px;
			}

			.link{
				font-family: $headline-font;
				font-size: 14px;
				font-weight: bold;
				color: $primary;
				position: absolute;
				bottom: 25px;
				right: 30px;

				i{
					font-size: 11px;
					margin-right: 3px;
				}

				svg{
					height: 14px;
					width: 14px;
					margin-right: 5px;
				}

				span{
					display: inline-block;
					border-bottom: 2px solid;
					line-height: 1em;
				}

				&:hover{
					color: $quatenary;
				}
			}
			
			&:hover{
				background: #fff;
				box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
				-webkit-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
				border-color: #fff;
			}
		}
	}
}

.home.featured-activists{
	margin-top: 0;
	max-height: inherit;
	padding-bottom: 50px;
	
	@include breakpoint($bp-bobby){
		padding-bottom: 0;
		min-height: 740px;
		max-height: 740px;
	}
	
	.activist-image{
		background-size: cover;
		background-position: center center;
		position: relative; 
		top: 0;
		margin: 17px 17px 40px 17px;
		min-height: 500px;
		
		@include breakpoint($bp-charlie){
			min-height: 700px;
		}
		
		@include breakpoint($bp-bobby){
			float: left;
			margin: 17px 0 0 17px;
			min-height: 770px;
			height: calc(100% + 30px);
			width: calc(55% - 17px);
		}
	}
	.activist-wrapper{
		position: relative;
		width: 100%;
		padding: 0 6%;

		@include breakpoint($bp-bobby){
			padding: 0;
			float: left;
			position: relative;
			height: 740px;
			width: 45%;
		}

		.content{
			position: relative;
			float: none;
			
			@include breakpoint($bp-bobby){
				float: left;
				top: 50%;
				@include transform(translateY(-50%));
				padding: 0 20% 0 12%;
			}
			
			@include breakpoint($bp-echo){
				padding: 0 12%;
			}
		}
		h6 {
			color: $quatenary;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 0.2em;
			padding-left: 50px;
			position: relative;
			display: inline-block;
			margin: 0 0 15px 0;
			

			&::before{
				background: $quatenary;
				content: '';
				width: 35px;
				height: 4px;
				position: absolute;
				left: 0;
				top: 7px;
			}

			@include breakpoint(376px){
				font-size: 16px;
			}

			@include breakpoint($bp-bobby){
				margin: 0;
				position: absolute;
				right: -150px;
				top: 50%;
				@include transform(rotate(-90deg));
			}
		}
		h2{
			color: #fff;
			display: inline-block;
			font-size: 30px;
			position: relative;
			min-width: 72%;

			@include breakpoint(376px){
				font-size: 40px;
			}

			@include breakpoint($bp-charlie){
				font-size: 54px
			}
			
			@include breakpoint($bp-bobby){
				// min-width: 100%;
				margin-left: calc(-12% + -80px);
				font-size: 48px;
			}
			
			@include breakpoint($bp-echo){
				font-size: 54px;
			}

			&:before{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 102%;
				height: 7px;
				position: absolute;
				bottom: -2px;
				left: -10px;
				padding: 0 1px;
				// z-index: 0;

				@include breakpoint(376px){
					height: 10px;
				}

				@include breakpoint($bp-bobby){
					z-index: -1;
				}
			}
			&:after{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 102%;
				height: 7px;
				position: absolute;
				bottom: -2px;
				left: 0;
				padding: 0 1px;
				// z-index: -1;

				@include breakpoint(376px){
					height: 10px;
				}

				@include breakpoint($bp-bobby){
					z-index: -1;
				}
			}
		}
		
		.description{
			color: #fff;
			font-size: 18px;
			
			@include breakpoint($bp-bobby){
				max-width: 365px;
				font-size: 16px;
			}
			
			@include breakpoint($bp-echo){
				font-size: 18px;
			}
		}

		.location{
			color: $tertiary;
			font-size: 17px;
			font-family: $headline-font;
		}
	}
}

.home.featured-resources{
	margin-top:60px;

	@include breakpoint($bp-charlie){
		margin-top: 80px;
	}
	@include breakpoint($bp-bobby){
		margin-top: 150px
	}

	.resources-slider .resource .above-headline{
		a, span.category{
			margin-right: 0;
		}
		span{
			transition: none;
		}

		.separator{
			color: $details;
		}
	}
}

.home.news-feed{
	background: #f1f1f1;
	border:0;
	padding-top: 0;
	margin-top: 225px;

	@include breakpoint($bp-charlie){
		border-left: 17px solid #fff;
		border-right: 17px solid #fff;
	}

	h3{
		font-size: 26px;
		text-align: right;
		margin-left: 5%;
		text-transform: uppercase;
		letter-spacing: 2.6px;
		margin-bottom: 13px;
		margin-right: 1.5%;
	}

	.container{
		margin-top: -120px;
	}
}

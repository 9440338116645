@import 'header/alert-bar.scss';

// special rules for browsers that DO NOT support position: sticky
@import 'header/nopositionsticky.scss';


// Header
.top {
	position: relative;//in case sticky is disabled or unavailable
	position: sticky;
	top: 0;
	z-index: 10000;
	opacity: 1;
	width:100%;
	//@include transition(all 0.3s); //no unecessary transation
}

.admin-bar {
	// .top {
	// 	top: 46px;
	// 	@include breakpoint($bp-charlie) {
	// 		top: 32px;
	// 	}
	// }
	// &.sticky {
	// 	.top {
	// 		@include breakpoint($bp-charlie) {
	// 			top: 32px;
	// 		}
	// 	}
	// }
}


//any special rules for the nav when the sticky scrollpoint is hit
//if the body needs rules, then javascript would need to be altered to apply a class to the body
.is-sticky{
	.main-header{
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	}
}


.pusher {
	height: 0px;
	@include transition(all 0.3s);
}

body.search-open {
    .pusher {
        margin-top: 0px;
    }
    .top {
        top: 61px;
    }
    .top-search {
        top: 0;
        opacity: 1;
    }
}

.top-search {
	height: 61px;
	background: #eee;
	padding: 0px 0;
	position: fixed;
	top: 0;
	width: 100%;
	top: -61px;
	@include transition(all 0.3s);
	z-index: 9999;
	opacity: 0;
	.container {
		padding: 0;
	}
	form {
		position: relative;
		height: 61px;
		display: block;
		float: left;
		width: 100%;
	}
	.container {
		width: 100%;
		max-width: 1195px;
	}
	.arrow {
		position: absolute;
		right: 23px;
		top: 50%;
		@include transform(translateY(-50%));
		svg {
			width: 22px;
			color: $body;
			@include hover-fade();
			cursor: pointer;
			&:hover {
				color: $secondary;
			}
		}
	}
	input[type="text"] {
		width: 100%;
		height: 61px;
		background: #eee;
		color: $body;
		font-family: $headline-font;
		font-weight: 400;
		font-size: 29px;
		line-height: 61px;
		letter-spacing: 0;
		opacity: 1;
		padding: 0px 60px 00px 10px;
		border: none!important;
		@include transition(all 0.3s);
		box-shadow: none!important;
		position: relative;
		top: 47%;
		@include transform(translateY(-50%));
		&::-webkit-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&::-moz-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-ms-input-placeholder {
			top: 0%;
			position: relative;
		  	color: $body;
		}
		&:-moz-placeholder { 
			top: 0%;
			position: relative;
		  	color: $body;
		}
	}
	input[type="submit"] {
		display: none;
	}
}

.main-header {
	width: 100%;
	padding: 4px 0;
	height: $header-mobile-height;
	background: $navBackground;
	@include transition(all 0.3s ease-in-out);
	position: relative;
	@include breakpoint($bp-charlie) {
		height: $header-tablet-height;
		padding: 0;
	}
	@include breakpoint($bp-bobby){
		height: $header-height;
		padding: 0 0 14px 0;
	}
	.container-navigation {
		padding: 0 20px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
	}
	.search-icon {
		float: right;
		padding: 10px 10px;
		line-height: 1em;
		margin-right: 40px;
		cursor: pointer;
		@include hover-fade();
		@include breakpoint($bp-charlie) {
			padding: 18px 10px;
			margin-top: 8px;
		}
		@include breakpoint($bp-bobby) {
			margin-right: 0;
			margin-top: 0;
		}
		&:hover {
			i {
				color: $secondary;
			}
		}
		i {
			@include hover-fade();
			color: #bbb;
			font-size: 23px;
			@include breakpoint($bp-charlie) {
				font-size: 19px;
			}
		}
	}
	.main-logo{
		display: inline-block;
	}
	.logo {
		// margin-top: 21px;

		@include breakpoint($bp-charlie) {
			margin-top: 8px;
		}

		@include breakpoint($bp-bobby) {
			margin-top: 21px;
		}

		img, svg {
			display: inline-block;
			width: 110px;
			height: 40px;
			margin: 3px 0 0 0;
			@include hover-fade();

			@include breakpoint($bp-charlie){
				width: 165px;
				height: 76px;
			}

			@include breakpoint($bp-charlie){
				width: 145px;
				height: 52px;
			}

			@include breakpoint($bp-bobby){
				width: 165px;
				height: 76px;
			}
		}
		svg#logo, svg#threeRs {
			.st0{fill:$body;}
			.st1{fill:#FFFFFF;}
			.st2{fill: #0076c0;;}
		}
	}
	.control {
		float: right;
	}
	.social {
		&.links {
			display: none;
			@include breakpoint($bp-echo) {
				display: block;
				height: 30px;
				overflow: hidden;
				float: right;
				margin: 8px 35px 0px 0;
				@include hover-fade();
				color: #fff;
			}
			ul {
				@include breakpoint($bp-echo) {
					float: none;
					min-width: 100px;
				}
			}
			li {
				i {
					font-size: 20px;
				}
				a {
					color: $body;
					padding: 0 15px 0 0;
					&:hover {
						color: $primary;
					}
				}
				&:last-child a{
					padding: 0;
				}
			}
		}
	}
	nav {
		float: right;
		&.primary {
			a {
				color: $navColor;
				&:hover {
					color: $navColorHover;
				}
			}

			ul li{
				&.menu-item-has-children {
						&:hover {
							.sub-menu {
								display: block;
								visibility: visible;
								margin-top: 0;
								opacity: 1;
								li {
									-webkit-animation: fadeIn 0.5s ease-in-out; 
									-webkit-animation-fill-mode: both;
									animation: fadeIn 0.5s ease-in-out; 
									animation-fill-mode: both;
								}
							}
						}
					}
					&:first-child {
						border-left: 0px;
					}
					&:last-child {
						margin-right: 0px;

						.sub-menu{
							left: 0;

							&:before{
								left: 70px;
							}
						}
					}
					.sub-menu {
						width: 270px;
						position: absolute;
						top: 46px;
						left: 58%;
						-webkit-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
						background: rgba($dropDownBg, 1);
						padding: 25px;
						visibility: hidden;
						opacity: 0;
						margin-top: 20px;
						@include hover-fade();
						@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
						&:before {
							content: '';
							width: 0; 
							height: 0; 
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
							border-bottom: 12px solid rgba($dropDownBg, 1);
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							margin: 0 auto;
						}
						&:after {
							content: '';
							height: 22px;
							width: 100%;
							position: absolute;
							top: -22px;
							left: 0;
							background:transparent;
						}
						li {
							width: 100%;
							margin: 0 0 12px 0;
							padding: 0;
							border: 0!important;
							line-height: 125%;
							font-family: $headline-font;
							font-size: 16px;
							text-transform: none;
							&:last-child {
								margin-bottom: 0!important;
							}
							a {
								color: $dropDownColor;
								margin: 0;
								letter-spacing: normal;

								&:hover {
									color: $dropDownHover;
									opacity: 1!important;
								}
							}
						}
					}
			}
		}
	}
	&.threeRs{
		.container-navigation{
			position: relative;
		}
		.control.no-nav{
			@include breakpoint($bp-bobby){
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}
		svg#threeRs{
			// height: 74px;
			// width: 156px;

			.cls-1{fill:#f83180;}.cls-1,.cls-2,.cls-3{fill-rule:evenodd;}.cls-2{fill:#3099ff;clip-path: unset;}.cls-3{fill:#fccb36;}.cls-4{fill:$body;}
		}
	}
}


.main-header nav.primary ul li.menu-item-39 .sub-menu{
	width: 372px;
}

// Navigation
nav {
	&.primary {
		display: none;

		@include breakpoint($bp-bobby) {
			display: block;
		}

		@include breakpoint($bp-charlie) {
			float: right;
			margin: -4px 0 0 0;
			@include hover-fade();
			ul {
				margin: 0 10px 0px 0;
				display: table;
				li {
					margin: 20px 0 0 0;
					padding: 0px 12px;
					position: relative;
					font-family: $headline-font;
					font-weight: bold;
					letter-spacing: 1.1px;
					font-size: 14px;
					text-transform: uppercase;
					font-weight: 600;
					@include breakpoint($bp-echo) {
						padding: 0px 13px;
					}
					&.home {
						display: none;
					}
				}
			}
		}
		/* classes that can be used to style nav when # of items gets really long*/
		&.primary.over-five-items{

		}
		&.primary.over-six-items{

		}
		&.primary.over-fourty-characters{
			// & > ul > li{
			// 	padding: 11px 10px;
			// 	font-size: 14px;
			// }
		}
		&.primary.over-fifty-characters{
			
		}
	}
	&.secondary {
		display: none;
		ul {
			li {
				a {
					display: block;
					float: left;
					text-align: center;
					background: transparent;
					border: 3px solid $tertiary;
					color: $body;
					height: 43px;
					line-height: 39px;
					width: 155px;
					font-size: 16px;
					font-weight: 600;
					font-family: $headline-font;
					position: relative;
					// @include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
					&::before{
						content: '\f105';
						color: $tertiary;
						font-family: 'FontAwesome';
						font-size: 19px;
						position: relative;
						left: -15px;
						line-height: 1em;
						@include hover-fade();
					}
					&:hover {
						background: $tertiary;
						color: #fff;

						&:before{
							color: $body;
							left: -10px;
						}
					}
					@include breakpoint($bp-echo) {
						width: 175px;
					}

					
				}
				&:last-child {
					a {
						background: $secondary;
						border: 0;
						padding: 3px 0;
						&:before{
							color: #fff;
						}
						&:hover {
							background: $quatenary;
							color: #fff;

							&:before{
								color: #fff;
							}
						}
					}
				}
			}
		}
		@include breakpoint($bp-bobby) {
			display: block;
			float: right;
			@include hover-fade();
			// padding-top: 2px;
			ul {
				li {
					&:last-child {
						// margin: 0 0 0 10px;
					}
				}
			}
		}
	}
	@include breakpoint($bp-charlie) {
		ul {
			li {
				float: left;
				list-style-type: none;
			}
		}
	}
}

// Mobile Nav Icon
.hamburger {
	float: right;
	margin: 17px 0 0 0;
	z-index: 11;
	position: absolute;
	top: 0;
	right: 20px;
	z-index: 9998;
	@include hover-fade();
	@include breakpoint($bp-charlie) {
		margin: 26px 0 0 0;
	}
	@include breakpoint($bp-bobby) {
		display: none;
	}
	.hamburger-inner {
		&, &:before, &:after {
			@include hover-fade();
		}
	}
	&:hover {
		.hamburger-inner {
			background-color: $secondary;
			width: 33px;
			&:after, &:before {
				background-color: $secondary;
				width: 33px;
			}
		}
	}
	&.is-active {
		.hamburger-inner {
			&, &:after {
				background-color: white;
				width: 33px;
			}
		}
	}
}

// Mobile Nav
.mobile-nav {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba($body,1);
	z-index: 10;
	height: 100%;
	display: none;
	nav {
		float: left;
		width: 100%;
		&.mobile {
			@include vertical-align();
			ul {
				margin: 0px;
				padding: 0px;
				float: left;
				width: 100%;
				li {
					float: left;
					width: 100%;
					text-transform: uppercase;
					text-align: center;
					font-size: 30px;
					margin: 0 0 10px;
				}
			}
		}
	}
}


/* Menu */
.menu-wrap {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	right: 0;
	background: transparent;
	font-weight: 600;
	opacity: 0;
	@include hover-fade();
	-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	left: 100%;
	.align-middle {
		padding: 0 30px;
		width: 100%;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.menu {
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		li {
			width: 100%;
			float: left;
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			font-family: $headline-font;
			margin-bottom: 0px;
			a {
				color: #fff;
				padding: 5px 0px;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.secondary {
		margin: 20px 0 15px 0;
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		display: block;
		li {
			display: table;
			margin: 0 auto 10px auto!important;
			text-align: center;
			a {
				float: none;
				display: table;
				margin: 0 auto;
				width: 100%;
				height: 52px;
				max-width: 325px;
				font-size: 18px;
			}
		}
	}
	.social-mobile {
		float: left; 
		width: auto;
		width: 100%;
		.social {
			display: table;
			margin: 0 auto;
			float: none;
			width: auto;
			ul {
				li {
					a {
						color: #999999;
						&:hover {
							color: $secondary
						}
						i {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
}

.menu-top {
	line-height: 58px;
}

.menu-top .profile {
	display: inline-block;
	padding: 8px 10px;
	line-height: 42px;
}

.menu-top .profile,
.menu-side {
	width: 300px;
}

.menu-top .profile img {
	float: left;
	margin-right: 1em;
}

.icon-list { 
	display: inline-block;
	font-size: 1.25em;
}

.icon-list a {
	margin: 0 1em 0 0;
	padding: 0;
}

@media screen and (max-width: 32em) {
	.icon-list {
		padding-left: 1em;
	}
}

.menu-side a {
	display: block;
	padding: 1.2em;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.menu-side a:first-child {
	border-top: 1px solid rgba(0,0,0,0.1);
}



#container-wrap {
	float: left;
	width: 100%;
	opacity: 1;
	left: 0;
	@include hover-fade();
}

/* Shown menu */
.show-menu {
	.top {
		opacity: 0;
	}
	#container-wrap {
		left: -100%;
		//opacity: 0;
		-webkit-transform: translate3d(-100%,0,0);
		transform: translate3d(-100%,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
		transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
	}
	.menu-wrap {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		left: 0;
	}
}

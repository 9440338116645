//Variables. Needed for style-guide.

// H1 Super
$h1super 		:  		76px;
$h1superTablet 	:  		54px;
$h1superMobile	:  		36px;
$h1superWeight	:  		600;
$h1superFont	:		$headline-font;
// H1
$h1 			:  		64px;
$h1Tablet 		:  		54px;
$h1Mobile		:  		36px;
$h1Weight		:  		600;
$h1Font			:		$headline-font;
// H2
$h2 			:  		58px;
$h2Tablet 		:  		32px;
$h2Mobile		:  		24px;
$h2Weight		:  		600;
$h2Font			:		$headline-font;
// H3
$h3 			:  		34px;
$h3Tablet 		:  		27px;
$h3Mobile		:  		20px;
$h3Weight		:  		600;
$h3Font			:		$headline-font;
// H4
$h4 			:  		26px;
$h4Tablet 		:  		23px;
$h4Mobile		:  		18px;
$h4Weight		:  		600;
$h4Font			:		$headline-font;
// H5
$h5 			:  		22px;
$h5Tablet 		:  		20px;
$h5Mobile		:  		16px;
$h5Weight		:  		600;
$h5Font			:		$headline-font;
// H6
$h6 			:  		14px;
$h6Tablet 		:  		16px;
$h6Mobile		:  		16px;
$h6Weight		:  		600;
$h6Font			:		$headline-font;
//Body
$p 				:  		18px;
$pTablet 		:  		16px;
$pMobile		:  		16px;
$pWeight		:  		400;
$pFont			:		$primary-font;


h1, .h1 {
	font-family: $h1superFont; 
	color: $headlineColor;
	font-weight: $h1superWeight;
	font-size: $h1Mobile;  // mobile size
	line-height: 106%;
	margin: 0 0 .5em;
	letter-spacing: -0.01em;
	@include breakpoint($bp-charlie) { 
		font-size: $h1Tablet; // tablet size
	}
	@include breakpoint($bp-bobby) { 
		font-size: $h1; //desktop size
	}
	&.super {
		font-size: $h1superMobile;
		line-height: 1.12em;
		@include breakpoint($bp-charlie) { 
			font-size: $h1superTablet;
		}
		@include breakpoint($bp-bobby) { 
			font-size: $h1super;
		}
	}
	i, em, strong {
		font-weight: 600!important;
		font-style: normal!important;
	}
}

h2, .h2 {
	font-family: $h2Font; 
	color: $headlineColor;
	font-weight: $h2Weight;
	font-size: $h2Mobile;
	line-height: 117%;
	letter-spacing: -0.01em;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h2Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h2;
	}
	i, em, strong {
		font-weight: $h2Weight!important;
		font-style: normal!important;
	}
}

h3, .h3 {
	font-family: $h3Font; 
	color: $headlineColor;
	font-weight: $h3Weight;
	font-size: $h3Mobile;
	line-height: 130%;
	letter-spacing: -0.01em;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h3Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h3;
	}
	i, em, strong {
		font-weight: $h3Weight!important;
		font-style: normal!important;
	}
}

h4, .h4 {
	font-family: $h4Font;
	color: $headlineColor;
	font-weight: $h4Weight;
	font-size: $h4Mobile;
	line-height: 140%;
	letter-spacing: 0.1em;
	margin: 0 0 .5em;
	text-transform: uppercase;
	@include breakpoint($bp-charlie) {
		font-size: $h4Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h4;
	}
	i, em, strong {
		font-weight: $h4Weight!important;
		font-style: normal!important;
	}
}

.old-content .content h2[style]{
	font-family: $h4Font!important;
	color: $headlineColor!important;
	font-weight: $h4Weight!important;
	font-size: $h4Mobile!important;
	line-height: 140%!important;
	letter-spacing: 0.1em!important;
	margin: 0 0 .5em;
	text-transform: uppercase;
	@include breakpoint($bp-charlie) {
		font-size: $h4Tablet!important;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h4!important;
	}
	i, em, strong {
		font-weight: $h4Weight!important;
		font-style: normal!important;
	}
}

h5, .h5 {
	font-family: $h5Font; 
	color: $headlineColor;
	font-weight: $h5Weight;
	font-size: $h5Mobile;
	line-height: 140%;
	letter-spacing: 0.02em;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h5Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h5;
	}
	i, em, strong {
		font-weight: $h5Weight!important;
		font-style: normal!important;
	}
}

h6, .h6 {
	font-family: $h6Font;
	color: $headlineColor;
	font-weight: $h6Weight;
	font-size: $h6Mobile;
	line-height: 1.4em;
	letter-spacing: 0.2em;
	text-transform: uppercase;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h6Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h6;
	}
	i, em, strong {
		font-weight: $h6Weight!important;
		font-style: normal!important;
	}
}

p {
	font-family: $primary-font;
	font-weight: $pWeight;
	font-size: $pMobile;
	line-height: 170%;
	margin: 0 0 20px;
	letter-spacing: 0;
	color: $body;
	//-webkit-font-smoothing: auto;// would be more readable and performant....

	&.caption {
		font-size: 14px;
		@include breakpoint($bp-charlie) {
			font-size: $pMobile;
		}
	}
	&.small {
		font-family: $headline-font;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 12px;
	}
	&.teaser {
		font-size: 14px;
		@include breakpoint($bp-charlie) {
			font-size: 16px;
		}
		@include breakpoint($bp-charlie) {
			font-size: $pTablet;
		}
	}
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
		margin: 0 0 25px;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
		margin: 0 0 25px;
	}

	//override inline styles
	.content .full-content & {
		color: $body !important;
		font-family: $primary-font !important;
		// font-size: 12px !important;
		@include breakpoint($bp-charlie) {
			font-size: $pTablet !important;
		}
		@include breakpoint($bp-bobby) {
			font-size: $p !important;
		}
	}
}

body {
	font-family: $pFont;
	font-weight: $pWeight;
	font-size: $pMobile;
	line-height: 150%;
	letter-spacing: 0;
	color: $body;
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
	}
}

.content ul, .content ol {
	font-family: $primary-font;
	margin-left: 15px;
	padding-left: 15px;
	font-size: $pMobile;
	line-height: 150%;
	margin-bottom: 40px;
	font-weight: $pWeight;
	color: $body;
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
		line-height: 30px;
	 	margin-bottom: 30px;
	 	margin-left: 25px;
		padding-left: 25px;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
	}
	ul, ol {
		margin: 5px;
	}
	li {
		color: $body;
		font-family: $primary-font;
		font-weight: $pWeight;
		font-size: $pMobile;
		margin-bottom: 1em;
		@include breakpoint($bp-charlie) {
			font-size: $pTablet;
		}
		@include breakpoint($bp-bobby) {
			font-size: $p;
		}
	}
	li {
		list-style: disc;
	}
}

.old-content .content .module.full-content {
	ul, ol{
		font-family: $primary-font !important;
		font-size: $pMobile !important;
		font-weight: $pWeight !important;
		color: $body !important;
		@include breakpoint($bp-charlie) {
			font-size: $pTablet !important;
		}
		@include breakpoint($bp-bobby) {
			font-size: $p !important;
		}
		li {
			color: $body !important;
			font-family: $primary-font !important;
			font-weight: $pWeight !important;
			font-size: $pMobile !important;
			@include breakpoint($bp-charlie) {
				font-size: $pTablet !important;
			}
			@include breakpoint($bp-bobby) {
				font-size: $p !important;
			}
		}
	}
}

//override client styles
.content{
	p a{
		font-weight: bold;
		text-decoration: underline;

		&.btn{
			text-decoration: none;
		}
	}
	strong{
		background: none !important;
	}
	li {

	}
}

small {
	font-weight: 600;
	font-size: 13px;
	line-height: 1em;
	text-transform: uppercase;
	color: $body;
	font-family: $headline-font;
}

$su-line-distance-mobile: -2px;
$su-line-distance-desktop: 0;

.special-underline{
  position: relative;
	z-index: 1;
	span{
		padding: 0;
		display: inline-block;
		transition: .45s;
		position: relative;

		&:before, &:after{
			background: $secondary;
			content: '';
			transform: skew(-30deg,0);
			width: 106%;
			height: 6px;
			position: absolute;
			bottom: $su-line-distance-mobile;
			@include breakpoint($bp-charlie) {
				bottom: $su-line-distance-desktop;
			}
			padding: 0 1px;
			z-index: -1;
		}
		&:before{
			left: -10px;
		}
		&:after{
			left: 0;
		}

		@include breakpoint($bp-charlie){
			&:before, &:after{
				height: 10px;
			}
		}
	}
}
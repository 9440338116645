.blog{
	#post-wrapper{
		.col-md-6{
			@include breakpoint($bp-charlie){
				padding-left: 50px;
			}

			&:first-child{
				@include breakpoint($bp-charlie){
					padding-right: 50px;
				}
			}
		}
	}
}

.articles {
	float: left;
	width: 100%;
}

.single-post {
	.featured-image, .featured-video {
		max-width: 100%;
		margin: 50px auto 0;
		margin-bottom: 0;
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
		.caption {
			margin-top: 15px;
			p {
				text-align: center;
				font-size: 13px;
			}
		}
		iframe {
			width: 100%;
			height: 280px;
			@include breakpoint($bp-charlie) {
				height: 100%;
				min-height: 600px;
			}
			@include breakpoint($bp-bobby) {
				min-height: 700px;
			}
		}
	}
}

.post-feed {
	.content {
		// background: #eeeeee;
		padding: 30px 20px;
		@include breakpoint($bp-charlie) {
			padding: 50px 40px;
		}
		.container {
			padding: 0;
			@include breakpoint($bp-charlie) {
				width: 1115px;
			}
		}
	}
	.featured-content {
		position: relative;
		margin-bottom: 20px;
		a.full-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.featured-image {
			width: 100%;
			background-size: cover!important;
			background-position: 50% 50%;
			height: 210px;
			// @include breakpoint($bp-charlie) {
			// 	height: 380px;
			// }
			// @include breakpoint($bp-bobby) {
			// 	height: 520px;
			// }
			// @include breakpoint($bp-echo) {
			// 	height: 555px;
			// }
		}
		.featured-video {
			margin-bottom: 0;
			iframe {
				width: 100%;
				height: 240px;
				@include breakpoint($bp-charlie) {
					height: 380px;
				}
				@include breakpoint($bp-bobby) {
					height: 520px;
				}
				@include breakpoint($bp-echo) {
					height: 555px;
				}
			}
		}
	}
}

article {
	float: left;
	width: 100%;
	position: relative;
	//background: #fff;
	margin-bottom: 25px;
	@include hover-fade();
	padding:  25px 0px;
	a:target + &{
		background: $tertiary;//$highlight-color;
		border-color: $tertiary;//$highlight-color;
		color:  #fff;
		.meta span, .meta a, h3, p, .excerpt, .read-more a, .read-more svg, .read-more .cls-3{
			color: #fff !important;
			fill: #fff !important;
		}
		.content-wrapper{
			border-bottom: $tertiary;//$highlight-color;
		}
		@include box-shadow(10.6px, 10.6px, 40px, rgba(0, 0, 0, 0.2));
	}
	@include breakpoint($bp-charlie) {
		padding:  40px 40px 0;
		margin-bottom: 45px;
		z-index: 9;
		&:before{
			pointer-events:none;
			content: '';
			position: absolute;
	    width: 100%;
	    height: 100%;
	    top: 0%;
	    left: 0;
	    background: transparent !important;
	    transition: .2s;
	    z-index:-1;
	    will-change: background-color transform;

		}

		&:hover {
			//background: $primary !important;
			//border-color: $primary !important;
			z-index: 99999;
			&:before{
				background: $primary !important;
				transition: .4s;
				opacity:1;
				transition: .1s;
				transform: scale3d(1.05, 1.05, 1);
				@include box-shadow(10.6px, 10.6px, 40px, rgba(0, 0, 0, 0.2));
				z-index: 0;
			}

			.meta span, .meta a, h3, p, .excerpt{
				color: #fff
			}
			.overlay-share {
				opacity: 1;
			}
			.content-wrapper{
				border-color: $primary;
			}
			.main-content {
				h3 {
					a {
						color: #fff;

						&:hover{
							color: $secondary;
						}
					}
				}
				.read-more {
					a {
						color: #fff;
						
						svg .cls-3{
							fill: #fff;
						}
						&:hover{
							color: $secondary;

							svg .cls-3, svg#download{
								fill: $secondary;
							}
						}
					}
					.download-link{
						z-index:99999;
					}
				}
			}
		}
	}
	.content-wrapper{
		float: left;
		width: 100%;
		border-bottom: 3px solid #bbb;
		@include hover-fade();
		display: flex;
		flex-direction: column;
	}
	.overlay-share {
		position: absolute;
		right: 0;
		bottom: 0;
		@include hover-fade();
		opacity: 0;
		z-index: 100;
		.social {
			margin: 0;
			float: right;
			display: none;
			height: 77px;
			background: transparent;
			padding: 25px;
			@include breakpoint($bp-bobby) {
				display: block;
			}
			span {
				margin: 4px 10px 0;
				font-family: $headline-font;
				color: #fff;
				text-transform: none;
				font-weight: 600;
				font-size: 14px;
				display: none;
			}
			ul {
				li {
					a {
						font-size: 18px;
						padding: 0px 8px;
						color: #fff;
						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}
	}
	&:last-child {
		margin: 0px;
		// border-bottom: 0px;
	}
	img {
		max-width: 100%;
		height: auto;
		margin: 0;
	}
	.main-content {
		width: 100%;
		float: left;
		position: relative;
		display: flex;
		flex-direction: column;


		.full-link {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 99;
		}
	}
	h3, .excerpt {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		width: 100%;
		margin: 0 auto 20px;
		max-width: 785px;
	}
	.excerpt{
		@include hover-fade();
	}
	.excerpt p:last-of-type {
		margin-bottom: 0;
	}
	h3 {
		font-size: 26px;
		line-height: 1.38;
		letter-spacing: -0.3px;
		a {
			color: $body;
			text-decoration: none !important;
			transition:none;
		}
	}
	.read-more {
		display: table;
		width: 100%;
		margin: 0 auto 60px;
		max-width: 785px;
		a {
			color: $primary;
			font-size: 16px;
			font-family: $headline-font;
			font-weight: 600;
			position: relative;
			padding-right: 30px;
			line-height: 1em;
			text-decoration: none;
			&:hover {
				color: $secondary
			}

			span{
				display: inline-block;
				border-bottom: 3px solid;
				line-height: 1em;
			}

			svg{
				height: 14px;
				width: 14px;
				margin-right: 5px;

				.cls-3{
					fill: $primary;
					@include hover-fade();
				}
			}

			i{
				font-size: 13px;
				margin-right: 5px;
			}
			&:hover{
				color: $tertiary;

				svg .cls-3, svg#download{
					fill: $tertiary;
				}
			}
		}
	}
	.meta {
		display: table;
		width: 100%;
		margin: 0 auto 30px;
		max-width: 785px;
		position: relative;
		z-index: 101;

		> span {
			font-size: 15px;
			font-family: $headline-font;
			font-weight: 600;
			color: $details;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 3px;
			@include breakpoint($bp-charlie) {
				font-size: 12px;
			}
			&.separator{
				color: $details;
			}
			a {
				color: $quatenary;
				text-decoration: none;
				z-index: 101;
				&:hover {
					color: $secondary
				}
			}
		}
		a {
			color: $quatenary;
			text-decoration: none;
			text-transform: uppercase;
			font-size: 15px;
			font-family: $headline-font;
			letter-spacing: 3px;
			display: inline-block;
			&:hover {
				color: $secondary
			}
		}
	}
	.btn {
		@include breakpoint($bp-charlie) {
			float: left;
		}
	}
}

.category-style {
	color: $quatenary;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 15px;
	font-family: $headline-font;
	letter-spacing: 3px;
	display: inline-block;
	margin-right: 20px;
	&:hover {
		color: $secondary;
	}
}

.topper-posts .page-meta a{
	@extend .category-style;
	margin-right: 0;
}

.learn-more {
	position: relative;
	float: left;
	width: 100%;
	margin-bottom: 65px;
	margin-top: 30px;
	.go-back {
		margin: 0 auto;
		max-width: 750px;
		padding: 0 20px;
		text-align: center;
		a {
			position: relative;
			font-family: $headline-font;
			font-size: 16px;
			font-weight: 600;
			color: $primary;
			padding-left: 15px;
			&:after {
				left: 0;
				content: "\f104";
				position: absolute;
				font-size: 20px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: bold;
				text-decoration: inherit;
				bottom: -3px;
			}
			&:hover {
				color: $quatenary
			}
		}
	}
	.inner-nav {
		width: 100%;
		position: relative;
		max-width: 1170px;
		margin: 35px auto 0;

		.next-post {
			float: left;
			width: 100%;
			border: 8px solid $body;
			height: auto;
			position: relative;
			@include hover-fade();
			@include breakpoint($bp-charlie) {
				width: calc(50% - 15px);
				margin-right: 15px;
				height: 385px;
			}
			
			a.full-link {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				z-index: 99;
			}
			.arrow{
				position: absolute;
				width: 60px;
				height: 60px;
				top: 20px;
				left: 20px;
				@include breakpoint(550px){
					margin: 40px auto 0;
					margin: 0;
				}

				@include breakpoint($bp-charlie) {
					top: 30px;
					left: 30px;
				}

				@include breakpoint($bp-bobby) {
					top: 50px;
					left: 60px;
				}
				svg{
					fill: $body;
					@include hover-fade();
				}
			}
			.wrapper {
				margin: 0;
				padding: 90px 30px 40px;
				left: auto;
				right: 0;
				z-index: 98;
				@include hover-fade();
				@include breakpoint(550px){
					padding: 55px 30px;
					// margin: 0 50px 0 auto;
					max-width: 365px;
				}
				@include breakpoint($bp-charlie) {
					@include vertical-align(absolute);
					margin: 20px 20px 0 auto;
				}
				@include breakpoint($bp-bobby){
					margin: 0 50px 0 auto;
				}
				h3, p {
					text-align: right;
				}
				h3 {
					color: $body;
					margin: 20px 0px 0;
					font-size: 26px;

					&.long-title{
						font-size: 22px;
					}
				}
				p.tiny {
					color: $quatenary;
					font-family: $headline-font;
					font-size: 12px;
					font-weight: 600;
					margin: 0 0 40px;
					position: relative;
					text-transform: uppercase;
					letter-spacing: 3px;
					@include breakpoint($bp-charlie){
						margin: 0 0 60px;
					}
					@include breakpoint($bp-bobby){
						margin: 0 0 80px;
					}
					span {
						position: relative;
						padding-left: 15px;
						
						&.date {
							color: $body;
							opacity: 0.5;
							display: block;

							@include breakpoint($bp-charlie){
								display: inline-block;
							}
						}
					}
				}

			}
			&:hover {
				background: $primary!important;
				border-color: $primary;
				box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);

				.arrow svg{
					fill: #fff;
				}

				.wrapper{
					h3, p, p span.date{
						color: #fff;
					}
				}
			}
			&.float-right {
				// background: $primary;
				@include breakpoint($bp-charlie){
					margin-left: 15px;
					margin-right: 0;
					width: calc(50% - 15px);
				}
				.arrow{
					top: 20px;
					right: 20px;
					left: auto;

					@include breakpoint($bp-charlie) {
						top: 30px;
						right: 30px;
					}

					@include breakpoint($bp-bobby) {
						top: 50px;
						right: 60px;
					}
				}
				.wrapper {
					left: 0;
					right: auto;
					@include breakpoint($bp-charlie) {
						margin: 20px auto 0 20px;
					}
					@include breakpoint($bp-bobby){
						margin: 0 auto 0 50px;
					}
					h3, p{
						text-align: left;
					}
					.tiny {
						span {
							padding-left: 0;
							padding-right: 15px;
						
						}
					}
				}
			}
			&.float-left{
				margin-bottom: 30px;
				display: none;
				@include breakpoint($bp-charlie){
					display: block;
					margin-bottom: 0;
				}
				svg{
					@include transform(rotate(-180deg));
				}
			}
		}
		.only-post {
			width: 100% !important;
		}
	}
}

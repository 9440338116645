.post-feed.threeRs{
    .col-md-10{
        float: none;
        margin: 0 auto;
        display: block;
    }
    .topper-posts{
        padding: 70px 0 20px;
        @include breakpoint($bp-charlie) {
            padding: 100px 0 60px;
        }
        
    }
    .simple-topper{

        .col-md-8{
            float: none;
            margin: 0 auto;
            display: block;
        }

        h1{
            margin-bottom: 30px;

            span.word1, span.word2{
                &:before, &:after{
                    background: $tertiary;
                }
            }

            span.word3{
                &:before, &:after{
                    background: $secondary;
                }
            }
        }
        
        .sub-nav{
            padding: 0;
            margin-bottom: 25px;
            margin-top: 30px;
            float: none;
    
            nav{
                margin-bottom: 0;
            }
    
            .topper-menu{
                display: block;
            }
    
            ul{
                li{
                    list-style: none;
                    margin: 0;
                    
                    a{
                        margin-top: 0; 
                    }
                }
            }
        }
    }

    .filter-form {
        form >ul {
            
            @include breakpoint($bp-bobby){
                display: flex;
            }
            
            >li{
                display: block;
                min-width: 100%;
                
                @include breakpoint($bp-bobby){
                    display: inline-block;
                    margin-right: 15px;
                    min-width: calc(25% - 15px);
                }
                
                @include breakpoint($bp-echo){
                    min-width: 221px;
                    max-width: 221px;
                }
    
                &:last-child{
                    margin-right: 0;
                }
    
                span.label{
                    padding-left: 10px;
                }
    
                label{
                    width: 100%;
                    min-width: inherit;
                    min-height: 60px;
                }
    
                .selectric-wrapper{
                    padding: 0;
    
                    &.selectric-open{
                        &[data-sf-field-input-type="multiselect"]{
    
                            .selectric{
                                .button{
                                    svg{
                                        fill: #fff;
    
                                        .cls-1{
                                            fill: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
    
                .selectric-items{
                    background: $body;
                    border: 0;
                    padding: 0;
    
                    &:after{
                        display: none;
                    }
    
                    ul li{
                        margin-bottom: 8px;
                        
                        @include breakpoint($bp-echo){
                            font-size: 12px;
                        }
                    }
                }
    
                .selectric-scroll{
                    padding: 10px;
                }
    
                &[data-sf-field-input-type="multiselect"]{
                    // border: 1px solid red;

                    .selectric-items ul li{
                        word-wrap: break-word;
                        padding-right: 20px;

                        &:after{
                            display: none;
                        }

                        &:hover{
                            background: transparent;
                            color: $tertiary;
                            position: relative;

                            &:after{
                                display: block;
                                font-family: 'FontAwesome';
                                font-size: 13px;
                                content: "\f067";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }

                        &.highlighted, &.selected{
                            background: transparent;
                            color: $tertiary;
                            position: relative;

                            &:after{
                                display: block;
                                font-family: 'FontAwesome';
                                font-size: 13px;
                                content: "\f067";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                            }
                        }

                        &.selected:after{
                            content: "\f068";
                            z-index: 2;
                        }
                    }
    
                    .selectric{
                        .button{
                            width: 24px;
    
                            svg{
                                fill: $details;
                                position: absolute;
                                top: 50%;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);
                                left: 0;
                                right: 0;
                                
                                .cls-1{
                                    fill: $details;
                                    @include hover-fade();
                                }
                            }
                            &:hover{
                                svg{
                                    fill: $tertiary;

                                    .cls-1{
                                        fill: $tertiary
                                    }
                                }
                            }
                            &:after{
                                border: 0;
                                height: 24px;
                                width: 24px;
                            }
                        }
                        
                    }
    
                    .chosen-container{
                        min-width: 100%;
                        max-width: 100%;
                        //@include hover-fade();
                        
                        .chosen-choices{
                            background-image: none;
                            border: 0;
                            overflow: auto;
                            //@include hover-fade();
                        }

                        &.empty .chosen-choices{
                            border: 0;
                            box-shadow: none;
                            max-height: 0;
                            padding: 0;
                        }
                        &.not-empty .chosen-choices{
                            border: 2px solid $details;
                            max-height: 150px;
                            padding: 7px;
                        }

                        .chosen-drop{
                            display: none !important;
                        }
                        input{
                            display: none !important;
                        }
                    }

                    .chosen-container-multi .chosen-choices{
                        

                        .search-choice{
                            padding: 9px 20px 9px 9px;
                            background: $tertiary;
                            color: #fff;
                            width: 100%;
                            margin-left: 0;
                            margin-right: 0;
                            border: 0;
                            box-shadow: none;
                            font-size: 13px;
                            font-family: $headline-font;
                            @include hover-fade();
                            cursor:pointer;

                            .search-choice-close{
                                background: 0;
                                pointer-events:none;
                                right: 5px;
                                top: 50%;
                                width: auto;
                                height: 12px;
                                -webkit-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                                transform: translateY(-50%);

                                &:after{
                                    content: '\f00d';
                                    color: $body;
                                    font-family: 'FontAwesome';
                                    font-size: 12px;
                                    position: relative;
                                    @include hover-fade();
                                    z-index: 2;
                                    font-weight: bold;
                                    line-height: 1em;
                                }
                            }

                            &:hover{
                                background: $secondary;
                                color: $body;
                            }
                        }
                    }
                }
                
            }
        }
        
        .selectric{
            max-width: 100%;

            .button{
                margin-right: 10px;
            }

            @include breakpoint($bp-charlie){
                // max-width: 118px;
            }
            @include breakpoint($bp-bobby){
                // max-width: 142px;
            }
            @include breakpoint($bp-echo){
                // max-width: 178px;
            }
        }
    }
}

.filter-form{
    visibility: hidden;
}
.wf-active .filter-form, .no-js .filter-form{
    visibility: visible;
}

#threeRs-wrapper{
    float: left;
    width: 100%;
    @include hover-fade();

    @include breakpoint($bp-charlie, max){
        padding: 0 10px;
    }
    
    .articles{
        float: none;
        article{
            z-index: 0;
            &:hover, a:target + &{
                z-index: 1;
            }
            .meta a{
                font-size: 13px;

                span{
                    color: inherit;
                    word-break: break-word;
                }
            }

            span{
                &.separator{
                    color: $details;
                }
            }
            .content-wrapper{
                float: none;
            }
            .main-content{
                float: none;
            }
            .category{
                margin: 0;
                line-height: 130%;

                span{
                    margin: 0;
                }
            }
            .link3r{
                margin: 0;
            }
        }
    }
    .col-xs-12.col-md-6{
        padding: 0;
    }
}

@include breakpoint($bp-charlie, max){
    .searchandfilter li, .searchandfilter label{
        width:100%;
    }
}
@include breakpoint($bp-charlie){
    .search-no-match-text {
        padding-left: 15px;
    }
}

//targeting specific 3rs
.search-anchor{
    display: block;
    position: relative;
    top: calc(20vh + 105px);
    visibility: hidden;
    pointer-events:none;
}
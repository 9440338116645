$buttonBorderRadius: max($borderRadius - 1px, 0px);//not sure why button is 1px less than modules but it is atm

.btn {
	background: transparent;
	border-radius: $buttonBorderRadius;
	border: 3px solid $primary;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	color: $body;
	cursor: pointer;
	display: block;//should be inline-block, for the base case...
	font-size: 17px;
	font-weight: 600;
	font-family: $headline-font;
	line-height: 1.5em;
	@include hover-fade();
	@include breakpoint($bp-bravo) {
		min-width: 265px;
	}
	max-width: 365px;
	padding: 10px 20px;
	text-align: center;
	width: auto;
	@include breakpoint($bp-alpha, max) {
		width: 100%;
	}

	&:hover {
		background: $primary;
		color: #fff;
	}

	&.alt {
		background: $secondary;
		&:hover {
			background: $primary;
		}
	}
}
button.btn{
	background: $primary;
	color: #fff;
	position: relative;
	&::before{
		content: '\f105';
		color: $body;
		font-family: 'FontAwesome';
		font-size: 20px;
		position: relative;
		left: -15px;
		@include hover-fade();
	}

	&:hover {
		border-color: $secondary;
		background: $secondary;
		color: $body;

		&::before{
			color: #fff;
			left: -10px;
		}
	}
}
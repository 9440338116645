.all-events {
	float: left;
	width: 100%;
	margin-bottom: 50px;
	.col-md-8 {
		margin: 0 auto;
		display: table;
		float: none;
	}
}

.event-info {
	float: left;
	width: 100%;
	.map {
		width: 100%;
		height: 400px;
		margin: 20px 0;
	}
	.map img {
	   max-width: inherit !important;
	}
}

.top-link {
	text-align: center;
	margin-bottom: 35px;
	margin-top: -15px;
	a {
		float: none;
		display: inline-block;
	}
}

.bottom-link {
	text-align: center;
	margin-top: 35px;
	a {
		float: none;
		display: inline-block;
	}
}

.module {
	&.full-content {
		.col-md-10 {
			float: none;
			margin: 0 auto;
		}
		.event-info {
			.col-md-8 {
				float: left;
				margin: 0px;
			}
		}
	}
}

.event-info-block {
	float: left;
	width: 100%;
	margin: 0 0 20px;
}

.event-set {
	float: left;
	width: 100%;
	margin: 0 0 40px;
	@include breakpoint($bp-charlie) {
		margin: 0 0 75px;
	}
	h3 {
		text-align: center;
		margin-top: 0;
		margin-bottom: 30px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 45px;
			margin-top: 0;
		}
	}
	.event-item {
		&:last-child {
			margin: 0px;
			padding: 0px;
			border-bottom: 0px;
		}
	}
}

.event-item {
	float: left;
	width: 100%;
	border-bottom: 2px solid $tertiary;
	padding: 0 0 40px;
	margin: 0 0 40px;
	.col-sm-8 {
		float: left;
		margin: 0px;
	}
	.col-sm-4 {
		float: right;
		img {
			width: 100%;
			height: auto;
		}
	}
}

.single-event {
	.full-content {
		margin-top: 30px;
	}
}

h4.event-title {
	margin-top: 0;
	margin-bottom: 5px;
	text-align: left!important;
}

.event-meta {
	float: left;
	width: 100%;
	margin: 5px 0 10px;
	font-size: 17px;
	font-family: $headline-font;
	font-weight: 400;
	@include breakpoint($bp-charlie) {
		margin: 10px 0 15px;
	}
}

.event-links {
	margin-top: 15px;
	overflow: hidden;
	@include breakpoint($bp-charlie) {
		margin-top: 25px;
	}
	a {
		font-family: $headline-font;
		font-weight: 600;
		font-size: 16px;
		margin-right: 35px;
		position: relative;
		padding-right: 15px;
		&.learn {
			&:after {
				right: 0;
				content: "\f105";
				position: absolute;
				font-size: 18px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				bottom: -4px;
			}
		}
	}
}

.static-map{
	max-width: 100%;

	img{
		max-width: 100%;
	}
}
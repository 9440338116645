.noScroll {
	overflow: hidden;
}

/* Layout for search container */
.no-js .searchOverlay {
	display:none;
}

.searchOverlay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: rgba(#fff, 0.98);
	z-index: 9999!important;
}

.js .searchOverlay {
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
  right: 0;
  bottom: 0;
	z-index: 9999;
}

.searchOverlay::before,
.searchOverlay::after {
	content: '';
	position: absolute;
	width: calc(100% + 15px);
	height: calc(100% + 15px);
	pointer-events: none;
	border: 1em solid $details;
}

.searchOverlay::before {
	top: 0;
	left: 0;
	border-right-width: 0;
	border-bottom-width: 0;
}

.searchOverlay::after {
	right: 0;
	bottom: 0;
	border-top-width: 0;
	border-left-width: 0;
}

.btn--search-close {
	font-size: 2em;
	position: absolute;
	top: 1em;
	right: 1.25em;
	display: none;
	cursor: pointer;
}

.js .btn--search-close {
	display: block;
}

.search__form {
	margin: 5em 0;
}

.search__input {
	font-family: inherit;
	font-size: 6vw;
	line-height: 1;
	display: inline-block;
	box-sizing: border-box;
	width: 90%;
	padding: 0.05em 0;
	color: $body;
	border-bottom: 2px solid $primary;
	font-family: $headline-font;
	font-weight: 400;

	/* Remove Firefox focus ring */
	&:focus {
		outline: none;
	}

	@include breakpoint($bp-bravo) {
		min-width: 325px;
		width: 60%;
	}
}

.search__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	opacity: 0.85;
	color: $details;
}

.search__input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	opacity: 0.85;
	color: $details;
}

.search__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	opacity: 0.85;
	color: $details;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
	-webkit-appearance: none;
}

.search__input::-ms-clear {
	display: none;
}

.search__info {
	font-size: 80%;
	font-weight: bold;
	display: block;
	width: 90%;
	margin: 0 auto;
	padding: 0.65em 0;
	text-align: right;
	color: $primary;
	font-style: $headline-font;

	@include breakpoint($bp-bravo) {
		min-width: 325px;
		width: 60%;
	}
}

.search__related {
	display: flex;
	width: 75%;
	pointer-events: none;
}

.search__suggestion {
	width: 33.33%;
	text-align: left;
}

.search__suggestion:nth-child(2) {
	margin: 0 3em;
}

.search__suggestion h3 {
	font-size: 1.35em;
	margin: 0;
}

.search__suggestion h3::before {
	content: '\21FE';
	display: inline-block;
	padding: 0 0.5em 0 0;
}

.search__suggestion p {
	font-size: 1.15em;
	line-height: 1.4;
	margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

.js .searchOverlay {
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s;
	z-index:-1;
}

.js.ie10 .searchOverlay {
	z-index:-1 !important;
}

.js .search--open {
	pointer-events: auto;
	opacity: 1;
	z-index: 99999 !important;
}



/* Border */
.searchOverlay::before,
.searchOverlay::after {
	transition: transform 0.5s;
}

.searchOverlay::before {
	transform: translate3d(-15px, -15px, 0);
}

.searchOverlay::after {
	transform: translate3d(15px, 15px, 0);
}

.search--open::before,
.search--open::after {
	transform: translate3d(0, 0, 0);
}

/* Close button */
.btn--search-close {
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: opacity 0.5s, transform 0.5s;
	svg {
		width: 35px;
		.color {
			fill: $details;
			@include transition(all 0.2s ease);
		}
	}
	&:hover {
		svg .color {
			fill: $primary;
		}
	}
}

.search--open .btn--search-close {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

/* Search form with input and description */
.js .search__form {
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: opacity 0.5s, transform 0.5s;
}

.js .search--open .search__form {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

.search__suggestion {
	opacity: 0;
	transform: translate3d(0, -30px, 0);
	transition: opacity 0.5s, transform 0.5s;
}

.search--open .search__suggestion {
	opacity: 1;
	transform: translate3d(0, 0, 0);
}

.search--open .search__suggestion:nth-child(2) {
	transition-delay: 0.1s;
}

.search--open .search__suggestion:nth-child(3) {
	transition-delay: 0.2s;
}

@media screen and (max-width:40em) {
	.search__form {
		margin: 5em 0 1em;
	}
	.btn--search-close {
		font-size: 1.25em;
		top: 1.5em;
		right: 1.5em;
		svg {
			width: 25px;
		}
	}
	.search__info {
		text-align: left;
	}
	.search__suggestion {
		font-size: 80%;
		width: 100%;
	}
	.search__suggestion:nth-child(2),
	.search__suggestion:nth-child(3) {
		display: none;
	}
}



/* Layout for menu container */
.menuOverlay {
	background: rgba(#fff, 0.98);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.social-mobile {
		display: block;
		width: 100%;
		text-align: center;

		.social.links {
			float: none;
			display: inline-block;
			margin-top: 0;
			width: auto;
			ul {
				li {
					a {
						font-size: 24px;
						@include breakpoint($bp-bravo) {
							font-size: 29px;
						}
						@include breakpoint($bp-charlie) {
							font-size: 35px;
						}
						color: $details;
						opacity: 1;
						&:hover {
							color: $navColor;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}
.main-mobile-menu {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: 0;
	padding: 0;
	flex: 1 0 auto;

	ul.menu {
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			font-size: 25px;
			line-height: 130%;
			margin-bottom: 8px;
			@include breakpoint($bp-bravo) {
				font-size: 28px;
			}
			@include breakpoint($bp-charlie) {
				font-size: 38px;
			}
			 a {
			 	font-family: $headline-font;
			 	color: $navColor;
			 	padding: 5px;
			 	&:hover {
					color: $navColor;
					background: $tertiary;
			 	}
			 }
		}
	}
	nav {
		&.secondary {
			display: block;
			text-align: center;
			float: left;
			width: 100%;
			margin: 40px auto;
			ul {
				display: block;
				margin: 0 auto;
				float: none;
				li {
					float: none;
					display: block;
					width: 100%;
					max-width: 275px;
					margin: 0 auto !important;
					text-align:center;
					a {
						display: inline-block;
						height: 50px;
						line-height: 48px;
						float:none;
						padding: initial;
						width: 100%;
						max-width: 95%;
						margin: 0 auto;
					}
				}
				li + li {
					a{
						margin-top: 10px;
					}
				}
			}
		}
	}
}
.no-js .menuOverlay{
	display:none;
}
.js .menuOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding-top:50px;
	min-height: 100%;
	min-height: 100vh;
}


.mobile-nav-footer{
	color: $navColor;
	flex: 0 1 0;
	margin-bottom: 20px;
	@include breakpoint($bp-adams){
		margin-bottom: 100px;
	}
}


.menuOverlay::before,
.menuOverlay::after {
	content: '';
	position: absolute;
	width: calc(100% + 15px);
	height: calc(100% + 15px);
	pointer-events: none;
}

.menuOverlay::before {
	top: 0;
	left: 0;
	border-right-width: 0;
	border-bottom-width: 0;
}

.menuOverlay::after {
	right: 0;
	bottom: 0;
	border-top-width: 0;
	border-left-width: 0;
}

.btn--menu-close {
	font-size: 1em;
	position: absolute;
	top: 1em;
	right: 1.25em;
	display: none;
	cursor: pointer;
	z-index: 99;
	-webkit-tap-highlight-color: transparent;
	@include breakpoint($bp-charlie) {
		font-size: 2em;
	}
}

.js .btn--menu-close {
	display: block;
}

.menu__form {
	margin: 5em 0;
}

.menu__input {
	font-family: inherit;
	font-size: 10vw;
	line-height: 1;
	display: inline-block;
	box-sizing: border-box;
	width: 75%;
	padding: 0.05em 0;
	color: $details;
	border-bottom: 2px solid $primary;
}

.menu__input::-webkit-input-placeholder {
	/* WebKit, Blink, Edge */
	opacity: 0.85;
	color: $details;
}

.menu__input::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	opacity: 0.85;
	color: $details;
}

.menu__input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	opacity: 0.85;
	color: $details;
}

.menu__input::-webkit-menu-cancel-button,
.menu__input::-webkit-menu-decoration {
	-webkit-appearance: none;
}

.menu__input::-ms-clear {
	display: none;
}

.menu__info {
	font-size: 90%;
	font-weight: bold;
	display: block;
	width: 75%;
	margin: 0 auto;
	padding: 0.85em 0;
	text-align: right;
	color: $primary;
	font-style: $headline-font;
}

.menu__related {
	display: flex;
	width: 75%;
	pointer-events: none;
}

.menu__suggestion {
	width: 33.33%;
	text-align: left;
}

.menu__suggestion:nth-child(2) {
	margin: 0 3em;
}

.menu__suggestion h3 {
	font-size: 1.35em;
	margin: 0;
}

.menu__suggestion h3::before {
	content: '\21FE';
	display: inline-block;
	padding: 0 0.5em 0 0;
}

.menu__suggestion p {
	font-size: 1.15em;
	line-height: 1.4;
	margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

.js .menuOverlay {
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s;
}

.js .menu--open {
	pointer-events: auto;
	opacity: 1;
	z-index: 9999;
}

/* Border */
.menuOverlay::before,
.menuOverlay::after {
	transition: transform 0.5s;
}

.menuOverlay::before {
	transform: translate3d(-15px, -15px, 0);
}

.menuOverlay::after {
	transform: translate3d(15px, 15px, 0);
}

.menu--open::before,
.menu--open::after {
	transform: translate3d(0, 0, 0);
}

/* Close button */
.btn--menu-close {
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: opacity 0.5s, transform 0.5s;
	svg {
		width: 25px;
		.color {
			fill: $details;
			@include transition(all 0.2s ease);
		}
		@include breakpoint($bp-charlie) {
			width: 35px;
		}
	}
	&:hover {
		svg .color {
			fill: $primary;
		}
	}
}

.menu--open .btn--menu-close {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

/* menu form with input and description */
.js .main-mobile-menu {
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: opacity 0.5s, transform 0.5s;
}

.js .menu--open .main-mobile-menu {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

@media screen and (max-width:40em) {
	.js .menuOverlay {
		padding: 5em 0 1em;
	}
	.btn--menu-close {
		font-size: 1.25em;
		top: 0;
		right: 0;
		padding: 20px;
		svg {
			width: 25px;
		}
	}
	.menu__info {
		text-align: left;
	}
}

.js .temp-high-z {
	z-index: 99999 !important;
}
/* Take Action Menu*/

// Take Action Menu
.take-action-menu {
	background: rgba(white, .98);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background: rgba(#fff, 0.97);
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s;
	.align-middle{
		overflow-y: auto;
	}
	&.take-action-menu--open {
		pointer-events: auto;
		opacity: 1;
		z-index: 999991;
	}
	.action-centered {
		text-align: center;
	}
	.container {
		width: 100%;
	}
	.action-title{
		max-width: 500px;
		margin: 0 auto;

		h2 {
			font-size: 28px;
			@include breakpoint($bp-charlie){
				font-size: 32px;
			}
			@include breakpoint($bp-bobby){
				font-size: 58px;
			}
			span{
				&:after, &:before{
					background: $primary;
					height: 7px;
	
					@include breakpoint($bp-echo){
						height: 10px;
					}
				}
			}
		}
		

		p{
			font-size: 18px;
			padding: 0 30px;
			line-height: 150%;

			@include breakpoint($bp-charlie){
				font-size: 20px;
				padding: 0;
				line-height: 170%;
			}
		}
	}
	.full-link{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
	.read-more {
		.under {
			color: $primary;
			&:before{

			}
		}
		a{
			padding-bottom: 6px;
		}
		@include breakpoint($bp-charlie, max) {
			margin-top: 10px;
		}
		@include breakpoint($bp-charlie) {
			margin: 0px 15px;
			display: inline-block;
			width: auto;
		}
		@include breakpoint($bp-bobby) {
			.under {
				font-size: 20px;
			}
		}

	}
	.action-buttons {
		font-size: 0px;
		margin-bottom: 15px;
	}
	.action-item {
		width: 100%;
		height: 125px;
		position: relative;
		@include box-shadow(0px, 10px, 20px, 0px, rgba(0, 0, 0, 0.1));
		margin: 1% 0;
		background: $secondary;
		@include hover-fade();
		.arrow {
			width: 42px;
			height: 42px;
			position: absolute;
			right: 25px;
			bottom: 25px;
			transition: right 0.35s 0s, opacity 0.2s 0.1s;
			svg {
				height: 42px;
				width: 42px;
				.cls-1 {
					fill:$body;stroke-width:2.2;stroke-miterlimit:10;
					@include hover-fade();
				}
			}
		}
		h4 {
			line-height: 110%;
			margin: 10px auto 0px;
			@include hover-fade();
			font-family: $headline-font;
			text-align: left;
			@include breakpoint($bp-charlie) {
				margin: 10px 0 10px;
			}
			// @include breakpoint-width($bp-bravo-wide) {
			// 	font-size: 15px;
			// }
		}
		p {
			margin-bottom: 0px;
			max-height: 0;
			text-align: left;
			font-size: 13px;
			line-height: 150%;
			//opacity: 0;
			color: #fff;
    		@include transition(all .35s ease);
    		overflow: hidden;
    		display: none;
    		@include breakpoint($bp-charlie) {
    			display: block;
			}
			@include breakpoint($bp-bobby){
				font-size: 14px;
				line-height: 170%;
			}
		}
		.wrapper {
			position: absolute;
			top: 20%;
			// @include transform(translateY(-50%));
			padding: 0px 20px;
			width: 100%;
			@include breakpoint($bp-charlie) {
				padding: 0px 30px;
			}
			@include breakpoint($bp-bobby) {
				padding: 0px 50px;
			}
		}
		@include breakpoint($bp-charlie) {
			height: 200px;
			margin: 1% 1%;
			width: calc(33.333% - 2%);
			display: inline-block;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			height: 260px;
		}
		&:hover {
			background: $tertiary;
			@include box-shadow(0px, 10px, 40px, 0px, rgba(0, 0, 0, 0.2));
			@include transform(scale3d(1.1, 1.1, 1));
			z-index: 2;
			.arrow {
				// right: 5px;
				opacity: 0;

				@include breakpoint($bp-bobby){
					opacity: 1;
				}

				svg .cls-1{
					fill: #fff;
				}
			}
			h4, p{
				color: #fff;
			}
			p {
				max-height: 200px;
				//opacity: 1;
			}
			.wrapper{
				top: 10%;
				@include breakpoint($bp-bobby){
					top: 20%;
				}
			}
		}
	}
	.close-container {
		svg{
			cursor: pointer;
			fill: $hrColor;
			@include hover-fade();

			&:hover{
				fill: $primary;
			}
		}
	}
	.close {
		width: 25px;
		height: 25px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		z-index: 99;
		@include breakpoint($bp-charlie) {
			width: 50px;
			height: 50px;
			top: 50px;
			right: 50px;
		}
	}
}

/* All menus form with input and description fade in */
.js .align-middle {
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: opacity 0.5s, transform 0.5s;
}

.js .temp-high-z{
	z-index: 999991 !important;
}

.js .states--open .align-middle, 
.js .search--open .align-middle, 
.js .menu--open .align-middle,
.js .take-action-menu--open .align-middle {
	opacity: 1;
	transform: scale3d(1, 1, 1);
}

@media screen and (max-width:40em) {
	.align-middle {
		margin: 1em 0 1em;
	}
	.btn--menu-close {
		font-size: 1.25em;
		top: 0;
		right: 0;
		svg {
			width: 25px;
		}
	}
	.menu__info {
		text-align: left;
	}
}
// colors
$primary		: #3099FF;
$secondary		: #FCCB36;
$secondary-light: lighten($secondary,30%);
$tertiary		: #F83180;
$quatenary      : #F85A29;
$body			: #121416;
$details		: #bbbbbb;
$gray           : #f1f1f1;
$bg-dark     	: #0066FF;
$bg-light		: #eeeeee;
$footer		    : #fff;
$highlight-color: rgba($secondary, 0.5);

// Header
$header-height		 :  105px;
$header-tablet-height:	70px;
$header-mobile-height:	54px;

// Navigation
$navBackground	    : #fff;
$navColor		    : $body;
$navColorHover	    : $primary;
$dropDownBg		    : $primary;
$dropDownColor	    : #fff;
$dropDownHover	    : $body;
$socialColor	    : #999999;
$socialHover	    : $secondary;

// Default Box shadow. Set to zero if you want to remove shadow from buttons and boxes
$boxShadowTop	  : 4px;
$boxShadowLeft	: 4px;
$boxShadowBlur	: 4px;
$boxShadowColor	: rgba(0,0,0,0.08);

// Default Border radius
$borderRadius: 0px;


// Headlines
$headlineColor  : #121416;
$hrColor		    : #bbbbbb;

// Social Media
$facebook		: #344b81;
$twitter		: #00aced;
$email			: #cccccc;

// breakpoints
$bp-alpha		: 320px;
$bp-adams		: 375px;
$bp-bravo		: 480px;
$bp-bravo-wide	: 590px;
$bp-charlie		: 768px;
$bp-delta		: 960px;
$bp-bobby		: 992px;
$bp-1100		: 1100px;
$bp-echo		: 1250px;

// fonts
@font-face {
    font-family: 'Galano Grotesque Alt';
    src: url('../fonts/GalanoGrotesqueAlt-Bold.eot');
    src: url('../fonts/GalanoGrotesqueAlt-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GalanoGrotesqueAlt-Bold.woff2') format('woff2'),
        url('../fonts/GalanoGrotesqueAlt-Bold.woff') format('woff'),
        url('../fonts/GalanoGrotesqueAlt-Bold.svg#GalanoGrotesqueAlt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}


$primary-font	  : "Roboto Slab", sans-serif;
$headline-font	: 'Galano Grotesque Alt';

// Fonts available: 
// font-family: "sofia-pro";
// medium = 500
// semibold = 600
// bold = 700

// font-family: "freight-text-pro";
// book = 400
// medium = 500
// semibold = 600
// bold = 700


$mediaBreakPoints: (
  small-min: 768px,
  extra-small: 767px,
  medium-min: 992px,
  small-max: 991px,
  large-min: 1200px,
  medium-max: 1199px
);
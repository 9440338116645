.social {
	&.links {
		float: left;
		font-size: 20px;
		margin-top: 8px;
		span {
			float: left;
			display: block;
			text-align: center;
			font-size: 12px;
			text-transform: uppercase;
			margin: 13px 15px 0 15px;
		}
		ul {
			margin: 0px;
			padding: 0px;
			float: right;
			li {
				list-style-type: none;
				float: left;
				a {
					padding: 6px 15px;
					float: left;
					color: $body;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
}


$social-side-width: 56px;
$social-side-transition-time: .35s;

.social-nav {
    position: fixed;
    bottom: 0;
    z-index: 99;
    width: 100%;
    opacity: 1;
    @include transform(translateY(-50%) translateX(0px));
    transition: $social-side-transition-time;
    display: none;
    &.animate {
        opacity: 0;
        @include transform(translateY(-50%) translateX(50px));
    }
    &.behind {
        z-index: -99;
    }
    @include breakpoint($bp-bobby) {
        display: block;
        width: $social-side-width;
        position: fixed;
        right: 0!important;
        top: 50%!important;
        left: auto!important;
        bottom: auto;
        @include transform(translateY(-50%));
    }
    .social-side {
        display: table;
        width: $social-side-width;
        display: none;
        @include breakpoint($bp-charlie) {
            display: block;
        }
        p {
            font-family: $headline-font;
            font-size: 11px;
            letter-spacing: 0.04em;
            color: $socialColor;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 5px;
            width: 100%;
        }
        .box {
            background: transparent;
            border: 1px solid $socialColor;
            border-right: none;
            text-align: center;
            padding: 14px 0;
            cursor: pointer;
            color: $socialColor;
            margin-bottom: 10px;
            z-index: 6;
            position: relative;
            transition: $social-side-transition-time;
            height: 55px;
            i {
                font-size: 22px;
                font-weight: 400;
                color: $socialColor;
                @include hover-fade();
            }
        }
        .share-slide {
            position: absolute;
            top: 0px;
            display: inline-flex;
            //width: 210px;
            //height: 25px;
            background: #fff;
            left:100%;
            opacity: 0;
            height: 55px;
            padding-left: 9px;
            padding-right: 9px;
            border: 1px solid #cfcfcf;
            border-right: none;
            transition: $social-side-transition-time;
            width: auto;
            z-index: 5;
            zoom: 1;
            .social.links {
                float:none;
                margin-top: 0;
                display: inline-flex;
                align-items: center;
                zoom: 1;
                min-height: 0;
                min-width: 1%;
                ul {
                    display: inline-flex;
                    flex: 1 0 auto;
                    align-items: center;
                    float:none;
                    margin-right: $social-side-width;
                    zoom: 1;
                    min-height: 0;
                    min-width: 1%;
                    li {
                        display: inline-flex;
                        float:none;
                        flex: 1 0 auto;
                        align-items: center;
                        min-width: 42px;
                        a {
                            float:none;
                            color: $socialColor;
                            min-height: 0;
                            min-width: 1%;
                            &:hover {
                                color: $tertiary;
                            }
                        }
                    }
                }
            }
        }
        &:hover {
            .box {
                border: 1px solid $socialHover;
                background: $socialHover;
                i {
                    color: #fff;
                }
            }
            .share-slide {
                transform: translate(-100%, 0);
                opacity: 1;
            }
            p {
                color: $tertiary;
            }
        }
    }
}

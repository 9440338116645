.container {
    margin: 0 auto;
    padding: 0 30px;
    max-width: 100%;
    @include breakpoint($bp-charlie) {
        padding: 0 40px;
        width: 1145px;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

main {
    float: left;
    width: 100%;
}

hr {
    width: 140px;
    height: 3px;
    background: $hrColor;
    border-radius: 0px;
    margin: 25px auto 25px auto;
    @include breakpoint($bp-charlie) {
        margin: 30px auto 30px auto;
    }
    @include breakpoint($bp-bobby) {
        margin: 40px auto 40px auto;
    }
    &.divider {
         margin: 35px auto 35px auto;
        @include breakpoint($bp-charlie) {
            margin: 55px auto 55px auto;
        }
        @include breakpoint($bp-bobby) {
            margin: 60px auto 60px auto;
        }
    }
    &.long {
        width: 100%;
        height: 2px;
        max-width: 100%;
        margin: 50px auto 0 auto;
        background: #eaeaea!important;
        @include breakpoint($bp-charlie) {
            margin: 0;
        }
    }

    &.extra_space{
        margin-bottom: 50px;
    }
    &.hidden-hr{
        background: transparent;
        margin-bottom: -17.5px;
        @include breakpoint($bp-charlie) {
            margin-bottom: -27px;
        }
        @include breakpoint($bp-bobby) {
            margin-bottom: -30px;
        }
    }
}

.issues-page {
    hr {
        &.divider {
            margin: 40px auto 0px auto;
            @include breakpoint($bp-charlie) {
                margin: 55px auto 0px auto;
            }
            @include breakpoint($bp-bobby) {
                margin: 75px auto 0px auto;
            } 
        }
    }
}

.content {
    float: left;
    width: 100%;
    .col-md-8 {
        margin: 0 auto;
        display: table;
        table-layout: fixed;
        float: none;
    }
    .bottom-share {
        padding: 25px 0;
        width: 100%;
        float: left;
        text-align: center;
        display: block;
        @include breakpoint($bp-bobby) {
            display: none;
        }
        .social {
            display: table;
            float: none;
            margin: 0 auto;
            span {
                display: none;
            }
            ul {
                li {
                    a {
                        color: #666666;
                        &:hover {
                            color: $secondary;
                        }
                        i {
                            font-size: 23px;
                            @include breakpoint($bp-charlie) {
                                font-size: 29px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.upnext {
    margin-top: 0!important;
    height: auto;
    position: relative;
    padding: 65px 0 65px;
    @include hover-fade();
    margin: 0 auto;
    margin-bottom: 60px;
    width: 100%;
    display: table;
    z-index: 25;
    max-width: 1170px;
    background:$tertiary;
    border-radius: 0px;
    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.15);
    &:hover {
        background-color: $secondary;

        h6, h2{
            color: $body;
        }

        .arrow svg{
            fill: $body;
        }
    }
    h2 {
        text-align: center;
        color: #fff;
        margin: 0 auto;
        max-width: 650px;
        @include hover-fade();
        font-size: $h3;
        line-height: 130%;
        letter-spacing: -0.01em;
    }
    h6 {
        text-align: center;
        color: #fff;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.23em;
        font-size: 16px;
        @include hover-fade();
    }
    @include breakpoint($bp-charlie) { 
        padding: 50px 0;
        height: 310px;
        padding: 0;
    }
    @include breakpoint($bp-bobby) { 
        padding: 150px 0;
        padding: 0;
    }
    @include breakpoint(1175px) {

    } 
    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .container {
        height: 100%;
        max-width: 100%;
        padding: 0px 40px;
        @include breakpoint($bp-charlie) { 
            max-width: 90%;
            padding: 0px 60px;
            position: absolute;
            height: 310px;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        @include breakpoint($bp-echo) { 
            max-width: 1190px;
        }
    }
    .arrow {
        width: 70px;
        height: 70px;
        margin: 30px auto 0px;
        svg {
            fill: #fff;
            @include hover-fade();

            .cls-1{
                fill: #fff;
            }
        }
        @include breakpoint($bp-charlie) {
            @include transform(translateY(-50%));
            position: absolute;
            top: 50%;
            height: 70px;
            width: 70px;
            margin: 0;
            left: auto;
            right:0px;
            bottom: auto;
            @include transform(translateY(-50%));
        }
    }

    &.campaign{
        .container{
            width: 100%;
            padding: 0;
            position: relative;
            transform: none;

            .arrow{
                top: 60px;
                right: 50px;
                transform: none;
                height: 50px;
                width: 50px;
            }

            .up-next-image-block, .up-next-content-block{
                float: left;
            }

            .up-next-image-block{
                background-size: cover;
                background-repeat: no-repeat; 
                height: 100%;
                width: 40%;

                .category{
                    background: $quatenary;
                    color: #fff;
                    font-family: $headline-font;
                    font-weight: bold;
                    font-size: 9px;
                    letter-spacing: 2px;
                    padding: 0 15px;
                    display: inline-block;
                    position: absolute;
                    text-transform: uppercase;
                    left: 0;
                    margin: 0;
                    top: 0;
                    @include transform(rotate(-90deg) translateX(-100%));
                    transform-origin: top left;
                    height: 30px;
                    line-height: 30px;
                }
            }

            .up-next-content-block{
                width: 60%;
                padding-left: 75px;
                padding-right: 75px;
                position: absolute;
                right: 0;
                bottom: 70px;

                h2, h6{
                    text-align: left;
                }

                h6{
                    font-size: 16px;
                    letter-spacing: 3.2px;
                    margin-bottom: 40px;
                }

                h2{
                    font-size: 34px;
                    letter-spacing: -0.3px;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.vertical-centered {
    float: left;
    width: 100%;
    text-align: center;
    @include breakpoint($bp-charlie) {
        @include vertical-align();
    }
}

.video-cover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: cover !important;
    z-index: 2;
    cursor: pointer;
}
.videoWrapper {
	&:not(.featured-video) {
		position: relative;
		padding-bottom: 35.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

    .play-button {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        cursor: pointer;
        .play-bg {
            background: #fff;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin: -14px 0 0 -14px;
            position: absolute;
            @include hover-fade();
            @include breakpoint($bp-charlie) {
                width: 44px;
                height: 44px;
                margin: -22px 0 0 -22px;
            }
        }
        svg {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: .3s;
            color: $tertiary;
            @include breakpoint($bp-charlie) {
                width: 90px;
                height: 90px;
            }
        }
        &:hover {
            svg {
                @include transform(translateY(-50%) scale(1.12));
            }
        }
    }
}

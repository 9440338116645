.home-about-3rs {
	float: left;
	width: 100%;
	background: #fff;
	position: relative;
	padding: 10px 0 20px;
	@include breakpoint($bp-charlie) {
		padding: 65px 0px;
	}
	@include breakpoint($bp-bobby) {
		padding: 125px 0;
	}
	.col-md-11 {
		float: none;
		display: table;
		margin: 0 auto;
	}
	
	h2 {
		
		margin-top: 0;
		max-width: 460px;
		font-size: 35px;

		span{
			&:before, &:after{
				height: 6px;
				@include breakpoint($bp-bravo){
					height: 10px;
				}
			}
		}

		@include breakpoint($bp-bravo){
			font-size: 40px;
		}
		@include breakpoint($bp-charlie){
			margin-bottom: 45px;
			font-size: 42px;
		}
		@include breakpoint($bp-bobby){
			font-size: 58px;
		}
	}
	
	.description {
		float: right;
		margin-bottom: 20px;
		margin-top: 20px;
		max-width: 100%;

		@include breakpoint($bp-charlie) {
			margin-top: 0;
		}
		@include breakpoint($bp-bobby) {
			margin-bottom: 40px;
			max-width: 530px;
		}

		@include breakpoint($bp-echo) {
			max-width: 610px;
		}
		p {
			font-size: 16px;

			@include breakpoint($bp-charlie) {
				font-size: 18px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 20px;
			}

			strong{
				background: transparent;
			}
		}
	}
}

.three-rs-facts{
	background: #f1f1f1;
	float: left;
	width: 100%;
	padding: 50px 0px;
	@include breakpoint($bp-charlie) {
		border-left: 17px solid #fff;
		padding: 70px 0px;
	}
	@include breakpoint($bp-bobby) {
		padding: 85px 0;
	}
	text-align: center;
	
	h1{
		margin-bottom: 30px;
		max-width: 500px;
		display: inline-block;

		span.word1, span.word2{
			&:before, &:after{
				background: $tertiary;
			}
		}

		span.word3{
			&:before, &:after{
				background: $secondary;
			}
		}
	}

	.facts-wrapper{
		float: left;
		width: 100%;
		margin-top: 30px;
		margin-bottom: 30px;

		@include breakpoint($bp-bobby) {
			margin-top: 95px;
		}
		
		.fact{
			margin-bottom: 20px;
			padding: 0;
			display: inline-flex;
			align-content: center;
			justify-content: flex-start;
			align-items: center;
			width: 100%;
			
			@include breakpoint($bp-charlie) {
				// margin-bottom: 30px;
				min-height: 40px;
				padding: 0 15px;

				&:nth-child(odd){
					clear:left;
				}
			}
			@include breakpoint($bp-bobby){
				width: 50%;
			}
			.icon {
				float: left;
				margin-right: 20px;
				margin-top: -6px;

				svg{
					height: 34px;
					width: 34px;
				}
			}
			
			p{
				font-family: $headline-font;
				font-weight: bold;
				text-align: left;
				line-height: 120%;
				font-size: 17px;
				margin-bottom: 0;

				a{
					color: $primary;

					&:hover{
						color: $tertiary;
					}
				}
			}
		}
	}

	.btn{
		display: inline-block;
		background: $primary;
		color: #fff;
		width: 100%;

		&::before{
			content: '\f105';
			color: $body;
			font-family: 'FontAwesome';
			font-size: 20px;
			position: relative;
			left: -8px;
			line-height: 1em;
			@include hover-fade();
		}

		&:hover{
			background: $secondary;
			border-color: $secondary;
			color: $body;

			&:before{
				left: -5px;
			}
		}
	}
}

.before-you-teach{
	float: left;
	width: 100%;
	text-align: center;
	padding: 50px 0px;

	@include breakpoint($bp-charlie) {
		padding: 85px 0px;
	}
	@include breakpoint($bp-bobby) {
		padding: 130px 0;
	}

	.col-md-7{
		float: none;
		margin: 0 auto;
		display: block;
	}

	h1{
		margin-bottom: 30px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 50px;
		}
		span{
			&:before, &:after{
				background: $tertiary;
				height: 6px;
				@include breakpoint($bp-bravo){
					height: 10px;
				}
			}
		}
	}

	p{
		font-size: 17px;
		line-height: 170%;

		@include breakpoint($bp-charlie) {
			font-size: 20px;
			margin-bottom: 40px;
		}
	}

	.btn{
		display: inline-block;
		border-color: $tertiary;
		width: 100%;

		@include breakpoint($bp-charlie){
			min-width: 200px;
			max-width: 200px;
		}

		&::before{
			content: '\f105';
			color: $tertiary;
			font-family: 'FontAwesome';
			font-size: 20px;
			position: relative;
			left: -8px;
			line-height: 1em;
			@include hover-fade();
		}

		&:hover{
			background: $tertiary;
			
			&:before{
				color: #fff;
				left: -5px;
			}
		}
	}
}

.home.featured-activists.curriculum{
	
	.activist-wrapper{
		h1{
			color: #fff;
			display: inline-block;
			position: relative;
			min-width: auto;

			@media (max-width:424px){
				font-size: 45px;
				max-width: 260px;
			}

			@include breakpoint($bp-charlie){
				
			}
			
			@include breakpoint($bp-bobby){
				max-width: 370px;
				margin-left: calc(-12% + -80px);
			}
			
			@include breakpoint($bp-echo){
				
			}

			&:before{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 102%;
				height: 7px;
				position: absolute;
				bottom: -2px;
				left: -10px;
				padding: 0 1px;
				// z-index: 0;

				@include breakpoint($bp-charlie){
					height: 10px;
				}

				@include breakpoint($bp-bobby){
					z-index: -1;
				}
			}
			&:after{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 102%;
				height: 7px;
				position: absolute;
				bottom: -2px;
				left: 0;
				padding: 0 1px;
				// z-index: -1;

				@include breakpoint($bp-charlie){
					height: 10px;
				}

				@include breakpoint($bp-bobby){
					z-index: -1;
				}
			}
		}
		p{
			@include breakpoint($bp-charlie) {
				margin-bottom: 40px;
			}
		}
		.btn{
			background: $secondary;
			border-color: $secondary;
			display: inline-block;
			width: 100%;

			@include breakpoint($bp-charlie){
				min-width: 200px;
				max-width: 200px;
			}

			&::before{
				content: '\f105';
				color: #fff;
				font-family: 'FontAwesome';
				font-size: 20px;
				position: relative;
				left: -8px;
				line-height: 1em;
				@include hover-fade();
			}

			&:hover{
				background: $primary;
				border-color: $primary;

				&:before{
					left: -5px;
				}
			}
		}
	}
}


.the-science-behind-3rs{
	float: left;
	width: 100%;
	text-align: left;
	padding: 10px 0px 50px;

	@include breakpoint($bp-charlie) {
		padding: 85px 0px;
	}
	@include breakpoint($bp-bobby) {
		padding: 130px 0;
	}
	.col-md-5{
		@include breakpoint($bp-charlie){
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.row{
		@include breakpoint($bp-charlie) {
			display: flex;
		}
	}
	h3{
		span{
			&:before, &:after{
				background: $primary;
				height: 5px;
				@include breakpoint($bp-bravo){
					height: 8px;
				}
			}
		}
	}
	p{
		font-size: 16px;
		margin-bottom: 30px;
		line-height: 170%;
		
		@include breakpoint($bp-charlie) {
			margin-bottom: 0px;
		}

		@include breakpoint($bp-bobby){
			// line-height: 213%;
		}
	}

	.btn{
		&::before{
			content: '\f105';
			color: $primary;
			font-family: 'FontAwesome';
			font-size: 20px;
			position: relative;
			left: -8px;
			line-height: 1em;
			@include hover-fade();
		}
		&:hover{

			&:before{
				color: $body;
				left: -5px;
			}
		}
	}
}
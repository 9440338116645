.overlayModal{
    top: 0;
    position: fixed;
    z-index: 9999;
    background: rgba(0,0,0,.8);
    width: 100%;
    height: 100%;
    padding: 15px;
    opacity: 0;
    visibility: hidden;
    @include hover-fade();
    &.active {
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        .wrapper {
            transform: translateY(-50%);
            opacity: 1;
        }
    }
    &.removing {
        animation: fadeDown 0.35s linear forwards;
        animation-delay: 0s;
    }
    .wrapper {
        @include hover-fade();
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-40%);
        height: auto;
        max-width: 1000px;
        width: 95%;
        padding: 100px 30px 50px;
        background: #fff;
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.15);
        opacity: 0;
        overflow: hidden;
        @include breakpoint($bp-charlie){
            padding: 100px 50px 50px;
        }
        @include breakpoint($bp-bobby){
            padding: 150px 100px 100px;
        }
        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            text-align: center;
            cursor: pointer;
            @include hover-fade();
            @include transform(scale(0.75));
            @include breakpoint($bp-charlie) {
                top: 40px;
                right: 40px;
                @include transform(scale(1));
            }
            svg {
                .st0 {
                    fill: #bbbbbb;
                    @include hover-fade();
                }
            }
            &:hover {
                svg .st0 {
                    fill: $body;
                }
            }
        }
        .inner-wrapper {
            float: left;
            width: 100%;
            text-align: center;
            position: relative;
            .special-underline span{
                &:before,
                &:after{
                    background: $primary;
                }
            }
            p{
                margin: 0 0 30px;
                @include breakpoint($bp-bobby){
                    margin: 0 20% 30px;
                }
            }
            .btn{
                border-color: $tertiary;
                margin: 0 auto;
                display: inline-block;
                min-width: 200px;
                &:before{
                    content: '\f105';
                    color: $tertiary;
                    font-family: FontAwesome;
                    font-size: 19px;
                    position: relative;
                    left: -15px;
                    line-height: 1em;
                    transition: .45s;
                }
                &:hover,
                &:focus{
                    background: $tertiary;
                    &:before{
                        color: #fff;
                        left: -10px;
                    }
                }
            }
            .text {
                display: inline-block;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.04em;
                color: #fff;
                padding: 32px 25px 32px 0;
                position: relative;
                z-index: 3;
                @include hover-fade();
                span {
                    position: absolute;
                    top: 30px;
                    right: 3px;
                    @include hover-fade();
                    svg {
                        width: 14px;
                    }
                }
            }
            .back{
                margin-top: 50px;
                @include breakpoint($bp-bobby){
                    margin-top: 90px;
                }
                a{
                    font-family: $headline-font;
                    font-size: 17px;
                    color: $primary;
                    position: relative;
                    &:before{
                        content: '\f104';
                        color: $primary;
                        font-family: FontAwesome;
                        font-size: 19px;
                        position: absolute;
                        font-weight: bold;
                        top: 2px;
                        left: -15px;
                        line-height: 1em;
                        transition: .45s;
                        @include hover-fade();
                    }
                    &:after{
                        position: absolute;
                        top: 100%;
                        height: 2px;
                        background: $primary;
                        content: '';
                        left: 0;
                        right: 0;
                        @include hover-fade();
                    }
                    &:hover,
                    &:focus{
                        color: $tertiary;
                        &:before{
                            color: $tertiary;
                        }
                        &:after{
                            background: $tertiary;
                        }
                    }
                }
            }
        }
    }
}
.landing-page {
	position: relative;
	.landing-social {
		position: relative;
		float: left;
		width: 100%;
		padding: 25px 15px 10px 15px;
		@include breakpoint($bp-charlie) {
			float: none;
			width: auto;
			position: absolute;
			top: 30px;
			right: 30px;
			padding: 0;
			z-index: 100;
		}
		.social {
			float: none;
			display: table;
			margin: 0 auto;
			@include breakpoint($bp-charlie) {
				float: right;
				display: block;
				margin: 0;
			}
			span {
				float: left;
				width: auto;
				font-family: $primary-font;
				color: #999999;
				@include breakpoint($bp-charlie) {
					color: #fff;
				}
				font-size: 12px;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				font-weight: 600;
				padding-top: 11px;
			}
			ul {
				float: left;
				margin-left: 10px;
				li {
					margin: 0 0 0 10px;

					&:last-of-type {
						a {
							padding-left: 2px;
							padding-top: 9px;
						}
					}
					a {
						float: left;
						width: 40px;
						height: 40px;
						background: #eaeaea;
						@include breakpoint($bp-charlie) {
							background: #fff;
						}
						border-radius: 40px;
						text-align: center;
						padding-top: 10px;
						i {
							font-size: 21px;
							color: #999999;
						}
						&:hover {
							@include transform(scale(1.1));
							background: $primary;
							i {
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
	.dotted-nav {
		position: fixed;
		bottom: 0;
		z-index: 99;
		width: 100%;
		@include breakpoint($bp-charlie) {
			width: 56px;
			position: fixed;
			right: 0!important;
			top: 50%!important;
			left: auto!important;
			bottom: auto;
			@include transform(translateY(-50%));
		}
		&.white {
			.social-side p {
				color: #fff;
			}
		}
		.social-side {
			display: table;
			width: 56px;
			display: none;
			@include breakpoint($bp-charlie) {
				display: block;
			}
			&:hover {
				.box {
					border: 1px solid $secondary;
					background: $secondary;
					i {
						color: #fff;
					}
				}
				.share-slide {
					left: -160px;
					opacity: 1;
				}
			}
			p {
				display: block;
				font-size: 11px;
				letter-spacing: 0.04em;
				color: #535454;
				font-weight: 700;
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 5px;
				width: 100%;
				font-family: $headline-font;
				position: fixed;
				right: auto!important;
				top: 0!important;
				left: auto!important;
				bottom: auto;
				@include transition(all 0.2s ease);
				.dark {
					color: #fff;
				}
			}
			.share-slide {
				position: absolute;
			    top: 23px;
			    width: 160px;
			    height: 25px;
			    background: #fff;
			    left: 0;
			    opacity: 0;
			    height: 56px;
			    padding-left: 9px;
			    border: 1px solid #cfcfcf;
			    border-right: none;
			    @include hover-fade();
			    z-index: 5;
			    .social.links {
			    	margin-top: 6px;
			    }
			    span {
			    	display: none;
			    }
			}
			.box {
				background: #fff;
				border: 1px solid #cfcfcf;
				border-right: none;
				text-align: center;
				padding: 12px 0;
				cursor: pointer;
				color: #989e9e;
				margin-bottom: 10px;
				z-index: 6;
				position: relative;
				@include hover-fade();
				margin-top: 23px;
				i {
					font-size: 22px;
					font-weight: 400;
					color: #989e9e;
					@include hover-fade();
				}
				&:hover {
					border: 1px solid $secondary;
					background: $secondary;
					i {
						color: #fff;
					}
				}
			}
		}
		.dotted-style {
			list-style: none;
			display: table;
			margin: 0 auto;
			padding: 15px 10px;
			background: rgba(#252626, 1);
			@include breakpoint($bp-charlie) {
				width: 14px;
				background: rgba(#252626, 1);
				float: left;
				padding: 18px 11px;
				padding: 18px 11px;
			}
			li {
				width: 100%;
				width: 14px;
				float: left;
				margin-bottom: 0;
				margin: 0 10px;
				position: relative;
				@include breakpoint($bp-charlie) {
					margin-bottom: 14px;
				}
				.tool {
					position: absolute;
					background: $secondary;
					top: 50%;
					@include transform(translateY(-50%));
					right: 38px;
					width: 145px;
					text-align: center;
					padding: 4px 8px;
					line-height: 110%;
					@include transition(all 0.2s ease);
					opacity: 0;
					display: none;
					@include breakpoint($bp-charlie) {
						display: block;
					}
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						right: -7px;
						width: 0; 
						height: 0; 
						border-bottom: 7px solid transparent;
						border-top: 7px solid transparent; 
						border-left: 7px solid $secondary;
						font-size: 0;
						line-height: 0;
					}
					span {
						font-size: 16px;
						font-family: $headline-font;
						font-weight: 600;
						color: #fff;
					}
				}
				&:last-of-type {
					margin-bottom: 0;
				}
				a {
					width: 14px;
					height: 14px;
					border-radius: 100%;
					background: #cfcfcf;
					float: left;
					&:hover {
						background: $secondary;
					}
					&.active {
						background: $primary;
					}
				}
			}
		}
	}
}

.landing-topper {
	height: 100%;
	padding-top: 65px;
	padding-bottom: 45px;
	position: relative;
	margin: 0!important;
	background: $primary;
	overflow: hidden;
	@include breakpoint(1090px) {
		min-height: 650px;
		max-height: 1200px;
		padding-bottom: 0!important;
		padding-top: 0!important;
		height: 100vh;
	}
	&.has-image {
		background: #000;
	}
	.scroll-down {
			position: absolute;
			bottom: 25px;
			right: 0;
			left: 0;
			margin: 0 auto;
			width: 45px;
			text-align: center;
			cursor: pointer;
			display: none;
			@include breakpoint($bp-bobby) {
				display: block;
			}
			svg {
				max-width: 43px;
				height: 26px;
				margin-bottom: 8px;
				@include hover-fade();
				.st0{fill:#fff;stroke:#fff;stroke-miterlimit:10;
				@include hover-fade();}
			}
			span {
				float: left;
				width: 100%;
				color: #fff;
				font-weight: 700;
				font-size: 10px;
				text-transform: uppercase;
				text-align: center;
				@include hover-fade();
			}
			&:hover {
				svg {
					margin-bottom: 6px;
				}
				svg .st0 {
					fill: $secondary;
					stroke: $secondary;
				}
				span {
					color: $secondary;
				}
			}
	}
	.image-overlay {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		width: 100%;
		height: 100%;
		background-size: cover!important;
		background-position: 50% 50%;
		opacity: 0.4;
		@include transform(scale(1.05));

		@include breakpoint($bp-charlie) {
			display: block;
		}
	}
	.topper__mobile-image {
		opacity: 0.7;
	}
	.shadow {
		opacity: 0.5;
	}
	.col-xs-12 {
		padding: 15px;
		@include breakpoint(1090px) {
			padding: 30px;
		}
	}
	.bottom-float {
		position: relative;
		width: 100%;
		max-width: 750px;
		margin: 0 auto;
		z-index: 11;
		opacity: 1;
		@include breakpoint(1090px) {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			left: 0;
			right: 0;
		}
		h1 {
			text-align: center;
			color: #fff;
			margin: 0 0 25px 0;
		}
		p {
			color: #fff;
			margin: 0 auto;
			text-align: center;
			max-width: 650px;
		}
		.logo {

			margin: 0 auto 35px;

			a, svg {
				color: $gray !important;
			}
			max-width: 160px;
			@include breakpoint(1090px) {
				max-width: 160px;
				margin: 0 auto 15px;
			}
			svg {
				height: 113px;
				max-height: 113px;
				max-width: 160px;
				.st0, .st1, .st2, .st3, .st4 {
					fill: #fff;
				}
			}
		}
	}
}


.secondary-footer {
	float: left;
	width: 100%;
	background: $body;
	position: relative;
	padding: 32px 15px 30px 15px;
	.container {
		max-width: 980px;
	}
	.col-sm-8, .col-sm-4 {
		min-width: 100%;
		@include breakpoint($bp-charlie) {
			min-width: auto;
		}
	}
	p {
		margin: 10px 0 0 0;
	    padding: 0;
	    font-weight: 400;
	    font-size: 13px;
	    letter-spacing: .02em;
	    color: #999f9f;
	    font-family: $headline-font;
	    text-align: left;
		@include breakpoint($bp-charlie) {
			text-align: left;
		}
		span {
			float: left;
			width: 100%;
			margin-bottom: 10px;
			@include breakpoint($bp-charlie) {
				float: none;
				width: auto;
				margin-bottom: 0;
			}
		}
		.privacy {
			position: relative;
			@include breakpoint($bp-charlie) {
				padding-left: 15px;
				margin-left: 15px;
			}
			a {
				color: #999f9f;
				&:hover {
					color: $secondary;
				}
			}
			&:before {
				@include breakpoint($bp-charlie) {
					content: '';
					left: 0;
					top: 5px;
					height: 11px;
					width: 1px;
					position: absolute;
					background: #999f9f;
				}
			}
		}
	}
	.social {
		width: 100%;
		text-align: center;
		margin: 0;
		ul {
			display: table;
			margin: 15px auto 5px auto;
			@include breakpoint($bp-charlie) {
				float: right;
				margin: 0;
			}
			li {
				margin: 0 0 0 5px;
				a {
					font-size: 18px;
					padding: 6px 10px;
					color: #999f9f;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
	.site-by {
		p {
			width: 100%;
			text-align: center;
			font-size: 12px;
			padding-top: 14px;
		    font-weight: 400;
    		letter-spacing: .01em;
    		color: #999f9f;
    		font-family: $headline-font;
    		text-transform: none;
			@include breakpoint($bp-delta) {
				text-align: left;
			}
		}
	}
	a {
		color: #999f9f;
		&:hover {
			color: $secondary;
		}
	}
}

::-moz-selection { background: $secondary; color:$body; }
::selection { background: $secondary; color:$body; }

body, html {
	background-color: $body!important;
  height: 100%;
    color: $body;
    //@include transition(all 0.3s ease-in-out);
	-webkit-font-smoothing: antialiased;//this is really performance intensive....
    main {
        background: #fff;
    }
    &.search-open {
       // margin-top: 61px;
        header {
        }
        .topper, .topper-home {
            //top: 61px;
        }
        .top-search {
            top: 0;
            input[type="text"] {
                opacity: 1;
            }
        }
    }
}

a {
    color: $tertiary;
	@include hover-fade();
	&:hover {
        color: $primary;
	}
}

strong{
    background: $secondary-light;
}
.unstyled-link{
    color: $body !important;
}

.overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #f83180;
    // don't use rgba so we use animations on it
    opacity: 0.03;
    &.gradient {
        background: linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -moz-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        background: -ms-linear-gradient(to bottom,rgba(0,0,0,1) 0%,rgba(0,0,0,.1) 30%,rgba(0,0,0,0) 100%);
        opacity: 0.4 !important;

    }

    .no-js & {
        opacity: 0.4;
    }
}
.overlay-mobile {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #000000;
    opacity: .4;
    @include breakpoint($bp-charlie){
        display: none;
    }
}
.bottom-gradient{
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
   /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,121416+100&0+52,1+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(9,10,11,0) 40%, rgba(18,20,22,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(9,10,11,0) 40%,rgba(18,20,22,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(9,10,11,0) 40%,rgba(18,20,22,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#121416',GradientType=0 ); /* IE6-9 */
    opacity: 1 !important;

    @include breakpoint($bp-charlie){
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,121416+100&0+52,1+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(9,10,11,0) 52%, rgba(18,20,22,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(9,10,11,0) 52%,rgba(18,20,22,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(9,10,11,0) 52%,rgba(18,20,22,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#121416',GradientType=0 ); /* IE6-9 */
        opacity: 0.4 !important;
    }
}



.pagination {
    list-style: none;
    margin: 0 auto;
    text-align: center;
    float: left;
    width: 100%;
    position: relative;

    ul {
        margin: 0 auto!important;
        max-width: 600px;
        padding: 45px 15px;
        position: relative;
        @include breakpoint($bp-charlie) {
            padding: 80px 15px;
        }
    }

    li {
        margin: 0px !important;
        display: inline-block;
        float: none;
        text-align: center;
        font-family: $headline-font;
        font-size: 17px;
        font-weight: 600;
        color: #999999;
        padding: 0;
        &:last-of-type {
            &:after {
                display: none!important;
            }
        }
    }
    a, span {
        line-height: 1em;
        margin: 0px;
        padding: 5px 10px 0;
        display: inline-block;
        font-size: 18px;
        text-decoration: none;
        position: relative;
        color: #bbb;
        letter-spacing: 1.3px;
        svg {
            width: 20px;
            height: 20px;
            fill: #3b3d4d;
            @include transition(all 0.2s ease-in-out);
            &:hover {
                fill: $secondary;
            }
        }
        @include breakpoint($bp-charlie, max){
            &.page-numbers{
                display: none;
                &.next,
                &.prev{
                    display: inline-block;
                }
            }
        }
    }
    .prev svg {
        @include transform(rotate(-180deg));
    }
    span.current {
        color: $tertiary;
        border-bottom: 2px solid;
    }
    a {
        &:hover {
            color: $primary;
        }
    }

    .next, .prev{
        display: block;
        position: absolute;
        top: 45px;
        @include hover-fade();

        @include breakpoint($bp-charlie) {
            top: 80px;
        }
        
        span{
            text-transform: uppercase;
            font-family: $headline-font;
            font-size: 13px;
            color: $body;
        }

        svg{
            fill: $tertiary;
            position: relative;
            @include hover-fade();
        }

    }
    
    .next{
        right: 0;

        svg{
            right: 0;
        }
        
        &:hover{
            svg{
                fill: $secondary;
                // right: -3px;    
            }
        }
    }

    .prev{
        left: 0;

        svg{
            left: 0;
        }
        
        &:hover{
            svg{
                fill: $secondary;
                // left: -3px;    
            }
        }
    }
}


svg title {
  pointer-events: none;
}

.hiddennone{
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.module {
	float: left;
    width: 100%;
    position: relative;
	margin-top: 40px;
	@include breakpoint($bp-charlie) {
		margin-top: 60px;
	}
	@include breakpoint($bp-bobby) {
		margin-top: 80px;
	}
	&.last-module {
		margin-bottom: 40px;
		.issues-page hr.divider {
			display: none;
		}
		@include breakpoint($bp-charlie) {
			margin-bottom: 70px;
		}
		@include breakpoint($bp-bobby) {
			margin-bottom: 90px;
		}
	}
	.container, .row {
		// height: 100%;
	}
    img.size-full {
    	width: 100%;
        max-width: 100%;
        height: auto;
    }
    .wp-caption {
    	width: 100%!important;
    	margin: 25px 0 25px;
    	img {
    		height: auto!important;
    		width: 100%!important;
    		@include breakpoint($bp-bravo) {
    			height: auto!important;
    			width: auto!important;
    		}
    	}
    	@include breakpoint($bp-bravo) {
    		width: auto!important;
    		margin: 25px 0 25px;
    	}
    }
    .wp-caption-text {
    	margin: 10px 0px;
        font-size: 14px;
        line-height: 125%;
        text-align: center;
        font-family: $primary-font;
        font-weight: 400;
        color: #666666;
		width: 100%!important;
		font-style: italic;
        @include breakpoint($bp-bravo) {
			font-size: 16px;
			width: auto;
        }
    }
    .alignleft {
        float: none;
        margin: 25px 0 25px;
        max-width: 100%;
        width: 100%;
        height: auto;
        @include breakpoint($bp-bravo) {
            float: left;
            width: auto;
            max-width: auto;
            position: relative;
            margin: 10px 35px 10px 0;
        }
    }
    .aligncenter {
        float: none;
        display: table;
        margin: 0 auto;
        width: 100%;
        height: auto;
        margin: 25px 0 25px;
        @include breakpoint($bp-bravo) {
            float: none;
            width: auto;
            position: relative;
            margin: 10px auto;
        }
    }
    .alignright {
        float: none;
        margin: 25px 0 25px;
        max-width: 100%;
        width: 100%;
        height: auto;
        @include breakpoint($bp-bravo) {
            float: right;
            max-width: auto;
            width: auto;
            position: relative;
            margin: 10px 0 10px 35px;
        }
    }
	&.action-menu {
		margin: 0px;
		position: fixed;
		bottom: 0px;
		z-index: 10;
		@include breakpoint($bp-charlie) {
			position: relative;
		}
		nav {
			ul {
				@for $i from 1 through 8 {
		  			li:first-child:nth-last-child(#{$i}),
		  			li:first-child:nth-last-child(#{$i}) ~ li {
		    			width: 100% / $i 
		    		} 
		    	} 
			    li {
			    	text-align: center;
			    	border-right: 1px solid $primary;
			    	float: left;
					.sub-menu {
						display: none;
					}
			    	&:last-child {
			    		margin: 0px;
			    		border: 0px;
			    	}
			    	a {
			    		display: block;
			    		float: left;
			    		width: 100%;
			    		background: $secondary;
						color: #fff;
			    		text-transform: uppercase;
			    		padding: 20px 0;
			    		@include breakpoint($bp-charlie) {
			    			padding: 60px 0;
			    		}
			    	}
			    }
			}
		}
	}
	&.full-cta {
		float: left;
		width: 100%;
		background-color: $primary;
		color: #fff;
		background-size: cover !important;
		@include breakpoint($bp-charlie) {
			height: 650px; 
		}
		.btn {
			float: left;
			width: 100%;
			@include breakpoint($bp-charlie) {
				margin: 0 auto;
				display: table;
				float: none;
				width: auto;
			}
		}
		p {
			color: #fff;
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}
	&.donation {
		float: left;
		width: 100%;
		background-color: $body;
		color: #fff;
		background-size: cover !important;
		margin-bottom: 25px;
		padding: 85px 0;
		@include breakpoint($bp-charlie) {
			padding: 190px 0;
		}
		.all-columns{
			margin: 0 auto;
			max-width: 750px;
		}
		.btn {
			float: left;
			width: 100%;
			@include breakpoint($bp-charlie) {
				margin: 0 auto;
				display: table;
				float: none;
				width: auto;
			}
		}
		p {
			color: #fff;
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
		.inner-form {
			max-width: 450px;
			width: 100%;
			display: table;
			margin: 0 auto;

			@include breakpoint($bp-echo){
				// max-width: 300px;
			}
			input {
				width: 100%;
				margin-bottom: 5px;
			}
			input[name="zip"] {
				margin-bottom: 13px;
			}
			.btn {
				width: 100%!important;
				max-width: 450px;
			}

			.action-form #can_embed_form {
				#form_col1, #form_col2{
					width: 100% !important;
				}

				.country_drop_wrap, .international_link-wrap, label{
					display: none !important;
				}

				.floatlabel-input{
					margin-bottom: 6px !important;
				}

				.core_field, form li{
					margin: 0 !important;
				}

				#form-first_name{
					float: left !important;
					width: 100% !important;
				}

				#form-email{
					float: left !important;
					width: 100% !important;
				}

				#form-zip_code{
					float: left !important;
					margin-bottom: 13px !important;
					width: 100% !important;
				}

				#form_col2{
					width: 100% !important;
					height: 50px !important;
					display: flex;
					align-items: center;
					
					// @include breakpoint($bp-charlie){
					// 	position: absolute !important;
					// 	width: 50% !important;
					// 	bottom: 13px !important;
					// 	right: 0 !important;
					// }

					&::before{
						content: '\f105';
						color: $body;
						font-family: 'FontAwesome';
						font-size: 20px;
						position: relative;
						@include hover-fade();
						z-index: 2;
						font-weight: bold;
						line-height: 1em;
						margin-top:-2px;
						left: 19%;

						@include breakpoint(376px){
							left: 28%;
						}
						@include breakpoint($bp-charlie) {
							left: 29%;
						}
					}

					&:hover{
						&::before{
							color: #fff;
							left: 21%;
							@include breakpoint(376px){
								left: 30%;
							}
							@include breakpoint($bp-charlie) {
								left: 31%;
							}
							
						}
					}
				}

				input[type='submit']{
					float: left !important;
					margin: 0 !important;
					border-radius: 0 !important;
					max-width: 100% !important;
					min-width: auto !important;
					height: 50px !important;
					padding: 10px 15px !important;
					width: 100% !important;
					position: absolute !important;
					top: 0 !important;
					font-size: 17px !important;
					letter-spacing: normal !important;
					text-transform: none !important;

					

					&:hover {
						background: $secondary;
						color: $body !important;				
					}
				}

			}
		}
		&.with-image{
			padding: 0;

			h1, h2, h3,h4,h5,h6, p{
				text-align: left;
			}

			.donation-image, .module__mobile-image{
				background-size: cover !important;
				background-position: center center;
				float: left;
				margin: 16px;
				min-height: 350px;
				height: 100%;
				width: calc(100% - 32px);
				position: relative;

				@include breakpoint($bp-charlie){
					min-height: 450px;
				}

				@include breakpoint($bp-bobby){
					margin: 16px 0 -30px 16px;
					min-height: 770px;
					width: calc(55% - 16px);
				}
			}
			.module__mobile-image{
				position: relative;
			}
			.container{
				float: left;
				width: 100%;
				padding: 40px 30px 30px;

				@include breakpoint($bp-charlie){

				}

				@include breakpoint($bp-bobby){
					padding: 0;
					height: 740px;
					width: 45%;
				}
				
				.vertical-centered{
					top: auto;
					transform: none;

					@include breakpoint($bp-bobby){
						top: 50%;
						@include transform(translateY(-50%));
					}

				}

				h2{
					font-size: 40px;

					@include breakpoint($bp-charlie){
						font-size: 45px
					}

					@include breakpoint($bp-bobby){
						font-size: 58px;
					}

					span:after, span:before{
						height: 8px;

						@include breakpoint($bp-charlie){
							height: 10px;
						}
					}
				}
			}

			.inner-form{
				max-width: 100%;

				.btn{
					max-width: 100%;
				}

				.action-form #can_embed_form {
					#form_col1, #form_col2{
						width: 100% !important;
					}

					.country_drop_wrap, .international_link-wrap, label{
						display: none !important;
					}

					.floatlabel-input{
						margin-bottom: 6px !important;
					}

					.core_field, form li{
						margin: 0 !important;
					}

					#form-first_name{
						float: left !important;
						width: 100% !important;
					}

					#form-email{
						float: left !important;
						width: 100% !important;
					}

					#form-zip_code{
						float: left !important;
						margin-bottom: 13px !important;
						width: 100% !important;
					}

					#form_col2{
						width: 100% !important;
						height: 50px !important;
						display: flex;
						align-items: center;
						
						// @include breakpoint($bp-charlie){
						// 	position: absolute !important;
						// 	width: 50% !important;
						// 	bottom: 13px !important;
						// 	right: 0 !important;
						// }

						&::before{
							content: '\f105';
							color: $body;
							font-family: 'FontAwesome';
							font-size: 20px;
							position: relative;
							@include hover-fade();
							z-index: 2;
							font-weight: bold;
							line-height: 1em;
							margin-top:-2px;
							left: 19%;

							@include breakpoint(376px){
								left: 28%;
							}
							@include breakpoint($bp-charlie) {
								left: 29%;
							}
						}

						&:hover{
							&::before{
								color: #fff;
								left: 21%;
								@include breakpoint(376px){
									left: 30%;
								}
								@include breakpoint($bp-charlie) {
									left: 31%;
								}
							}
						}
					}

					input[type='submit']{
						float: left !important;
						margin: 0 !important;
						border-radius: 0 !important;
						max-width: 100% !important;
						min-width: auto !important;
						height: 50px !important;
						padding: 10px 15px !important;
						width: 100% !important;
						position: absolute !important;
						top: 0 !important;
						

						&:hover {
							background: $secondary;
							color: $body !important;				
						}
					}

				}
			}
		}
	}
	&.news-feed {
		margin: 0;
		padding: 50px 20px;
		@include breakpoint($bp-charlie) {
			padding: 85px 40px;
		}
		@include breakpoint($bp-bobby) {
			padding: 125px 40px;
		}
		h3 {
			text-transform: uppercase;
			text-align: center;
			margin: 0 0 35px 0;
			@include breakpoint($bp-charlie) {
				margin: 0 0 45px 0;
			}
			@include breakpoint($bp-bobby) {
				margin: 0 0 55px 0;
			}
		}
		.container {
			padding: 0 15px;

			@include breakpoint($bp-charlie){
				padding: 0;
			}
		}
		.read-more {
			float: none;
			margin: 0 auto;
			display: table;
			text-align: center;
			a {
				display: table;
			}
		}
		.col-md-8 {
			float: none;
			display: table;
			margin: 0 auto;
		}
		.news-items {
			float: left;
			width: 100%;
			margin: 0 0 0 0;
			@include breakpoint($bp-charlie) {
				margin: 0 0 55px 0;
			}
			@include breakpoint($bp-bobby) {
				margin: 0 0 75px 0;
			}
			//this class controls the order so that tweets are staggered ww/ featured posts
			.supports-flex-wrap & {
				&.stagger-tweets{
					display: flex;
					flex-wrap: wrap;
					.featured-post{
						order: 1;
						& + .featured-post{
							order: 3;
						}
					}
					.twitter {
						order: 1;
						& + .twitter{
							order: 4;
						}
					}
					@include breakpoint($bp-charlie) {
						.featured-post{
							order: 2;
							margin-right: 0;
							& + .featured-post{
								margin-right: 3%;
								order: 3;
							}
						}
						.twitter {
							order: 1;
							& + .twitter{
								order: 4;
							}
						}
					}
				}
			}
			.news-item {
				float: none;
				width: 100%;
				margin: 6px auto;
				background: #000000;
				border-radius: $borderRadius;
				@include box-shadow(0px, 6px, 30px, 0px, rgba(0, 0, 0, 0.15));
				height: 420px;
				position: relative;
				@include hover-fade();
				overflow: hidden;
				@include breakpoint(375px) {
					height: 350px;
				}
				@include breakpoint(414px) {
					height: 400px;
				}
				@include breakpoint($bp-charlie) {
					background: #fff;
					width: calc(50% - 1.5%);
					height: 475px;
					float: left;
					margin: 1.5% 3% 1.5% 0;
					max-width: 350px;
					max-width: 100%;
				}
				@include breakpoint($bp-bobby) {
					height: 555px;
					max-width: 100%;
				}

				$moduleNewsFeed: &;
				@at-root{
					.activeSection#{$moduleNewsFeed} {
						will-change: transform, background-color;
					}
				}

				&:nth-child(even){
					margin-right: 1.5%;
					margin-left: 1.5%;

					@include breakpoint($bp-charlie){
						margin-right: 0;
						margin-left: 0;
					}
				}

				&:nth-child(odd){
					margin-right: 1.5%;
					margin-left: 1.5%;

					@include breakpoint($bp-charlie){
						margin-right: 3%;
						margin-left: 0;
					}
				}

				&:hover {
					background: #000;
					@include transform(translateY(-6px));
					@include box-shadow(0px, 6px, 30px, 0px, rgba(0,0,0,0.15));
					&.facebook, &.twitter, &.featured-post {
						&.image-in.half {
							h5, small {
								color: #fff;
								opacity: 1;
							}
							.news-image {
								opacity: .3;
								@include breakpoint($bp-charlie) {
									opacity: 1;
								}
							}
							.news-content {
								.text-container {
									@include transform(translateY(0%));
								}
							}
						}

						&.image-in.full, &.image-in.video {
							h5, small {
								opacity: 1;
							}
							.news-image {
								opacity: .5;
							}
							.news-content {
								.text-container {
									@include transform(translateY(0%));
								}
							}
						}
					}
					.news-image .image {
						opacity: 0.5;
					}
					.play-icon {
						display: none;
						@include breakpoint(414px) {
							display: block;
						}
						color: #fff;

						&:before{
							background: $body;
						}

						svg {
							fill: $secondary;
						}
					}
					@include breakpoint($bp-charlie) {
						background: $secondary;
					}
				}
				a {
					position: absolute;
					left: 0px;
					top: 0px;
					right: 0px;
					bottom: 0px;
					z-index: 199!important;
				}
				.video-link {
					height: 0;
					z-index: 200 !important;
					@include breakpoint($bp-charlie) {
						height: 50%;
					}
				}
				.news-image {
					position: absolute;
					top: 0px;
					left: 0px;
					height: 100%;
					width: 100%;
					z-index: 1;
					background: #000;
					background-position: 50% 50% !important;
					@include hover-fade();
					//opacity: .5;
					.image {
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						height: 100%;
						width: 100%;
						background-size: cover !important;
						background-position: 50% 50% !important;
						@include hover-fade();
						//opacity: .5;
					}
					@include breakpoint($bp-charlie) {
						height: 50%;
					}
				}
				.message {
					@include hover-fade();
					margin: 15px 0;
					word-wrap: break-word;
					@include breakpoint($bp-charlie) {
						margin: 20px 0;
					}
					@include breakpoint($bp-bobby) {
						margin: 30px 0;
					}
				}
				.icon {
					position: absolute;
					right: 0;
					padding: 30px;
					i {
						font-size: 38px;
						@include hover-fade();
						color: $primary;
						&.fa-facebook {
							font-size: 26px;
						}
					}
					svg {
						fill: $body;
						width: 38px;
						height: auto;
						@include hover-fade();
					}
				}
				.play-icon {
					width: 50px;
					z-index: 9;
					position: relative;
					top: 50%;
					left: 50%;
					@include transform(translateX(-50%) translateY(-50%));
					@include hover-fade();

					&:before{
						content: '';
						display: block;
						position: absolute;
						background: #fff;
						height: 30px;
						width: 30px;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						@include hover-fade();
						z-index: -1;
					}

					svg {
						fill: $tertiary;
						width: 50px;
						height: 50px;
						display: block;
						margin: 0 auto 20px auto;
						border-radius: 999px;
						@include hover-fade();
					}
				}
				.news-content {
					position: absolute;
					height: 100%;
					top: 0px;
					left: 0px;
					right: 0px;
					padding: 0px;
					z-index: 2;
					.text-container {
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 25px 40px 20px 25px;
						width: 100%;
						@include breakpoint($bp-bravo) {
							padding: 55px 90px 55px 55px;
						}
						@include breakpoint($bp-charlie) {
							padding: 35px 70px 30px 35px;
						}
						@include breakpoint($bp-bobby) {
							padding: 55px 90px 55px 55px;
						}
					}
				}
				small {
					color: #fff;
					@include hover-fade();
					margin-bottom: 0px;
					opacity: 0.6;
					letter-spacing: 3.3px;
					@include breakpoint($bp-charlie) {
						color: $body;
					}
				}
				&.image-in {
					.icon {
						i {
							color: #fff;
						}
						svg {
							fill: #fff;
						}
					}
					&.full, &.video {
						h5, small {
							color: #fff;
						}
					}
				}
				&.half{
					background: $tertiary;

					h5{
						color: #fff;
					} 

					.icon svg{
						fill: #fff;
					}
					
					small {
						color: #fff;
						opacity: 0.6;
					}
				}
				&.half.long-tweet {
					.text-container h5, .text-container h4 {
						@include breakpoint($bp-charlie) {
							font-size: 14px;
						}
						@include breakpoint($bp-delta) {
							font-size: 15px;
						}
					}
				}
				&.no-image {
					background: #fff;
					&:hover {
						background: $primary;
						h5,
						small,
						.icon i {
							color: #fff;
						}
						.icon svg {
							fill: #fff;
						}

						small{
							opacity: 0.6;
						}
					}

					&.long-tweet{
						.text-container h5, .text-container h4 {
							font-size:14px;
							@include breakpoint(376px){
								font-size: 16px;
							}
	
							// @include breakpoint($bp-charlie) {
							// }
							@include breakpoint($bp-delta) {
								font-size: 18px;
							}

						}
					}
				}
				&.twitter {
					&.image-in {
						h5, small {
							color: #fff;
							@include breakpoint($bp-charlie) {
								color: $body;
							}
						}
						&.video, &.full {
							h5, small {
								color: #fff;
							}
						}
						small{
							opacity: 0.6;
						}
					}
					// &:nth-child(4), &:nth-child(5), &:nth-child(6) {
					// 	display: none;//we should have functions output exactly what we want without this backstop
					// }

					&:nth-child(odd){
						background: $primary;

						h5, small{
							color: #fff;
						}

						.icon {
							i {
								color: #fff;
							}
							svg {
								fill: #fff;
							}
						}

						small{
							opacity: 0.6;
						}
					}

					&:nth-child(even){
						background: $secondary;

						h5, small{
							color: $body;
						}

						.icon {
							i {
								color: #fff;
							}
							svg {
								fill: #fff;
							}
						}

						&:hover{
							background: $tertiary;

							h5, small{
								color: #fff;
							}
						}
					}
				}
				&.facebook, &.twitter, &.featured-post {
					&.image-in.half {
						.icon svg{
							fill: $body;

							@include breakpoint($bp-charlie){
								fill: #fff;
							}
						}

						.news-image{
							opacity: 0.3;

							@include breakpoint($bp-charlie){
								opacity: 1;
							}
						}

						h5 {
							opacity: 1;
							@include hover-fade();

							@include breakpoint($bp-charlie) {
								// opacity: 1;
							}
						}
						small {
							opacity: 0.6;
							@include hover-fade();
							@include breakpoint($bp-charlie) {
								// opacity: 0.6;
							}
						}
						.news-content {
							.text-container {
								@include transform(translateY(0));
								@include hover-fade();

								@include breakpoint($bp-charlie) {
									// @include transform(translateY(0%));
								}
							}
						}
					}
					&.image-in.full, &.image-in.video  {
						background: #000000;

						h5, small {
							opacity: 1;
							@include hover-fade();

							@include breakpoint($bp-charlie){
								opacity: 0;
							}
						}
						.news-image .image{
							opacity: 0.5;
							@include breakpoint($bp-charlie){
								opacity: 1;
							}
						}
						.news-content {
							.text-container {
								@include transform(translateY(0));
								@include hover-fade();

								@include breakpoint($bp-charlie){
									@include transform(translateY(25%));
								}
							}
						}
					}
					&.image-in.video{
						&:hover{
							.play-icon{
								opacity: 0;
							}
						}
					}
					.news-image {
						background-size: cover !important;
						&.full {
							height: 100%;
						}
					}
				}
				&.featured-post {
					background: #fff;

					h5{
						color: $body;
					} 

					.icon svg{
						fill: $primary;
					}
					
					small {
						color: $body;
						opacity: 0.6;
					}

					&:hover{
						h5{
							color: $body;
						} 
						small {
							color: $body;
							opacity: 0.6;
						}
					}

					&.no-image{
						&:hover{
							h5, small{
								color: #fff;
							}
						}
					}

					&.image-in{
						.icon svg{
							fill: #fff;;
						}

						&.half{
							.icon svg{
								fill: $body;

								@include breakpoint($bp-charlie){
									fill: #fff;
								}
							}
							.news-image{
								opacity: 0.3;

								@include breakpoint($bp-charlie){
									opacity: 1;
								}
							}

							.news-content .text-container{
								@include transform(translateY(0%));

								@include breakpoint($bp-charlie){
									@include transform(translateY(0%));
								}

								h5{
									opacity: 1;

									@include breakpoint($bp-charlie){
										// opacity: 0;
									}
								}

								small{
									opacity: 0.6;

									@include breakpoint($bp-charlie){
										// opacity: 0;
									}
								}
							}

							&:hover{
								h5{
									color: $body;
								} 
								small {
									color: $body;
									opacity: 0.6;
								}
							}
						}
						
					}
					&:nth-child(2n){
						background: $primary;

						h5{
							color: #fff;
						} 

						.icon svg{
							fill: #fff;
						}
						
						small {
							color: #fff;
							opacity: 0.6;
						}

						&:hover{
							background: $secondary;

							h5, small{
								color: $body;
							}
						}
					}
				}

			}
		}
		.btn {
			margin: 0 auto;
			display: table;
			float: none;
			width: auto;
		}

		&.module-feed{
			h3{
				font-size: 26px;
				text-align: left;
				margin-left: 5%;
			}
			.news-items .news-item{
				
				&:nth-child(even){
					margin: 0 0 15px;
	
					@include breakpoint($bp-charlie){
						margin-right: 0;
						margin-left: 0;
					}
				}
	
				&:nth-child(odd){
					margin: 0 0 15px;
	
					@include breakpoint($bp-charlie){
						margin-right: 3%;
						margin-left: 0;
					}
				}
			}
		}
	}
	&.featured-content {
		float: left;
		width: 100%;
		.image-slider {
			float: left;
			width: 100%;
			position: relative;
			padding: 0;
			overflow: hidden;
			.slick-prev {
				position: absolute;
				top: 37%;
				@include transform(translateY(-50%));
				left: 25px;
				width: 25px;
				height: 25px;
				-webkit-mask: url('img/next.svg') no-repeat 100% 100%;
				mask: url('img/next.svg') no-repeat 100% 100%;
				-webkit-mask-size: cover;
				mask-size: cover;
				background-color: #333;
				@include hover-fade();
				text-indent: -9999px;
				cursor: pointer;
				@include transform(rotate(180deg));
				z-index: 25;
				opacity: 0.5;
				@include breakpoint($bp-charlie) {
					left: 100px;
					background-color: #c8c8c8;
					top: 42%;
					width: 70px;
					height: 70px;
				}
				&:hover {
					background: #333;
					opacity: 1;
				}
			}
			.slick-next {
				position: absolute;
				top: 42%;
				@include transform(translateY(-50%));
				right: 25px;
				width: 25px;
				z-index: 25;
				height: 25px;
				-webkit-mask: url('img/next.svg') no-repeat 100% 100%;
				mask: url('img/next.svg') no-repeat 100% 100%;
				-webkit-mask-size: cover;
				mask-size: cover;
				background-color: #333;
				@include hover-fade();
				text-indent: -9999px;
				opacity: 0.5;
				cursor: pointer;
				@include breakpoint($bp-charlie) {
					right: 100px;
					background-color: #c8c8c8;
					top: 47%;
					width: 70px;
					height: 70px;
				}
				&:hover {
					background: #333;
					opacity: 1;
				}
			}
			.featured-image {
				height: auto;
				width: 100%;
				float: left;
				outline: none;
				background-repeat: no-repeat;
				background-position: center 0;
				background-size: cover!important;
				@include breakpoint($bp-charlie) {
					height: auto;
				}
				@include breakpoint($bp-delta) {
					height: auto;
				}
			}
			.featured-image{ 
				margin-top: 0;
			}
		}
		.featured-image {
			text-align: center;
			img {
				width: 100%;
				max-width: 1145px;
				height: auto;
				margin: 0 auto;
			}
			&.video-share {
				margin-top: 0;
				.videoWrapper {
					width: 100%;
					max-width: 1145px;
					height: auto;
					float: none;
					margin: 0 auto;
					@include breakpoint($bp-charlie) {
						min-height: 600px;
					}
					@include breakpoint($bp-bobby) {
						min-height: 700px;
					}
				}

				p{
					font-style: italic;
					text-align: center;
				}
			}
		}
		.col-md-8 {
			margin: 0 auto;
			display: table;
			float: none;
			text-align: left;
			margin-top: 15px;
			p {
				margin: 0;
				font-size: 14px;
				color: #666666;
				@include breakpoint($bp-charlie) {
					font-size: 15px;
				}
			}
		}
	}
	&.full-content {
		blockquote {
			text-align: left;
			margin: 35px 0;
			position: relative;
			padding-left: 20px;
			font-size: 22px;
			line-height: 145%;
			font-family: $primary-font;
			font-weight: 400;
			@include breakpoint($bp-charlie) {
				font-size: 25px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 28px;
				padding-left: 0;
				margin: 0 0 50px;
			}
			&:before {
				content: '';
				position: absolute;
				width: 3px;
				height: calc(100% - 6px);
				background: $tertiary;
				border-radius: $borderRadius + 1px;
				margin: 0 auto;
				top: 8px;
				left: 0;
				@include breakpoint($bp-delta) {
					left: -40px;
				}
			}
			p {
				color: $tertiary !important;
				font-size: 22px !important;
				line-height: 140%;
				font-family: $primary-font;
				font-weight: 400;
				margin-bottom: 25px;
				@include breakpoint($bp-charlie) {
					font-size: 25px !important;
				}
				@include breakpoint($bp-bobby) {
					font-size: 26px !important;
				}
				&:last-child {
					padding: 0px;
					margin: 0px;
				}
			}
			h6 {
				font-style: normal;
				text-transform: uppercase;
				color: $body !important;
				letter-spacing: 3.2px;
			}
			img {
				max-width: 100%;
			}
		}
		.col-md-8 {
			margin: 0 auto;
			float: none;
		}
		.container {
			//max-width: 1140px;
		}
		img {
			width: 100%;
			@include breakpoint($bp-bravo) {
				width: auto;
			}
		}
		@include breakpoint($bp-bravo, max) {
			a {
				word-break: break-word;
			}
		}
	}
	&.issues-page {
		margin-top: 30px;
		@include breakpoint($bp-charlie) {
			margin-top: 45px;
		}
		@include breakpoint($bp-bobby) {
			margin-top: 50px;
		}
	}
	&.columns {
		.row {
			.indiv-column {
				float: left;
				width: 100%;
				@include breakpoint($bp-charlie) {
					padding: 0 15px;
				}
			}
			@include breakpoint($bp-charlie) {
				@for $i from 1 through 8 {
		  			.indiv-column:first-child:nth-last-child(#{$i}),
		  			.indiv-column:first-child:nth-last-child(#{$i}) ~ .indiv-column {
		    			width: 100% / $i 
		    		} 
		    	}
		    }
		}
	}
	&.video {
		.videoWrapper {
			@include breakpoint($bp-charlie) {
				width: calc(100% + 150px);
				margin: 0 -75px;
			}
		}
		.col-md-8 {
			margin: 0 auto;
			display: table;
			float: none;
		}
	}
	&.block-quote {
		padding: 90px 0;
		background: $secondary;
		text-align: center;
		background-size: cover!important;
		background-position: 50% 50%;
		@include breakpoint($bp-charlie) {
			padding: 0;
			min-height: 630px;
			.container {
				height: 630px;
				// position: absolute;
			}
		}

    & + .block-quote,
    & + .donation,
    & + .take-action,
    & + .campaign-cta-module,
    & + .multiple-campaigns,
    & + .featured-activists {
      margin-top: 0;
    }

		.container{
			padding: 0;
		}
		.col-sm-6, .col-sm-9 {
			margin: 0 auto;
			display: table;
			float: none;
		}
		.row{
			margin: 0;
		}
		.col-sm-9 {
			@include breakpoint($bp-charlie){
				height: 630px;
			}
			.vertical-centered{
				position: relative;

				@include breakpoint($bp-charlie){
					position: absolute;
				}
			}
		}
		svg{
			color: #fff;
			display: block;
			height: 26px;
			width: 40px;
			margin: 0 auto 40px;
		}
		p {
			color: $body;
			font-size: 22px;
			line-height: 145%;
			font-family: $primary-font;
			font-weight: 400;
			margin-bottom: 25px;
			@include breakpoint($bp-charlie) {
				font-size: 25px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 28px;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		h6 {
			margin-top: 35px;
			margin-bottom: 0;
			color: $body;
			padding-left: 50px;
			display: inline-block;
			position: relative;

			&::before{
				background: $body;
				content: '';
				width: 35px;
				height: 4px;
				position: absolute;
				left: 0;
				top: 11px;
			}
		}
		.quote-image{
			bottom: -30px;
			right: 0px;
			top: 0;
			height: calc(100% + 30px);
			min-height: 350px;
			position: relative;

			@include breakpoint ($bp-charlie){
				min-height: 660px;
				position: absolute;
				right: 10px;
			}
		}
		&.has-image{
			background: $gray;
			position: relative;
			padding: 0 0 90px;

			@include breakpoint ($bp-charlie){
				padding: 0;
			}

			.module__mobile-image{
				background: $gray;
			}

			.vertical-centered{
				text-align: center;
				width: 100%;
				position: relative;

				@include breakpoint ($bp-charlie){
					width: auto;
					text-align: left;
					position: absolute;
				}
			}

			.col-sm-6 {
				margin: 0;
				padding: 90px 20px 20px;

				@include breakpoint ($bp-charlie){
					padding: 0;
					height: 630px;
					padding-left: 115px;
				}
				@include breakpoint ($bp-bobby){
					padding-left: 135px;
				}
				@include breakpoint (1280px){
					padding-left: 22px;
				}
			}

			.col-sm-5{
				@include breakpoint ($bp-charlie){
					width: 45%;
				}
				@include breakpoint($bp-echo){
					width: 41.66666667%;
				}
			}

			svg{
				color: $secondary;
			}

			p{
				font-size: 22px;

				@include breakpoint($bp-charlie) {
					font-size: 20px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 28px;
				}
			}

			h6{
				color: $quatenary;
				display: inline-block;
				margin: 0;
				
				@include breakpoint($bp-charlie){
					position: absolute;
					left: 0px;
					top: 50%;
					@include transform(rotate(-90deg));
					
				}

				&::before{
					background: $quatenary;
				}
			}
			
		}
	}
	&.buttons {
		.all-buttons {
			float: left;
			width: 100%;
			text-align: center;
			margin: 0 auto;
			@include breakpoint($bp-charlie) {
				float: none;
				display: table;
				width: auto;
			}
			.btn {
				margin: 10px 0px;
				display: inline-block;
				float: none;

				@include breakpoint($bp-charlie){
					margin: 10px 10px;
				}
			}
		}
		.btn {
			float: none;
			width: 100%;
			display: table;
			margin: 0 auto 8px auto;
			@include breakpoint($bp-charlie) {
				margin: 0 0 0 10px;
				width: auto;
				display: block;
				float: left;
			}
		}
	}
	&.signup {
	    @include breakpoint($bp-delta) {
	        padding: 200px 0;
	    }
	    input[type="text"], input[type="email"] {
	        color: #333;
	    }
	    p {
	        text-align: center;
	        @include breakpoint($bp-delta) {
	            width: 70%;
	            margin: 0 auto;
	            display: table;
	        }
	    }
	    h6 {
	        text-align: center;
	    }
	}
	&.image-slider {
		width: 100%;
		position: relative;
		padding: 0;
		overflow: hidden;
		margin-bottom: 25px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 50px;
			padding: 0;
		}
		.full-widht-slider-image {
			height: 300px;
			width: 100%;
			float: left;
			outline: none;
			background-repeat: no-repeat;
			background-position: center 0;
			background-size: cover!important;
			@include breakpoint($bp-charlie) {
				height: 450px;
			}
			@include breakpoint($bp-delta) {
				height: 650px;
			}
		}
		.slick-prev {
			position: absolute;
			top: 46%;
			@include transform(translateY(-50%));
			left: 25px;
			width: 25px;
			height: 25px;
			-webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
			mask: url('img/arrow.svg') no-repeat 100% 100%;
			-webkit-mask-size: cover;
			mask-size: cover;
			background-color: #fff;
			@include hover-fade();
			text-indent: -9999px;
			cursor: pointer;
			@include transform(rotate(180deg));
			z-index: 25;
			opacity: 0.5;
			@include breakpoint($bp-charlie) {
				width: 45px;
				height: 45px;
			}
			&:hover {
				background: #fff;
				opacity: 1;
			}
		}
		.slick-next {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			right: 25px;
			width: 25px;
			z-index: 25;
			height: 25px;
			-webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
			mask: url('img/arrow.svg') no-repeat 100% 100%;
			-webkit-mask-size: cover;
			mask-size: cover;
			background-color: #fff;
			@include hover-fade();
			text-indent: -9999px;
			opacity: 0.5;
			cursor: pointer;
			@include breakpoint($bp-charlie) {
				width: 45px;
				height: 45px;
			}
			&:hover {
				background: #fff;
				opacity: 1;
			}
		}
	}
	&.take-action{
		background: $gray;
		float: left;
		padding: 70px 0 30px;
		width: 100%;
		
		@include breakpoint($bp-charlie){
			border-right: 17px solid #fff;
			border-left: 17px solid #fff;
			border-top: 17px solid #fff;
			padding: 160px 0 170px;
		}

		.col-xs-12{

			&.col-lg-9, &.col-lg-11{
				float: none;
				margin: 0 auto;

				@include breakpoint($bp-bobby){
					padding-right: 22px;
					padding-left: 22px;
				}
				@include breakpoint($bp-echo){
					padding-right: 15px;
					padding-left: 15px;
				}
			}
		}
		

		.intro-block{
			margin-bottom: 55px;
			width: 100%;

			@include breakpoint($bp-echo){
				width: 455px;
			}
			
			h1{
				text-align: center;

				@include breakpoint($bp-echo){
					text-align: left;
				}
				
				span:before, span:after{
					background: $tertiary;
					height: 8px;

					@include breakpoint($bp-charlie) {
						height: 10px
					}
					
				}
			}
	
			p{
				font-family: $headline-font;
				line-height: 1.25em;
				letter-spacing: -0.2px;
				font-size: 24px;
				text-align: center;

				@include breakpoint($bp-echo){
					text-align: left;
				}
			}
		}
		.action-grid{
			width: 100%;
			font-size: 0px; /*Important for Firefox*/

			@include breakpoint($bp-charlie) {
				display: flex;
				justify-content: center;
			}

			@include breakpoint($bp-echo){
				justify-content: normal;
			}

			.single-action{
				border-bottom: 3px solid $body;
				display: flex;
				flex-direction: column;
				float: none;
				width: 100%;
				max-width: 100%;
				margin-bottom: 25px;
				height: auto;
				padding-bottom: 50px;
				position: relative;
				@include hover-fade();
				overflow: hidden;
				text-align: left;

				@include breakpoint($bp-charlie) {
					width: calc(33.33% - 5%);
					float: none;
					margin-right: 7%;
				}
				@include breakpoint($bp-bobby) {
					margin-right: 50px;
					width: 33.33%;
				}
				@include breakpoint($bp-echo){
					margin-right: 80px;
				}
				&:last-child{
					border: 0;
					margin-right: 0;

					@include breakpoint($bp-charlie){
						border-bottom: 3px solid $body;
					}
				}
				.icon{
					margin-bottom: 20px;

					svg{
						height: 73px;
						width: 73px;
	
						&#alerts{
							.cls-1{fill:$secondary;}.cls-2{fill:#c37803;}
						}
						&#donate{
							.cls-1{fill:#c37803;}.cls-2{fill:$secondary;}
						}
						&#petition{
							.cls-1{fill:#9f0a45;}.cls-2{fill:$tertiary;}
						}
						&#text{
							.cls-1{fill:$primary;}.cls-2{fill:#0556a7;}
						}
						&#volunteer{
							.cls-1{fill:$tertiary;}.cls-2{fill:#9f0a45;}
						}
					}
				} 

				h4{
					text-transform: uppercase;
					margin-bottom: 15px;
					font-size: 23px;
					letter-spacing: 0.01em;

					@include breakpoint($bp-charlie) {
						font-size: 21px;
					}
					@include breakpoint($bp-bobby) {
						font-size: 26px;

					}
				}

				p{
					font-size: 16px;
					margin-bottom: 0;
					flex: 1 1 auto;
				}
				.btn{
					font-size: 17px;
					padding: 9px 15px;
					position: relative;
					max-height: 50px;
					margin-top: 30px;

					@include breakpoint($bp-charlie){
						min-width: 0;
					}

					&::before{
						content: '\f105';
						color: $body;
						font-family: 'FontAwesome';
						font-size: 20px;
						position: relative;
						line-height: 1em;
						left: -15px;
						@include hover-fade();
						top: 1px;
					}

					&:hover{
						position: relative;

						&::before{
							position: relative;
							left: -10px;
						}
					}

					&.volunteer, &.petition{
						border-color: $tertiary;

						&:before{
							color: $tertiary
						}

						&:hover{
							background: $tertiary;

							&::before{
								color: $body;
							}
							
						}
					}
					&.donate{
						border-color: $secondary;

						&:before{
							color: $secondary
						}

						&:hover{
							background: $secondary;
							color: $body;

							&::before{
								color: $body;
							}
							
						}
					}
				}
			}

			&.two-actions{
				text-align: left;
				.single-action{
					max-width: 320px;
					width: 100%;
					@include breakpoint($bp-charlie){
						max-width: 265px;
						width: 50%;
						margin-right: 80px;
					}

					@include breakpoint($bp-bobby){
						max-width: 320px;
						margin-right: 140px;
					}

					&:last-child{
						margin-right: 0;
					}
				}
			}
		}

		&.no-icons{
			.action-grid{
				.single-action{
					height: auto;

					@include breakpoint($bp-charlie){
						height: 315px;
					}

					@include breakpoint($bp-bobby){
						height: 250px;
					}

					&:first-child .btn{
						border-color: $tertiary;

						&:before{
							color: $tertiary
						}

						&:hover{
							background: $tertiary;

							&:before{
								color: #fff;
							}
						}
					}

					&:nth-child(2) .btn{
						border-color: $secondary;

						&:before{
							color: $secondary
						}

						&:hover{
							background: $secondary;

							&:before{
								color: #fff;
							}
						}
					}

					&:nth-child(3) .btn{
						border-color: $primary;

						&:before{
							color: $primary
						}

						&:hover{
							background: $primary;

							&:before{
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	&.featured-resources{
		float: left;
		width: 100%;
		
		@include breakpoint($bp-echo){
			margin-top: 130px;
		}

		.container{
			// width: 100%;
			
			@include breakpoint($bp-echo){
				padding: 0;
				// width: 1145px;
			}
		}
		.resources-top{
			position: relative;

			.resource-arrows{
				text-align: right;
				margin-bottom: 35px;
	
				// @include breakpoint($bp-charlie){
				// 	margin-bottom: 0;
				// }
	
				.slick-next, .slick-prev{
					display: inline-block;
					text-indent: -9999px;
					height: 62px;
					width: 60px;
					cursor: pointer;
				}
				.slick-prev{
					margin-right: 40px;
					position: relative;
					@include transform(rotate(-180deg));
					color: $body;
	
					&:hover{
						color: $secondary;
					}
				}
				.slick-next{
					color: $body;
					position: relative;
	
					&::before{
						content: '';
						width: 3px;
						height: 23px;
						background-color: $details;
						position: absolute;
						left: -20px;
						top: 20px;
					}
	
					&:hover{
						color: $secondary;
					}
				}
			}
	
			h3{
				font-size: 26px;
				text-align: left;
				margin-left: 5%;
				text-transform: uppercase;
				letter-spacing: 2.6px;
				margin-bottom: 25px;
				
				@include breakpoint($bp-charlie){
					margin-bottom: 0;
					position: absolute;
					bottom: 0
				}
			}
		}


		.resources-slider{

	    visibility: hidden;
	    &.slick-initialized{
	        visibility: visible;
	    }
	    .slick-track{
		    	display: flex;
		    	align-content: stretch;
		    	.resource{
		    		height: auto;
		    		flex: 1 0 auto;
		    	}
		  }


			@include breakpoint($bp-echo){
				margin-left: -20px;
				margin-right: -20px;
				// width: 1145px;
			}

			.slick-list{
				// overflow-y: visible;
			}

			.resource{
				border: 8px solid $body;
				padding: 50px 20px 50px 20px;
				margin: 0 0 20px 0px;
				@include hover-fade();
				// min-height: 400px;
				max-height: 500px;
				// height: 400px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;

				@include breakpoint($bp-charlie){
					// min-height: 545px;
					max-height: 545px;
					// height: 545px;
					margin: 0 10px 20px 10px;
					padding: 100px 50px;
					.inner-resource-content{
						max-width:100%;
					}
				}
				
				@include breakpoint($bp-bobby){
					margin: 0 20px 20px 20px;
					padding: 100px 70px;
				}

				svg {
					position: absolute;
					width: 41px;
					height: 35px;
					top: 35px;
					right: 35px;
					@include hover-fade();
				}

				h4, p, a{
					@include hover-fade();
				}

				h4{
					letter-spacing: normal;
					text-transform: unset;
					font-size: 24px;

					@include breakpoint($bp-charlie){
						font-size: 26px;
					}

					@include breakpoint($bp-bobby){
						font-size: 28px;
					}

					@include breakpoint($bp-echo){
						font-size: 34px;
					}
				}

				p{
					line-height: 150%;

					@include breakpoint($bp-charlie){
						line-height: 170%;
					}
				}

				.above-headline{
					text-transform: uppercase;
					font-size: 12px;
					letter-spacing: 3px;
					font-family: $headline-font;

					@include breakpoint ($bp-charlie, max){
						&:before{
							content: '';
					    float: right;
					    width: 60px;
					    height: 2em;
						}
					}

					a{
						color: $quatenary;
						display: inline-block;
						font-size: 12px;
						// margin-right: 20px;
						text-decoration: none;

						&:hover{
							color: $secondary;
						}
					}

					span{
						display: inline-block;
						@include hover-fade();

						&.category{
							// margin-right: 20px;
							
						}
						&.date, &.separator{
							color: $details;
						}
					}
				}

				.excerpt{
					font-size: 16px;
				}

				a{
					font-family: $headline-font;
					font-size: 17px;
					
					&.learn-more{
						padding-left: 20px;
						position: relative;
						color: $primary;
						display: inline-block;
						float: none;
						width: auto;
						margin: 0;
						
						&::before{
							content: '\f105';
							font-family: 'FontAwesome';
							font-size: 23px;
							font-weight: bold;
							position: absolute;
							top: 49%;
							-webkit-transform: translateY(-50%);
							-ms-transform: translateY(-50%);
							transform: translateY(-50%);
							left: 0;
							// @include hover-fade();
						}
						span{
							display: inline-block;
							border-bottom: 2px solid;
							line-height: 1em;
						}

						&:hover{
							color: $secondary;

							&::before{
								// left: 5px;
							}
						}
					}


				}

				&:hover{
					border-color: $primary;
					background: $primary;
					box-shadow: 10.6px 10.6px 20px 0 rgba(0, 0, 0, 0.2);
					-moz-box-shadow: 10.6px 10.6px 20px 0 rgba(0, 0, 0, 0.2);
					-webkit-box-shadow: 10.6px 10.6px 20px 0 rgba(0, 0, 0, 0.2);

					h4, p, a, .above-headline a, .above-headline .date, .above-headline .separator{
						color: #fff;
					}

					svg{
						fill: #fff;
					}

					.above-headline a:hover{
						color: $secondary;
					}
				}
			}
		}
	}
	&.campaign-cta-module{
		float: left;
		background: $gray;
		position: relative;
		padding: 20px 0 50px;
		width: 100%;

		@include breakpoint ($bp-charlie){
			padding: 0;
		}
		

		.vertical-centered{
			text-align: left;
			width: auto;
			
			@include breakpoint ($bp-charlie){
				position: absolute;
				
			}
		}

		.col-sm-6 {
			margin: 0;
			width: 100%;
			
			@include breakpoint ($bp-charlie){
				height: 630px;
				padding-left: 70px;
				width: 45%;
			}
			@include breakpoint ($bp-bobby){
				padding-left: 125px;
				width: 48%;
			}
			@include breakpoint ($bp-echo){
				padding-left: 0px;
				width: 50%;
			}
		}

		.col-sm-5{
			@include breakpoint ($bp-charlie){
				width: 50%;
			}

			@include breakpoint($bp-bobby){
				width: 45%;
			}
			@include breakpoint($bp-echo){
				width: 41.66666667%;
			}
		}

		h2{
			span{
				&:before, &:after{
					background: $primary;
					bottom: 2px;
				}

				&:after{
					left: -2px;

					@include breakpoint($bp-bobby){
						left: 5px;
					}
				}
			}
		}
		p {
			color: $body;
			line-height: 160%;
			font-family: $primary-font;
			font-weight: 400;
			margin-bottom: 25px;
			font-size: 16px;
			@include breakpoint($bp-bobby) {
				font-size: 18px;
			}
		}
		h6 {
			color: $quatenary;
			font-size: 13px;
			letter-spacing: 2px;
			display: inline-block;
			position: relative;
			text-transform: uppercase;
			left: 0px;
			margin-bottom: 20px;
			
			@include breakpoint ($bp-charlie){
				margin: 0;
				background: $quatenary;
				color: #fff;
				left: 0px;
				margin: 0;
				padding: 0 15px;
				position: absolute;
				top: 0;
				@include transform(rotate(-90deg) translateX(-100%));
				transform-origin: top left;
				height: 40px;
				line-height: 40px;
			}

			&.desktop{
				display: none;
				@include breakpoint ($bp-charlie){
					display: block;
				}
			}
			&.mobile{
				display: block;
				@include breakpoint ($bp-charlie){
					display: none;
				}
			}

			a{
				color: $quatenary;

				@include breakpoint ($bp-charlie){
					color: #fff;
				}
			}
		}
		.quote-image{
			position: relative;
			height: 100%;
			margin-bottom: 30px;
			min-height: 400px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			@include breakpoint ($bp-charlie){
				bottom: -30px;
				position: absolute;
				right: 10px;
				top: 0;
				height: calc(100% + 30px);
				margin-bottom: 0;
				min-height: 660px;
			}
		}

		.link{
			font-family: $headline-font;
			font-size: 17px;
			font-weight: bold;

			i{
				font-size: 13px;
				margin-right: 5px;
			}

			svg{
				height: 13px;
				width: 13px;
				margin-right: 5px;
			}

			span{
				display: inline-block;
				border-bottom: 2px solid;
				line-height: 1em;
			}
		}
	}

	&.multiple-campaigns{
		background: $gray;
		border-right: 17px solid #fff;
		border-left: 17px solid #fff;
		border-top: 17px solid #fff;
		float: left;
		padding: 160px 0 170px;
		width: 100%;

		&.archive{
			background: transparent;
			border: 0;
			margin-bottom: 0px;
			padding: 0px 0 0;

			@include breakpoint($bp-charlie){
				padding: 60px 0 0;
			}

			.campaign-grid{
				text-align: left;

				.single-campaign{
					margin-bottom: 25px;
					padding: 0 0 30px;
					border-bottom: 3px solid $body;
					height: auto;

					
					@include breakpoint($bp-charlie) {
						padding: 0 25px;
						border-bottom: 0;
						padding-bottom: 0;
						margin-bottom: 50px;
						min-height: 650px;
						max-height: 650px;

						&:nth-child(2n){
							border-right-color: #fff;
						}
					}

					@include breakpoint($bp-bobby) {

						&:nth-child(2n){
							border-right-color: $body;
						}
						
						&:nth-child(3n){
							border-color: #fff;
						}
					}

					

					.campaign-image{
						.category a{
							color: #fff;
						}
					}

					h5{
						font-size: 22px;
					}

					p{
						font-size: 16px;
						@include breakpoint($bp-charlie){
							font-size: 15px;
						}
						@include breakpoint($bp-echo){
							font-size: 16px;
						}
					}

				}
				
			}
		}

		.intro-block{
			margin-bottom: 55px;
			
			h2{
				text-align: center;
				span:before, span:after{
					background: $tertiary;
				}
			}
		}
		.campaign-grid{
			width: 100%;
			display: block;
			text-align: center;
			font-size: 0px; /*Important for Firefox*/

			.single-campaign{
				display: inline-block;
				float: none;
				width: 100%;
				height: auto;
				padding: 0 25px;
				position: relative;
				@include hover-fade();
				overflow: hidden;
				text-align: left;
				border-bottom: 3px solid $body;				
				
				@include breakpoint($bp-charlie) {
					border-right: 3px solid $body;
					border-bottom: 0;
					width: calc(50% - 12px);
					min-height: 650px;
					max-height: 650px;
					float: none;
					display: inline-block;
					// margin-right: 11.9%; 
				}
				@include breakpoint($bp-bobby) {
					width: 33.33%;
				}
				&:last-child{
					margin-right: 0;
					border-right: 0;
					border-bottom: 0;
				}
				.campaign-image{
					background-size: cover; 
					background-position: center center;
					height: 235px;
					margin-bottom: 25px;
					position: relative;

					.category{
						background: $quatenary;
						color: #fff;
						font-family: $headline-font;
						font-weight: bold;
						font-size: 9px;
						letter-spacing: 2px;
						padding: 0 15px;
						display: inline-block;
						position: absolute;
						text-transform: uppercase;
						left: 0px;
						margin: 0;
						top: 0;
						@include transform(rotate(-90deg) translateX(-99%));
						transform-origin: top left;
						height: 30px;
						line-height: 30px;

						a{
							color: #fff;
						}
					}
				}
				
				.category{
					text-transform: uppercase;
					font-size: 13px;
					letter-spacing: 1px;
					font-family: $headline-font;
					margin-bottom: 10px;
					line-height: 130%;

					a{
						color: $quatenary;
						display: inline;
						font-size: 13px;
						text-decoration: none;
						line-height: 150%;

						&:hover{
							color: $primary;
						}
					}

					.separator{
						color: $details;
					}
				}
				.content-wrapper{
					padding: 0 20px;
				}


				h5{
					text-transform: uppercase;
					margin-bottom: 15px;
				}

				p{
					font-size: 16px;
					margin-bottom: 30px;
				}

				.link{
					font-family: $headline-font;
					font-size: 17px;
					font-weight: bold;
					color: $primary;

					i{
						font-size: 13px;
						margin-right: 5px;
					}

					svg{
						height: 14px;
						width: 14px;
						margin-right: 5px;
					}

					span{
						display: inline-block;
						border-bottom: 2px solid;
						line-height: 1em;
					}

					&:hover{
						color: $quatenary;
					}
				}
				
				&:hover{
					background: #fff;
					box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
					-moz-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
					-webkit-box-shadow: 10.6px 10.6px 40px 0 rgba(0, 0, 0, 0.2);
					border-color: #fff !important;
				}
			}

			&.two-campaigns{
				.single-campaign{
					max-width: 495px;
					width: 50%;

					.content-wrapper{
						padding: 0 40px;
					}
				}
			}
		}
	}

	&.featured-activists{
		background: $body;
		float: left;
		width: 100%;
		min-height: inherit;
		max-height: inherit;
	
		@include breakpoint($bp-charlie){
			min-height: 700px;
			max-height: 700px;
		}

		.feat-activist-grid{
			.feat-activist{
				position: relative;
				margin-right: 0;
				left: 0;

				@include breakpoint($bp-bobby){
					margin-right: 25px;
					left: -25px;
				}
				@include breakpoint($bp-echo){
					margin-right: 35px;
					left: -35px;
				}
				.activist-image{
					background-size: cover;
					background-position: center center;
					float: left;
					position: relative; 
					top: 0;
					min-height: 350px;
					margin: 16px;
					width: calc(100% - 32px);

					@include breakpoint($bp-charlie){
						// height: calc(100% + 45px);
						margin: 0;
						min-height: 745px;
						width: 50%;
					}

					@include breakpoint($bp-bobby){
						width: 45%;
					}
					@include breakpoint($bp-echo){
						width: 40%;
					}
				}
				.activist-wrapper{
					margin-bottom: 110px;
					position: relative;
					overflow: hidden;
					width: 100%;
					
					@include breakpoint($bp-charlie){
						margin-bottom: 0;
						height: 700px;
						width: 50%;
					}

					@include breakpoint($bp-bobby){
						width: 55%;
					}
					@include breakpoint($bp-echo){
						width: 60%;
					}
				}
				
				.content{
					float: left;
					position: relative;
					padding: 40px 25px 0;
					
					@include breakpoint($bp-charlie){
						top: 50%;
						@include transform(translateY(-50%));
						padding: 0 10% 0 22%;
					}

					@include breakpoint($bp-bobby){
						padding: 0 10% 0 25%;
					}
					@include breakpoint($bp-echo){
						padding: 0 25%;
					}
				}
				h6 {
					color: $quatenary;
					font-size: 16px;
					text-transform: uppercase;
					letter-spacing: 0.2em;
					padding-left: 50px;
					display: inline-block;
					margin: 0;
					left: 0;
					position: relative;

					@include breakpoint(376px){
						left: 25px;
					}
					
					@include breakpoint($bp-charlie){
						position: absolute;
						top: 50%;
						@include transform(rotate(-90deg));
						left: -145px;
					}

					@include breakpoint($bp-bobby){
						left: -120px;
					}
					@include breakpoint($bp-echo){
						left: -50px;
					}

					&::before{
						background: $quatenary;
						content: '';
						width: 35px;
						height: 4px;
						position: absolute;
						left: 0;
						top: 7px;
					}
				}
				
				svg{
					fill: $secondary;
					height: 49px;
					width: 64px;
					margin-bottom: 35px;
				}

				h2{
					font-size: 30px;
					color: #fff;

					@include breakpoint($bp-charlie){
						font-size: 38px;
					}

					@include breakpoint($bp-bobby){
						font-size: 47px;
					}
				}

				.description{
					color: #fff;
					font-size: 16px;

					@include breakpoint($bp-charlie){
						font-size: 18px;
					}

					@include breakpoint($bp-bobby){
						font-size: 20px;
					}
				}

				.location{
					color: $tertiary;
					font-size: 17px;
					font-family: $headline-font;
				}
			}
		}

		.grid-arrows{
			position: absolute;
			bottom: 30px;
			right: 0;
			left: 0;
			margin: 0 auto;
			text-align: center;

			@include breakpoint($bp-charlie){
				left: auto;
				margin: 0;
				text-align: inherit;
				bottom: 20px;
				right: 20px;
			}

			@include breakpoint($bp-bobby){
				bottom: 45px;
				right: 95px;
			}
			
			.slick-next, .slick-prev{
				display: inline-block;
				cursor: pointer;
				color: #fff;
				
				svg{
					height: 62px;
					width: 62px;
					
					.cls-1{
						fill: #fff;
						@include hover-fade();
					}
				}

				&:hover{
					color: $secondary;
				}
			}
			.slick-prev{
				margin-right: 40px;
				@include transform(rotate(-180deg));
				
			}
			.slick-next{
				position: relative;

				&::before{
					content: '';
					width: 3px;
					height: 23px;
					background-color: $details;
					position: absolute;
					left: -20px;
					top: 20px;
				}
			}
		}
	}
}



.donation {
  .image-overlay {
    background-color: rgba(#000, 0.35);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
    ul {
        margin: 0px;
        padding: 0px;
        float: left;
        width: 100%;
        li {
            float: left;
            width: 100%;
            list-style-type: none;
            margin: 0 0 5px;
            @include breakpoint($bp-charlie) {
                width: 48%;
                margin: 0 0 10px;
                &:nth-child(2n+2) {
                    float: right;
                }
            }
            a {
                color: $body;
                text-align: center;
                text-decoration: none;
                display: block;
                float: left;
                width: 100%;
                font-size: 18px;
                padding: 8px 5px 10px;
                background: $secondary;
                font-family: $headline-font;
				font-weight: 600;
                border: 2px solid $secondary;
                border-radius: $borderRadius;
                line-height: 30px;
                &:hover,
                &:focus {
                	background: $quatenary;
                	color: #fff;
                	border-color: $quatenary;
                }
                @include breakpoint($bp-charlie) {
                    font-size: 21px;
                }
            }
            &:last-child {
                a {
                	background: transparent;
                	border: 2px solid #fff;
                    font-size: 14px;
                    color: #fff;
                    @include breakpoint($bp-charlie) {
                        height: 52px;
                    }
                    &:hover {
	                	background: $quatenary;
	                	border-color: $quatenary;
	                }
                }
            }
        }
    }
    .all-columns {
        margin-top: 10px;
        .text-col {
            &:last-child {
                padding: 0px;
                margin-top: 10px;
                @include breakpoint($bp-charlie) {
                    margin: 0px;
                }
            }
        }
    }
    .col-lg-10, .col-lg-8 {
        float: none;
        margin: 0 auto;
        display: table;
    }
    .donation-title {
	    float: left;
	    width: 100%;
	    text-align: center;
	    color: #fff;
	    margin-bottom: 30px;
	    @include breakpoint($bp-charlie) {
	        width: 100%;
	    }
	    h6 {
	    	margin-bottom: 15px;
	    	color: #fff;
	    }
	    h2  {
	    	margin-bottom: 25px;
			color: #fff;
			font-size: 40px;
			
			@include breakpoint($bp-charlie){
				font-size: 32px;
			}

			@include breakpoint($bp-bobby){
				font-size: 58px;
			}
			
			span:after, span:before{
				height: 8px;

				@include breakpoint($bp-bobby){
					height: 10px;
				}
			}
	    }
	}
}

.quick-link__flex {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid $details;
	border-bottom: 1px solid $details;

	@include breakpoint($bp-charlie) {
		text-align: left;
		flex-direction: row;
	}
}

.quick-link__headline,
.quick-link__link {
	margin: 0;

	@include breakpoint($bp-charlie) {
		max-width: 50%;
	}
}

.quick-link__headline {
	text-transform: uppercase;
	font-size: 12px;
}

.quick-link__link {
	font-size: 16px;
	padding-left: 10px;
	font-weight: 600;

	i{
		font-size: 14px;
	}

	svg {
		@include transform(rotate(-90deg) translateX(1px));
		width: 12px;
		height: 6px;
		margin-left: 8px;
	}
}

.download-resource__flex {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	border-top: 1px solid $details;
	border-bottom: 1px solid $details;
}


.download-resource__link {
	margin: 0;
}

.download-resource__link {
	font-size: 16px;
	padding-left: 10px;
	font-weight: 600;
	color: $primary;

	
	i{
		font-size: 14px;
	}
	
	svg {
		width: 14px;
		height: 13px;
		margin-right: 5px;
		
		.cls-3{
			fill: $primary;
			@include hover-fade();
		}
	}
	
	&:hover{
		color: $tertiary;

		svg .cls-3{
			fill: $tertiary;
		}
	}
}

.module__mobile-image {
	background-color: $solidBgColor;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	@include breakpoint($bp-charlie) {
		display: none;
	}

	&.-color + .overlay {
		display: none;

		@include breakpoint($bp-charlie) {
			display: block;
		}
	}
}

//fix for content pages
.single .content .module:first-child {
	margin-top: 10px;
	@include breakpoint($bp-charlie) {
		margin-top:30px;
	}

}
.single .content .full-content{
	p a {
		word-break: break-word;
	}
}



.ie10 {
	.news-item {
		max-width: 47% !important;
		float:left !important;
	}
}
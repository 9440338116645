/* Variables are only for the topper menus */

$solidBgColor	: $body;
$headlineColor	: #fff;
$paragraphColor	: #fff;

//Tall Topper Height
$tallDesktop	: 780px;
$tallTablet		: 540px;

//Short Topper Height
$shortDesktop	: 495px;
$shortTablet	: 495px;

.home .topper {
	height: auto!important;
	max-height: 100%;
	min-height: 905px;
	padding: 0 0 0 0;
	@include transition(all 0.3s ease-in-out);
	//margin-top: $header-mobile-height;
	@include breakpoint($bp-charlie) {
		height: calc(100vh - 72px)!important;
		max-height: 650px;
		min-height: 550px;
		//margin-top: $header-height;
	}
	@include breakpoint($bp-bobby) {
		height: calc(100vh - 72px)!important;
		max-height: 850px;
		min-height: 720px;
		//margin-top: $header-height;
	}
}

.topper__mobile-image {
	background-color: $solidBgColor;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	@include breakpoint($bp-charlie) {
		display: none;
	}
}

.topper {
	background-color: #fff;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	float: left;
	width: 100%;
	position: relative;
	padding: 40px 0 40px;
	//margin-top: $header-mobile-height;
	@include breakpoint($bp-charlie) {
		padding: 0 0 0 0;
		//margin-top: $header-height;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	h6 {
		color: $quatenary;
		margin-bottom: 10px;
		@include breakpoint($bp-charlie){
			margin-bottom: 25px;
		}
		padding-left: 50px;
		text-transform: uppercase;

		&::before{
			background: $quatenary;
			content: '';
			width: 35px;
			height: 4px;
			position: absolute;
			left: 0;
			top: 9px;
		}
	}
	h1 {
		margin-bottom: 10px;
		@include breakpoint($bp-charlie, max){
			font-size: 36px;
		}
		@include breakpoint($bp-charlie){
			margin-bottom: 35px;
		}
		span{
			padding: 0;
			display: inline-block;
			transition: .45s;
			position: relative;
			&:before{
				background: $primary;
				content: '';
				transform: skew(-30deg,0);
				width: 106%;
				height: 6px;
				position: absolute;
				bottom: 0;
				left: -10px;
				padding: 0 1px;
				z-index: -1;
			}
			&:after{
				background: $primary;
				content: '';
				transform: skew(-30deg,0);
				width: 106%;
				height: 6px;
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 0 1px;
				z-index: -1;
			}

			@include breakpoint($bp-charlie){
				&:before, &:after{
					height: 10px;
				}
			}
		}
	}
	p {
		color: $paragraphColor;
	}
	.topper-image-wrapper{
		z-index: 0;
		.js &{
			// opacity: 0;
		}
	}
	.topper__mobile-image.-color + .overlay {
		display: none;
		@include breakpoint($bp-charlie) {
			display: block;
		}
	}
	.container {
		height: 100%;
		// max-width: 680px;
		// set to 0 so it kicks in with animations. if animation is removed, remove this opacity

		.js & {
			opacity: 0;
		}
		.row {
			height: 100%;
		}
	}
	.donation {
        margin: 15px 0 0 0;
        @include breakpoint($bp-charlie) {
            margin: 20px 0 0 0;
        }
        .donation-note {
            color: white;
        }
        .col-lg-8 {
            margin: 0 auto;
            float: none;
        }
        ul {
            li {
                font-weight: 700;
            }
        }
    }
	.deck {
		margin-bottom: 25px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 45px;
		}
		p {
			margin-bottom: 20px;
			line-height: 160%;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.vertical-centered{
		text-align: left;
	}
	&.short {
		background: $body;
		height: auto;
		padding: 65px 0 55px 0;
		min-height: 200px;

		&.withImage{
			background-image: none;
		}

		.deck {
			margin-bottom: 0px;
		}
		@include breakpoint($bp-charlie) {
			height: $shortTablet;
		}
		@include breakpoint($bp-bobby) {
			height: $shortDesktop;
		}
		.vertical-centered{
			// position: static;
			transform: none;
			position: absolute;
			bottom: 15px;
			top: auto;
			max-width: 95%;
			padding-left: 25px;
			padding-right: 25px;
			@include breakpoint($bp-charlie){
				bottom: 25px;
				max-width: 1145px;
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}

		h6{
			color: $quatenary;	
			margin-bottom: 20px;
		}

		.topper-image-wrapper{
			width: 100%;

			.image{
				width: calc(100% - 32px);
				left: 16px;
			}
		}
		.next-issue{
			display: none;
		}
	}
	&.tall {
		height: auto;
		padding: 85px 0 100px 0;
		max-height: 730px;
		min-height: 350px;
		@include breakpoint($bp-charlie) {
			height: $tallTablet;
		}
		@include breakpoint($bp-bobby) {
			height: calc(100vh - 74px);
			min-height: 600px;
		}

		h1{
			font-size: 35px;

			@include breakpoint($bp-charlie) {
				font-size: 40px;
			}
			
			@include breakpoint($bp-charlie) {
				font-size: 48px;
			}

			@include breakpoint($bp-bobby) {
				font-size: 58px;
			}
		}

		.deck {
			display: none;
			@include breakpoint($bp-charlie) {
				display: block;
			}
		}

		.topper-image-wrapper{
			position: absolute;
			left: 0;
			width: 100%;
			height: 100%;
			top: 0;

			@include breakpoint($bp-charlie) {
				// top: -22px;
				width: 45%;
			}

			.image{
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				width: calc(100% - 32px);
				height: 100%;
				position: absolute;
				z-index: 1;
				right: 16px;
				bottom: 16px;

				@include breakpoint($bp-charlie){
					width: 100%;
				}
			}

			&:after{
				background: $body;
				bottom: 0;
				content: '';
				height: calc(100% - 52px);
				position: absolute;
				right: 0;
				width: 105%;
			}
		}
		.tall-content-wrapper{
			min-height: auto;
			max-height: 730px;
			position: absolute;
			right: 0;
			width: 100%;
			bottom: 10px;
			
			@include breakpoint($bp-charlie){
				bottom: auto;
				height: calc(100% - 85px);
				min-height: 455px;
				width: 55%;
			}

			@include breakpoint($bp-bobby){
				min-height: 600px;
			}

			h1, h2, h3, h4, h5, h6, p {
				color: $body;
			}
			h6{
				color: #fff;

				&:before{
					background: #fff;
				}

				@include breakpoint($bp-charlie){
					color: $quatenary;

					&:before{
						background: $quatenary;
					}
				}
			}
			h1{
				color: #fff;

				@include breakpoint($bp-charlie) {
					color: $body;
				}
			}
			.container{
				max-width: 100%;

				@include breakpoint($bp-charlie){
					max-width: 375px;
				}

				@include breakpoint($bp-bobby){
					max-width: 455px;
				}

				@include breakpoint($bp-echo){
					max-width: 500px;
				}
			}
		}
	}
	&.home {
		height: auto;
		padding: 55px 0 50px;
		max-height: inherit;
		min-height: auto;

		@include breakpoint($bp-charlie) {
			max-height: 730px;
			min-height: auto;
			height: $tallTablet;
			padding: 0 0 0 0;
		}
		@include breakpoint($bp-bobby) {
			height: $tallDesktop;
			padding: 0 0 0 0;
		}
		.container{
			min-height: 450px;
			width: calc(100% - 30px);
			position: relative;

			@include breakpoint($bp-charlie){
				min-height: auto;
				width: 100%;
			}

			@include breakpoint($bp-bobby){
				width: 1145px;
			}
		}
		.topper-image-wrapper{
			max-height: 505px;

			@include breakpoint($bp-charlie){
				max-height: 100%;
			}
		}
		.vertical-centered{
			max-width: 100%;
			margin: 0 auto;
			position: absolute;
			top: auto;
			transform: none;
			bottom: 0px;
			
			@include breakpoint($bp-charlie) {
				bottom: 95px;
				max-width: 725px;
			}
			@include breakpoint($bp-echo) {
				max-width: 1095px;
			}
		}
		.deck {
			display: block;
			@include breakpoint($bp-charlie) {
				display: block;
			}
		}

		.read-more{
			left: 35px;
			right: auto;
			bottom: 45px;
		}
	}
	&.fourOhFour{
		background: $body;
	}

	&.threeRs-home{
		min-height: auto;
		padding: 55px 0;
		@include breakpoint($bp-charlie){
			padding: 0;
		}

		h6{
			color: #fff;

			&:before{
				background: #fff;
			}
		}

		.vertical-centered{
			bottom: 20px;

			@include breakpoint($bp-charlie){
				bottom: 35px;
			}

			@include breakpoint($bp-bobby){
				bottom: 75px;
			}

			@include breakpoint($bp-echo){
				bottom: 35px;
			}
		}

		.read-more{
			display: none;

			@include breakpoint($bp-bobby){
				display: block;
			}
		}
	}

	.topper-image-wrapper{
		position: absolute;
		left: 0;
		width: 45%;
		height: 100%;
		top: 0;

		&.no-image{
			background-color: $body;
			display: none;
			.image{
				display: none;
			}
			&:after{
				display: none;
			}
		}

		.wrapper{
			overflow: hidden;
			position: relative;
			height: 100%;
		}

		.image{
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			right: 16px;
			bottom: 16px;
			@include transform(scale(1.1));
			@include hover-fade();
		}

		&:after{
			background: $body;
			bottom: 0;
			content: '';
			height: calc(100% - 52px);
			position: absolute;
			right: 0;
			width: 105%;
		}

		&.full-width{
			width: 100%;

			.wrapper{
				
			}
	
			.image{
				width: calc(100% - 32px);
				left: 16px;
			}
		}
	}

	&.color{

		.topper-image-wrapper{
			display: none;
		}

		.vertical-centered{
			transform: none;
		}

		h1 span:after, h1 span:before{
			background: $secondary;
		}
	}

	&.blue-bkg{
		background-color: $primary;
	}
	&.pink-bkg{
		background-color: $tertiary;
	}

	&.active{
		.topper-image-wrapper .image{
			@include transform(scale(1));
		}
	}
	
	.read-more {
		position: absolute;
		bottom: 0px;
		right: 15px;
		margin: 0 auto;
		max-width: 31px;
		display: none;
		@include breakpoint ($bp-charlie){
			display: block;
		}
		@include breakpoint($bp-bobby) {
			max-width: 42px;
		}
		a {
			padding: 0px;
			float: left;
			width: 100%;
			height: auto;
			color: $details;
			span{
				color: $details;
				display: block;
				font-family: $headline-font;
				font-size: 14px;
				height: 10px;
				padding-left: 50px;
				position: absolute;
				right: -62px;
				top: -105px;
				text-transform: uppercase;
				width: 175px;
				letter-spacing: 0.23em;
				@include transform(rotate(-90deg));

				&::before{
					background: $details;
					content: '';
					width: 35px;
					height: 3px;
					position: absolute;
					left: 0;
					top: 13px;
				}
			}
			svg {
				position: relative;
				width: 100%;
				@include hover-fade();
			}
			&:hover {
				color: $secondary;
				svg {
					@include transform(scale(1.15));
				}
			}
		}
	}

	.next-issue{
		display: none;
		width: 145px;


		@include breakpoint ($bp-charlie){
			display: block;
			margin-left: auto;
			margin-right: 0;
		}

		@include breakpoint($bp-bobby){
			right: -55px;
			position: absolute;
			width: 215px;
			margin-top:20px;
		}


		@include breakpoint($bp-echo){
			right: -145px;
			width: 245px;
		}

		p{
			font-size: 13px;
			text-transform: uppercase;
			font-family: $headline-font;
			font-weight: bold;
			letter-spacing: 1.3px;

			a{
				font-size: 17px;
				text-transform: none;
				letter-spacing: normal;
				margin-top: 5px;
				display: inline-block;
				
				span{
					border-bottom: 3px solid;
					line-height: 1.5em;
				}
				.arrow{
					display: inline-block;
					height: 20px;
					width: 20px;
					border: 2px solid $tertiary;
					border-radius: 50%;
					margin-bottom: -7px;
					margin-left: 2px;
					@include hover-fade();
	
					svg{
						fill: $tertiary;
						height: 9px;
						width: 5px;
						margin: 3px auto 0 6px;
						display: block;
						@include hover-fade();
					}
				}

				&:hover{
					color: $primary;

					.arrow{
						border-color: $primary;
						svg{
							fill: $primary;
						}
					} 
				}

			}
		}
	}
}

.topper-posts {
	padding: 70px 0 20px;
	position: relative;

	@include breakpoint($bp-charlie) {
		padding: 100px 0 0 0;
	}
	@include breakpoint($bp-bobby) {
		padding: 140px 0 40px;
	}
	.col-md-8, .col-md-9, .col-md-10 {
		margin: 0 auto;
		float: none;
	}
	.side-content{
		color: $details;
		font-family: $headline-font;
		left: 20px;
		margin: 0;
		display: none;
		
		@include breakpoint($bp-bobby){
			position: absolute;
			top: 50%;
			-ms-transform-origin: left top;
			transform-origin: left top;
			-ms-transform: rotate(-90deg) translateX(-100%);
			transform: rotate(-90deg) translateX(-100%);
			display: block;
		}

		.time{
			display: inline-block;
			margin-right: 10px;
			letter-spacing: 3px;
		}

		h6{
			position: relative;
			display: inline-block;

			&:before{
				top: 9px;
			}
		}
	}
	.content-left, .content-right{
		float: left;
		width: 100%;

		@include breakpoint($bp-charlie){
			width: 47%;
		}

		p{
			margin: 0;
			line-height: 150%;

			&.category{
				font-size: 16px;
				margin-top: 25px;
				color: $details;

				a{
					font-weight: bold;
					display: inline-block;

					&:after{
						background: $tertiary;
						content: '';
						display: block;
						height: 2px;
						width: 100%;
						@include hover-fade();
					}

					&:hover{
						color: $primary;

						&:after{
							background: $primary;
						}
					}
				}
			}
		}
	}

	.content-left{
		width: 100%;

		@include breakpoint($bp-charlie){
			width: 53%;
			margin-right:6%;
		}
	}
	.content-right{
		margin-top: 30px;
		@include breakpoint($bp-charlie){
			margin-top:25px;
			width: 41%;
		}
	}
	.left-right-split{
		@include breakpoint($bp-charlie){
			display: flex !important;
			flex-direction: columns;
			.content-right{
				display: flex;
				align-items: flex-end;
				
				.description{
					position:relative;
					top:6px;
				}
			}
		}
	}
	.topper-image-wrapper{
			width: 100%;
			height: 100%;
			min-height: 600px;
			position: relative;
			margin-top: 110px;

			.image{
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				height: 630px;
				position: absolute;
				z-index: 1;
				right: 16px;
				bottom: 16px;
				width: calc(100% - 32px);
				left: 16px;
			}

			&:after{
				background: $body;
				bottom: 0;
				content: '';
				height: calc(100% - 52px);
				position: absolute;
				right: 0;
				width: 105%;
			}
		}
	&.simple-topper {
		.col-md-8, .col-md-9, .col-md-10 {
			display: table;
			margin: 0 auto 0 auto;
		}
		h6, h1 {
			text-align: left;
		}
		h6 {
			color: $quatenary;
			margin-bottom: 20px;
			padding-left: 35px;
			text-transform: uppercase;
			letter-spacing: 0.2em;

			@include breakpoint($bp-charlie){
				margin-bottom: 40px;
			}

			&::before{
				background: $quatenary;
				content: '';
				width: 35px;
				height: 4px;
				position: absolute;
				left: 0;
				top: 9px;
			}
		}

		h1 {
			span:after{
				width: 113%;
			}
			span:last-child:after{
				width: 103%;
			}
		}
		.deck{
			margin-top: 30px;
		}
	}
	.page-meta {
		text-align: left;
		font-size: 13px;
		font-weight: 600;
		margin-bottom: 15px;
		span {
			// padding: 0 10px;
			font-size: 20px;
			font-family: $headline-font;
			font-weight: 600;
			color: $body;

			&.separator{
				color: $details;
			}
		}
		a {
			color: $body;
			&:hover {
				color: $secondary;
			}
		}

		p.category{
			font-size: 16px;
			margin-top: 25px;
			color: $details;

			.separator{
				font-size: inherit;
			}

			a{
				font-weight: bold;
				display: inline-block;
				color: $tertiary;
				text-transform: none;
				font-size: inherit;
				font-family: $primary-font;
				letter-spacing: normal;

				&:after{
					background: $tertiary;
					content: '';
					display: block;
					height: 2px;
					width: 100%;
					@include hover-fade();
				}

				&:hover{
					color: $primary;

					&:after{
						background: $primary;
					}
				}
			}
		}
	}
	h1, h2 {
		margin-bottom: 0px;

		span{
			
			&:before, &:after{
				background: $primary;
			}
		}
	}
	h2 {
		text-align: left;
		max-width: 700px;

		.special-underline span{
			&:before, &:after{
				height: 6px;
			}
	
			@include breakpoint($bp-bobby){
				&:before, &:after{
					height: 10px;
				}
			}
		}

	}
	h6 {
		color: $quatenary;
		margin-bottom: 10px;
		padding-left: 50px;

		&::before{
			background: $quatenary;
			content: '';
			width: 35px;
			height: 4px;
			position: absolute;
			left: 0;
			top: 11px;
		}
	}
	.post-info {
		text-align: left;
		font-size: 18px;
		margin-top: 15px;
	}
	.sub-nav {
		@include breakpoint($bp-delta) {
			margin-bottom: 25px;
		}
	}
}

// Topper Call To Action Area

.topper-cta {
	background: $body;
	padding: 30px 39px 30px 35px;
	width: 100%;
	z-index: 1;
	position: relative;
	visibility: hidden;
	transform: translateY(-1px);
	@include breakpoint($bp-charlie){
		// height: 190px;
		width: 100%;
	}
	@include breakpoint($bp-bobby){
		position: absolute;
		top: calc(100% - 100px);
		right: 0;
		width: 590px;
	}

	p{
		font-family: $headline-font;
		font-weight: bold;
		margin-bottom: 15px;
	}
	.row{
		margin: 0;
	}
	.btn {
		margin: 0 auto;
		display: table;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.topper-form {
		margin: 0 auto;
		display: block;
		float: none;
		width: 100%;
		padding: 0;
		@include breakpoint($bp-adams) {
			// width: 300px;
		}
		p{
			color: #fff;
		    font-size: 12px;
		    font-family: $headline-font;
		    line-height: 1.2;
		    margin-top: 10px;
		    margin-bottom: 0;
		    font-weight: 400;
		}
		form {
			.first-name,
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
				width: 50%;
			}

			.first-name{
				width: 100%;

				@include breakpoint($bp-charlie){
					width: calc(58% - 4px);
					margin-right: 4px;
				}
			}
			.email{
				width: 100%;

				@include breakpoint($bp-charlie){
					width: 42%;
				}
			}
			.zip {
				float: left;
				margin-bottom: 13px;
				width: 100%;

				@include breakpoint($bp-charlie){
					width: calc(50% - 4px);
					margin-right: 4px;
				}
			}
			.submit {
				float: left;
				margin-bottom: 0;
				width: 100%;

				@include breakpoint($bp-charlie){
					width: 50%;
				}

				.btn{
					max-width: 100%;
					min-width: auto;
					height: 50px;
					padding: 10px 15px;
					
					&:before{
						
					}
				}
			}
		}

		.action-form{
			#can_embed_form{
				
				#form_col1, #form_col2{
					width: 100% !important;
				}

				.country_drop_wrap, .international_link-wrap, label{
					display: none !important;
				}

				.core_field, form li{
					margin: 0 !important;
				}

				#form-first_name{
					float: left !important;
					width: 100% !important;

					@include breakpoint($bp-charlie){
						width: calc(50% - 2.5px) !important;
						margin-right: 5px !important;
					}
				}
				#form-last_name{
					float: left !important;
					width: 100% !important;

					@include breakpoint($bp-charlie){
						width: calc(50% - 2.5px) !important;
					}
				}

				#form-email{
					float: left !important;
					width: 100% !important;

					@include breakpoint($bp-charlie){
						width: 59% !important;
					}
				}

				#form-zip_code{
					float: left !important;
					margin-bottom: 5px !important;
					width: 100% !important;

					@include breakpoint($bp-charlie){
						width: calc(41% - 5px) !important;
						margin-left: 5px !important;
					}
				}
				#Cell-Phone{
					float: left !important;
					width: 100% !important;
					@include breakpoint($bp-charlie){
						width: calc(50% - 2.5px) !important;
					}
				}
				#Birth-Date{
					float: left !important;
					width: 100% !important;
					@include breakpoint($bp-charlie){
						width: calc(50% - 2.5px) !important;
						margin-left: 5px !important;
					}
				}

				#form_col2{
					width: 100% !important;
					height: 50px !important;
					display: flex;
					align-items: center;

					&::before{
						content: '\f105';
						color: $body;
						font-family: 'FontAwesome';
						font-size: 20px;
						position: relative;
						left: calc(100% / 2 - 80px);
						@include hover-fade();
						z-index: 2;
						font-weight: bold;
						line-height: 1em;
						top: -1px;
					}

					&:hover{
						&::before{
							color: #fff;
							left: calc(100% / 2 - 75px);
						}
					}
				}
				input[type="text"], input[type="password"], input[type="url"], input[type="email"], input[type="tel"], input[type="number"], textarea{
					font-size: 18px !important;
				}

				input[type='submit']{
					float: left !important;
					margin: 0 !important;
					border-radius: 0 !important;
					max-width: 100% !important;
					min-width: auto !important;
					height: 50px !important;
					padding: 10px 15px !important;
					width: 100% !important;
					position: absolute !important;
					top: 0 !important;
					text-transform: none !important;
					letter-spacing: normal !important;
					

					&:hover {
						background: $secondary;
						color: $body !important;				
					}
				}
			}

		}
	}
}

// Topper Video Background

.topper-video {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -100;
	background-size: cover !important;
}

.fullscreen-bg__video {
	display: none;
	@include breakpoint($bp-charlie) {
  		display: block;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

// Topper Menu


.blog .sub-nav, .archive .sub-nav, .category .sub-nav {
	background: #eee;
}

.sub-nav {
	float: left;
	width: 100%;
	padding: 0 20px;
	@include breakpoint($bp-bobby) {
		padding: 0 40px;
	}
	.issue-template-default &{
		padding-top: 50px;
	}
	.container {
		padding: 0;
	}
	.social {
		margin: 0;
		float: right;
		display: none;
		height: 70px;
		background: #eeeeee;
		padding: 21px 30px;
		@include breakpoint($bp-bobby) {
			display: block;
		}
		span {
			margin: 0 10px 0;
			font-family: $headline-font;
			color: #666666;
			text-transform: none;
			font-weight: 600;
			font-size: 14px;
		}
		ul {
			li {
				a {
					padding: 0px 8px;
					color: #666666;
					&:hover {
						color: $secondary;
					}
				}
			}
		}
	}
	.topper-menu {
		float: left;
		width: 100%;
		display: block;
		overflow: visible;
		&::after {
			content: "";
			clear: both;
			display: table;
		}
		@include breakpoint($bp-charlie) {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			display: block;
			width: auto;
			float: none;
			margin: 0 auto;
			display: table;
		}
		&.news {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
	}
	nav {
		margin-top: 15px;
		margin-bottom: 15px;
		overflow: visible;
		&::after {
			content: "";
			clear: both;
			display: table;
		}
		@include breakpoint($bp-charlie) {
			margin-top: 0;
			margin-bottom: 22px;
		}
		ul {
			margin: 0px;
			padding: 0px;
		    li {
		    	float: left;
				width: 100%;
		    	@include breakpoint($bp-charlie) {
					width: auto;
		    	}
		    	&.current-menu-item {
					a {
						color: $primary;
						position: relative;

						&::before{
							content: '\f105';
							color: $primary;
							font-family: 'FontAwesome';
							font-size: 19px;
							position: relative;
							left: -7px;
							top: 0px;
							@include hover-fade();
							opacity: 1;

							@include breakpoint($bp-charlie){
								position: absolute;
								left: -15px;
							}
						}

		    			&:after {
		    				@include breakpoint($bp-charlie) {
			    				background: $primary;
			    				opacity: 1;
			    			}
		    			}
					}
					
		    	}
		    	&:first-of-type {
		    		a {
			    		@include breakpoint($bp-charlie) {
							margin-left: 0!important;
			    		}
			    	}
		    	}
		    	&:last-of-type {
		    		a {
			    		@include breakpoint($bp-charlie) {
							margin-right: 0!important;
			    		}
			    	}
		    	}
		    	.sub-menu {
		    		display: none;
		    	}
		    	a {
		    		font-size: 16px;
		    		font-weight: 600;
		    		font-family: $headline-font;
		    		display: block;
		    		text-align: center;
		    		color: $body;
		    		position: relative;
		    		margin: 5px 10px;
		    		padding: 5px;
		    		@include breakpoint($bp-charlie) {
						padding: 0 0 5px 0;
		    			margin: 23px 20px 0;
					}
					&::before{
						content: '\f105';
						color: $body;
						font-family: 'FontAwesome';
						font-size: 19px;
						position: relative;
						left: -7px;
						top: 0px;
						@include hover-fade();
						opacity: 0;

						@include breakpoint($bp-charlie){
							left: -15px;
							top: -1px;
							position: absolute;
						}
					}
		    		&:after {
		    			content: '';
		    			width: 100%;
		    			height: 2px;
		    			position: absolute;
		    			bottom: 0;
		    			left: 0;
		    			background: $body;
		    			opacity: 0;
		    			@include hover-fade();
		    		}
		    		&:hover {
						color: $primary;
						&::before{
							color: $primary;
							left: -5px;
							opacity: 1;

							@include breakpoint($bp-charlie){
								left: -12px;
							}
						}
		    			&:after {
							background: $primary;
		    				@include breakpoint($bp-charlie) {
		    					opacity: 1;
		    				}
		    			}
		    		}
		    	}
		    }
		}
	}
}


.topper.short + .sub-nav {
	@include breakpoint($bp-charlie) {
		position: relative;
		// top: 20px;
	}
}

.campaign-single .topper-posts p.category{
	.separator{
		padding-left: 5px;
	}

	a, .separator{
		&:nth-child(n+4){
			display:none;
		}
	}
}
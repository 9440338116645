.post-feed.resources{
    .col-md-10{
        float: none;
        margin: 0 auto;
    }
}

.filter-form{
    margin-top: 30px;
    
    .selectric{
        background: transparent;
        border: 0;
        border-bottom: 3px solid $body;

        .button{
            background: transparent;

            &:after{
                border-width: 8px;
            }
        }
    }
    .selectric-items{
        background: $body;
        border: 0;
        left: auto;
        // position: relative;
        visibility: hidden;
        position: absolute;
        z-index: -1;
        top: 100%;
        box-shadow: 0px 10px 40px -10px rgba(0, 0, 0, 0.3);
        @include transform(translateY(-30px));
        height: 0px;
        @include hover-fade();
        padding: 0 0;

        .selectric-scroll {
            height: 100%;
            max-height: 200px;
            padding: 10px;
            // overflow: auto;
        }

        // &:after{
        //     background: $body;
        //     content: '';
        //     position: absolute;
        //     left: -16px;
        //     height: calc(100% + 2px);
        //     width: calc(100% + 37px);
        //     top: -1px;
        //     z-index: -1;
        // }

        ul{
            li{
                color: #fff;
                font-family: $headline-font;
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 5px;
                padding: 0;
                @include hover-fade();

                &:hover{
                    background: transparent;
                    color: $tertiary;
                    position: relative;
                }

                &.highlighted, &.selected{
                    background: transparent;
                    color: $tertiary;
                    position: relative;
                }
            }
        }
    }

    .selectric-wrapper{
        padding: 10px 0 0 0px;

        @include breakpoint($bp-charlie, max){
            padding-left:0;
            padding-right: 0px;
        }

        &.selectric-open{
            background: $body;

            .selectric{
                background: $body;
                border-color: $details;

                span.label{
                    color: #fff;
                    
                }
            }

            .selectric-items{
                @include transform(translateY(0));
                height: auto;
                visibility: visible;
            }
        }
    }
	form{
		> ul{
			margin: 0;
			padding: 0;

			> li{
                display: inline-block;
                margin-right: 25px;
                line-height: 1em;
                min-width: 235px;
                padding: 0;

                label{
                    min-width: 235px;
                    cursor: pointer;
                }

                span.label{
                    padding: 0;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: bold;
                    font-family: $headline-font;
                    //@include hover-fade();
                    outline: 0;
                    margin-left: 0;
                    text-transform: uppercase;
                    min-width: 162px;
                    color: $body;
                    padding-left: 10px;
                }

                .button{
                    width: 15px;
                    margin-right: 10px;
                }

                // &:hover{
                //     background: $body;
                //     border-color: $details;
                //     color: #fff;
                // }

                label select{
                    option:first-child{
                        text-transform: uppercase;
                    }
                }
			}
		}
	}
}

#resources-wrapper{
    float: left;
    width: 100%;
    @include breakpoint($bp-charlie, max){
        padding: 0;
    }
    .articles{
        float: none;

        article{
            z-index: 0;
            @include breakpoint($bp-charlie, max){
                padding-left: 0;
                padding-right: 0;
            }
            &:hover{
                z-index: 1;
            }
            .content-wrapper{
                float: none;
            }
            .main-content{
                float: none;
            }
        }
    }
    .col-xs-12.col-md-6{
        padding: 0;
    }
}

@include breakpoint($bp-charlie, max){
    .searchandfilter li, .searchandfilter label{
        width:100%;
    }
}
@include breakpoint($bp-charlie){
    .search-no-match-text {
        padding-left: 15px;
    }
}

@media print {

  //everything to get rid of
  .alert-bar, .main-header, .social-nav,
  nav,// <- controversial, but we should properly be using this on all proper navigation items, right?
  .read-more, .mfp-bg, .mfp-wrap, .menuOverlay, .searchOverlay
  {
    display:none !important;
  }
  .top{
    position: static;
  }


  a {
    color: #000 !important;
  }
  p a[href^="#"]:after {
    display: none;
  }



}

$form-content-max-width: 450px;

.bottom-cta {
	float: left;
	width: 100%;
	background-color: $body;
	border: 16px solid $body;
	position: relative;
	// padding: 40px 0;
	color: white;
	@include breakpoint($bp-charlie) {
		height: auto;
	}

	@include breakpoint($bp-bobby){

	}

	@include breakpoint($bp-echo){
		height: 750px;
	}
	.bottom-cta-image{
		background-size: cover !important;
		float: left;
		height: 100%;
		min-height: 350px;
		width: 100%;

		@include breakpoint($bp-charlie) {
			min-height: 550px;
		}
		
		@include breakpoint($bp-echo){
			min-height: 750px;
			width: 55%;
		}
	}
	.container{
		float: left;
		padding: 50px 15px 0;
		width: 100%;

		@include breakpoint($bp-charlie){
			padding: 50px 20px 0;
		}

		@include breakpoint($bp-echo){
			min-height: 750px;
			padding: 0 85px;
			width: 45%;
		}
		.row{
			margin: 0 auto;
			max-width: 750px;
		}
		.vertical-centered{
			position: static;
			top: auto;
			transform: none;

			@include breakpoint($bp-echo){
				position: relative;
				top: 50%;
				@include transform(translateY(-50%));
				height: 382px;
				
			}	
		}
	}
	.col-xs-12{
		padding: 0;
	}
	h1, h6 {
		color: #fff;
		text-align: left;
	}
	h6 {
		margin-bottom: 5px;
	}
	h1 {
		margin-bottom: 25px;
		font-size: 40px;

		@include breakpoint($bp-charlie){
			font-size: 58px;
		}

		span{
			padding: 0;
			display: inline-block;
			transition: .45s;
			position: relative;
			
			&:before{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 108%;
				height: 6px;
				position: absolute;
				bottom: -2px;
				left: -10px;
				padding: 0 1px;
				z-index: -1;
			}
			&:after{
				background: $secondary;
				content: '';
				transform: skew(-30deg,0);
				width: 108%;
				height: 6px;
				position: absolute;
				bottom: -2px;
				left: 0;
				padding: 0 1px;
				z-index: -1;
			}

			@include breakpoint($bp-charlie){
				&:before, &:after{
					height: 9px;
				}
			}
		}
	}
	.deck {
		margin-bottom: 35px;
		color: #fff;
		position: relative;
		p {
			color: #fff;
			font-size: 20px;
			line-height: 120%;
			font-family: $headline-font;
			font-weight: bold;
			text-align: left;
			@include breakpoint($bp-charlie){
				font-size: 24px;
				line-height: 150%;
			}
		}
	}
	.container, .row {
		@include breakpoint($bp-echo){
			height: 100%;
		}
	}
	.row{
		margin:0;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.bottom-cta-headline{
		max-width: $form-content-max-width;
		margin: 0 auto;
		float: none;
	}
	.bottom-cta-form {
		display: block;
		float: none;
		padding: 0;
		max-width: $form-content-max-width;
		margin: 0 auto;
		.form-wrapper {
			width: 100%;
			margin: 0 auto;
			display: table;
		}
		.btn {
			display: table;
			float: none;
			max-width: 380px;
			margin: 0 auto;
		}
		form {
			.first-name,
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;

				.btn{
					max-width: 100%;
				}
			}
		}

		.action-form{
			#can_embed_form{
				
				#form_col1, #form_col2{
					width: 100% !important;
				}

				.country_drop_wrap, .international_link-wrap, label{
					display: none !important;
				}

				.floatlabel-input{
					margin-bottom: 6px !important;
				}

				.core_field, form li{
					margin: 0 !important;
				}

				#form-first_name{
					float: left !important;
					width: 100% !important;
				}

				#form-email{
					float: left !important;
					width: 100% !important;
				}

				#form-zip_code{
					float: left !important;
					margin-bottom: 13px !important;
					width: 100% !important;
				}

				#form_col2{
					width: 100% !important;
					height: 50px !important;
					display: flex;
					align-items: center;
					
					// @include breakpoint($bp-charlie){
					// 	position: absolute !important;
					// 	width: 50% !important;
					// 	bottom: 13px !important;
					// 	right: 0 !important;
					// }

					&::before{
						content: '\f105';
						color: $body;
						font-family: 'FontAwesome';
						font-size: 20px;
						position: relative;
						left: 19%;
						@include hover-fade();
						z-index: 2;
						font-weight: bold;
						line-height: 1em;
						margin-top:-2px;

						@include breakpoint(376px){
							left: 28%;
						}
						@include breakpoint($bp-charlie) {
							left: 29%;
						}
					}

					&:hover{
						&::before{
							color: #fff;
							left: 21%;
							@include breakpoint(376px){
								left: 30%;
							}
							@include breakpoint($bp-charlie) {
								left: 31%;
							}
						}
					}
				}

				input[type='submit']{
					float: left !important;
					margin: 0 !important;
					border-radius: 0 !important;
					max-width: 100% !important;
					min-width: auto !important;
					height: 50px !important;
					padding: 10px 15px !important;
					width: 100% !important;
					position: absolute !important;
					top: 0 !important;
					font-size: 17px !important;
					letter-spacing: normal !important;
					text-transform: none !important;
					

					&:hover {
						background: $secondary;
						color: $body !important;				
					}
				}
			}

		}
	}
}

.bottom-cta__mobile-image {
	background-color: $body;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	@include breakpoint($bp-charlie) {
		display: none;
	}
}

.main-site-footer {
	float: left;
	width: 100%;
	padding: 30px 0 80px;
	color: $body;
	background: $body;
	@include breakpoint($bp-charlie) {
		padding: 50px 0;
	}
	a {
		color: #fff;
		&:hover {
			color: $secondary;
		}
	}
	&.submenu {
		padding: 40px 0 100px;
		@include breakpoint($bp-charlie) {
			padding: 40px 0;
		}
	}
	.col-sm-6 {
		text-align: center;
		@include breakpoint($bp-charlie) {
			margin: 0 auto;
			display: table;
			float: none;
		}
	}
	#threeRs{
		float: left;
		height: 74px;
		width: 156px;

		.st0, .st2{fill:#FFF;}
		.st1{fill:$body;}
	}
	
	nav {
		&.footer-secondary {
			float: none;
			margin: 10px 0 0;
			display: block;
			@include breakpoint($bp-charlie) {
				float: right;
				margin: 15px 0 0;
			}
			@include breakpoint($bp-bobby) {
				margin: 20px 0 0;
			}
			ul {
				margin: 0;
				display: table;
				float: none;
				li {
					display: inline-block;
					text-align: left;
					margin: 0 5px 0 0;
					padding:0;

					@include breakpoint($bp-charlie){
						margin: 0 0 0 5px;
						text-align: right;
					}
					
					small{
						border-left: 2px solid $details;
						font-size: 11px;
						text-transform: none;
						padding:0 0 0 5px;

						a{
							color: #fff;
							&:hover{
								color: $secondary
							}
						}
					}

					&:first-child small{
						border: 0;
						padding: 0;

						@include breakpoint($bp-charlie){
							padding:0 0 0 5px;
						}
					}
				}
			}
		}
		&.primary {
			float: left;
			width: 100%;
			display: block;
			padding-bottom: 50px;
			margin-bottom: 0;
			@include breakpoint($bp-charlie) {
				margin-bottom: 30px;
				margin-top: 30px;
			}
			@include breakpoint($bp-bobby) {
				margin-top: 0;
				// margin-bottom: 70px;
			}
			ul.menu {
				margin: 0 auto;
				display: table;
				float: left;
				width: 100%;
				> li {
					@include breakpoint($bp-charlie, max){
						margin-bottom: 30px;
					}
					text-align: left;
					font-family: $headline-font;
					font-weight: 600;
					display: block;
					float: left;
					max-width: inherit;
					font-size: 13px;
					color: $details;
					padding: 0;
					width: 100%;
					@include breakpoint($bp-charlie) {
						width: 50%;
						padding-right: 60px;
					}
					@include breakpoint($bp-bobby) {
						width: 33.33%;
					}
					@include breakpoint($bp-echo){
						width: 25%;
					}
					&.home {
						display: none;
					}
					a {
						font-size: 13px;
						color: $details;
						// @include breakpoint($bp-charlie) {
						// 	font-size: 19px;
						// }
						// @include breakpoint($bp-bobby) {
						// 	font-size: 20px;
						// }
						&:hover{
							color: $secondary
						}
					}

					.sub-menu{
						margin-top: 20px;

						li{
							padding: 0;
							text-transform: none;
							float: none;
							margin: 0 0 10px 0;
							line-height: 1.5;

							a{
								color: #fff;
								letter-spacing: normal;

								&:hover{
									color: $secondary
								}
							}
						}
					}
				}

				> li:last-child{
					margin-right: 0;
					padding-right: 0;

					> a{
						// pointer-events: none;
					}
				}
			}
		}
	}
	.social {
		&.links {
			float: none;
			width: 100%;
			text-align: center;
			margin-bottom: 50px;
			@include breakpoint($bp-bobby){
				float: right;
				width: auto;
			}
			ul {
				margin: 0;
				display: block;
				float: none;
				li {
					display: inline-block;
					@include breakpoint($bp-bobby, max){
						float: none;
					}
					a {
						color: #fff;
						padding: 0 6px;
						i {
							font-size: 15px;
							// @include breakpoint($bp-charlie) {
							// 	font-size: 28px;
							// }
							// @include breakpoint($bp-bobby) {
							// 	font-size: 32px;
							// }
						}

						&:hover{
							color: $secondary;
						}
					}
				}
			}
		}
	}

	&.threeRs{
		svg#logo {
			float: left;
			height: 74px;
			width: 156px;
			
			.cls-1{fill:#fff;}.cls-2{fill:#fff; clip-path: none;}.cls-3{fill:#fff;}
		}

		.col-md-10{
			width: 100%;
		}

		nav.primary ul.menu{
			> li{
				@include breakpoint($bp-charlie){
					width: 25%;
				}
			}
		}
		.footer-logo{
			display: inline-block;
		}
	}
}
.footer-details{
	border-top: 3px solid $details;
	padding-top: 20px;
}

.footer-meta {
	float: none;
	line-height: 1.2em;
	margin-bottom: 20px;
	@include breakpoint($bp-charlie){
		float: right;
	}

	p.copyrights {
		margin: 0;
		color: $details;
		font-family: $headline-font;
		font-weight: 400;
		display: block;
		font-size: 11px;
		text-align: left;

		@include breakpoint($bp-charlie){
			text-align: right;
		}

		span {
			color: #fff;
			margin: 0;
			display: inline-block;
		}
	}
	span {
		color: $details;
		font-family: $headline-font;
		font-weight: 400;
		display: block;
		font-size: 11px;
		text-align: left;

		@include breakpoint($bp-charlie){
			text-align: right;
		}

		&.campaign-name {
			margin-top: 15px;
		}

		&.below-copyright {
			text-align: right;
			font-family: $headline-font;

			p {
				font-family: inherit;
				font-size: inherit;
			}
		}

		&.paid-for {
			text-align: right;
			border: 1px solid rgba($footer, .25);
			padding: 7px 20px;
			width: 100%;
			color: rgba($footer, .5);
			margin: 0 auto;
			max-width: 620px;
			float: none;
			display: table;
			font-size: 12px;
		}
		&.site-by {
			// margin-top: 10px;
			text-align: left;
			font-weight: 400;
			color: #999999;
			// margin-top: 15px;

			@include breakpoint($bp-charlie){
				text-align: right;
			}
			a {
				color: #999999;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.phoneAndFax{
		text-align: left;
		color: $details;

		@include breakpoint($bp-charlie){
			text-align: right;
		}

		span{
			display: inline-block;
		}
	}
}

.access-downloads{
    .topper-posts.simple-topper{
        @include breakpoint($bp-charlie){
            padding-bottom: 20px;
        }
        @include breakpoint($bp-bobby){
            padding-bottom: 40px;
        }
        h1{
            text-align: center;
        }
    }
    .introduction{
        text-align: center;
        position: relative;
        > h6{
            color: $quatenary;
            display: none;
            margin: 0 40px 15px 80px;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 3.3px;
            &::before{
                background: $quatenary;
                content: '';
                width: 35px;
                height: 4px;
                position: absolute;
                left: -50px;
                top: 9px;
            }
            @include breakpoint($bp-charlie) {
                display: inline-block;
                left: 20px;
                margin: 0;
                position: absolute;
                top: 0;
                transform-origin: left top;
                @include transform(rotate(-90deg) translateX(-100%));
            }
        }
    }
    .module{
        &.wufoo{
            margin-top: 20px;
            min-height: 530px;
        }
    }
}

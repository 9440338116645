.alert-bar {
	width: 100%;
	background: $primary;
	position: fixed;
	bottom: 0;
	z-index: 9;
	@include hover-fade();

	&.has-link {
		&:hover {
			background: $quatenary;
		}

		.close-icon .fa:hover {
			color: $body;
		}
	}

	&.-alt {
		background-color: $bg-light;
		position: relative;
		bottom: auto;
	}

	a{
		color: #fff;
		text-decoration: underline;

		&.btn{
			text-decoration: none;
		}
	}

	//hidden by default even if turned on, visible if the cookie script says it can be
	display: none;
	.ok-to-alert &{
		display: block;
	}
}

.alert-bar__centered-col {
	float: none;
	margin-left: auto;
	margin-right: auto;
}

.alert-bar__close {
	cursor: pointer;
	position: absolute;
	top: 50%;
	height: 100%;
	@include transform(translateY(-50%));
	right: 15px;
	width: 21px;
	height: 21px;
	min-height: 0;
	border-radius: 100%;
	background: rgba(255,255,255,0.25);
	@include hover-fade();
	z-index:9999999;

	.alert-bar.-alt & {
		background-color: rgba(34, 34, 34, 0.2);
	}

	&.-top-aligned {
		top: 20px;
		@include transform(translateY(0));
	}

	svg {
		position: absolute;
		top: 5px;
		left: 5px;
		width: 11px;
		height: 11px;
		fill: #fff;
	}

	&:hover {
		background: rgba(#fff,1);

		svg {
			fill: $secondary;
		}

		.alert-bar.-alt & {
			background-color: $primary;

			svg {
				fill: #fff;
			}
		}
	}
}

.alert-bar__simple {
	padding: 9px 25px;

	p {
		padding: 0px 25px;
		margin: 0px;
		text-align: center;
		color: #fff;
		font-family: $headline-font;
		font-weight: 600;
		line-height: 116%;
		font-size: 13px;

		@include breakpoint($bp-charlie) {
			padding: 0 15px;
			line-height: 125%;
			font-size: 15px;
		}

		span {
			text-transform: uppercase;
			margin-right: 10px;
			font-size: 15px;
		}
	}
}

.alert-bar__simple__link {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.alert-bar__flex {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 20px;
	
	@include breakpoint($bp-bobby) {
		flex-direction: row;
		padding-top: 54px;
		padding-bottom: 54px;
	}
}

.alert-bar__description {
	@include breakpoint($bp-bobby) {
		max-width: 542px;
	}
	&:before{
		content:'';
		float:right;
		margin-right:-10px;
		width:30px;
		height:22px;
	}
}

.alert-bar__headline {
	.alert-bar:not(.-alt) & {
		color: #fff;
	}
}

.alert-bar__text {
	.alert-bar:not(.-alt) & {
		color: #fff;
	}

	@include breakpoint($bp-bobby) {
		font-size: 18px;
	}
}

.alert-bar__wrapper {
	@include breakpoint($bp-bobby) {
		padding-left: 45px;
	}

	&.-fill {
		flex: 1;
	}
}

.alert-bar__button {
	background-color: $primary;
	color: $body;
	border: 0;

	&:hover{
		background-color: $tertiary;
	}
}

.alert-bar__video {
	text-align: center;
	font-family: $headline-font;
	font-size: 16px;
	color: #fff;
	display: block;
	text-decoration: none !important;
	position: relative;

	&:after {
		content: 'watch video';
		color: $body;
		text-decoration: none;
	}

	&:before{
		content: '';
		display: block;
		position: absolute;
		background: #fff;
		height: 60px;
		width: 60px;
		top: -40px;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		@include hover-fade();
		z-index: -1;
	}

	svg {
		fill: $tertiary;
		width: 124px;
		height: 124px;
		display: block;
		margin: 0 auto 20px auto;
		border-radius: 999px;
		@include hover-fade();
	}

	&:hover {
		color: #fff;

		&:before{
			background: $body;
		}

		svg {
			fill: $secondary;
		}
	}
}

.alert-bar__form {
	.form-field {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		&.first-name,
		&.email {
			margin-bottom: 4px;
		}

		&.zip {
			margin-bottom: 13px;
		}

		input {
			border-color: transparent;
			border-radius: 0;
			box-shadow: 12px 20px 40px rgba(0, 0, 0, 0.1);
		}

		.btn:hover{
			color: $body;

			&:before{
				color: $body;
			}
		}
	}
}

.alert-bar__donation-wrapper {
	@include breakpoint($bp-bobby) {
		max-width: 400px;
	}

	ul.alert-bar__donations li a, ul.alert-bar__donations li:last-child a{
		color: $body;

		&:hover{
			background: $quatenary;
			color: #fff;
			border-color: $quatenary;
		}
	}
}

.alert-bar__donation-button {
	font-size: 16px !important;
	padding-top: 3px !important;
	padding-bottom: 3px !important;

	&.-alt {
		font-size: 11px !important;
		height: auto !important;
		color: $body !important;
		border-color: $secondary !important;
		box-shadow: 0 0 0 transparent !important;

		&:hover,
		&:focus {
			color: #fff !important;
			border-color: $quatenary !important;
		}
	}
}

.alert-bar__donation-caption {
	clear: both;
	margin-top: 10px;

	p {
		text-align: center;
		font-family: $headline-font;
		font-weight: 400;
		font-style: normal;
		line-height: 1.2;
		color: $body;
	}
}
